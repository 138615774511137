var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "sort-list-items" },
    _vm._l(_vm.sortListItems, function (sortListItem) {
      return _c(
        "li",
        {
          key: sortListItem.key,
          staticClass: "sort-list-item",
          on: {
            click: function ($event) {
              return _vm.handleSortListItemClick(sortListItem)
            },
          },
        },
        [
          _c("i", {
            staticClass: "sd2-icons sort-list-icon",
            class: sortListItem.isSelected
              ? "active radio-button-checked"
              : "radio-button",
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "sort-list-item-description",
              class: { active: sortListItem.isSelected },
            },
            [_vm._v(_vm._s(sortListItem.label))]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }