import Vue from 'vue';
import Vuex from 'vuex';
import SearchBar from '../module/SearchBar/view/vue/SearchBar.vue';
import Tweakwise from '../module/Deals/view/vue/Tweakwise.vue';
import SearchAndFilter from "../module/Deals/view/vue/SearchAndFilter.vue";
import Loader from './components/Loader.vue';
import dayjs from "./plugins/dayjs";
import 'url-polyfill';
import 'url-search-params-polyfill';

import DealsAvailabilityWrapper from "../module/Availability/view/vue/components/DealsAvailabilityWrapper.vue";
import HotelsAvailabilityWrapper from "../module/Availability/view/vue/components/HotelsAvailabilityWrapper.vue";

import SearchBarCalendar from "../module/SearchBar/view/vue/SearchBarCalendar.vue";
import InboxBadge from "../module/Account/view/vue/components/InboxBadge.vue";
import SortList from "./components/filter/SortListFilter.vue";
import FilterModal from "./components/filter/FilterModal.vue";
import BottomDrawer from "./components/BottomDrawer.vue";

Vue.use( dayjs );
Vue.use( Vuex );

Vue.config.devtools = false;

const store = new Vuex.Store( {
    state     : {
        favoriteUniques : [] as Array<string>,
    },
    getters   : {
        favoriteUniques( state ): Array<string> {
            return state.favoriteUniques;
        }

    },
    mutations : {
        setFavoriteUniques( state, payload: Array<string> ): void {
            state.favoriteUniques = payload;
        },
        removeFavorite( state, payload: string ): void {
            const index = state.favoriteUniques.findIndex( unique => unique === payload );
            if ( index !== -1 ) {
                state.favoriteUniques.splice( index, 1 );
            }
        },
        addFavorite( state, payload: string ): void {
            state.favoriteUniques.push( payload );
        }
    }
} );
Vue.prototype.$t = ( key: string ) => {
    return ( window as any
    ).lang[ key ] as Function
}

const vueSections = [
    '#vue-root',
    '#tweakwise-root',
    '#availability-box',
    '#navbar',
    '.mobile-footer'
];

for ( var i = 0; i < vueSections.length; i ++ ) {
    let exists = null;
    if( vueSections[i].charAt(0) === '#' ){
        exists = document.getElementById(vueSections[i].substring(1));
    } else {
        const htmlCollection = document.getElementsByClassName(vueSections[i].substring(1));
        exists = htmlCollection.length > 0 ? htmlCollection : null;
    }

    if(!exists) {
        continue;
    }
    new Vue( {
        el         : vueSections[i],
        store      : store,
        components : {
            Tweakwise,
            DealsAvailabilityWrapper,
            HotelsAvailabilityWrapper,
            SearchBar,
            SearchBarCalendar,
            InboxBadge,
            Loader,
            FilterModal,
            SearchAndFilter
        }
    } );
}




