












import Vue, { PropType } from "vue";

export default Vue.extend( {
  name       : 'CalendarDay',
  props      : {
    day    : {
      type     : Object,
      required : true
    },
    dayKey : {
      type     : Number,
      required : true
    }
  },
  computed   : {
    dayType(): string {
      if ( this.day.template === 'price' || this.day.template === 'default' ) {
        return 'day-default';
      }

      return 'day-' + this.day.template;
    }
  },
  components : {
    'day-default' : () => import('./day-default.vue'),
    'day-empty'   : () => import('./day-empty.vue')
  }
} );
