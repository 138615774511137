var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "current-tooltip",
      staticClass: "tooltip-wrapper",
      class: { "sticky-bottom": _vm.stickyBottom },
    },
    [
      _c("div", { class: "day-tooltip tooltip-" + _vm.dayKey }, [
        _c("div", { staticClass: "day-tooltip-top" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.toolTip.check_in) +
              " - " +
              _vm._s(_vm.toolTip.check_out) +
              "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "day-tooltip-bottom" }, [
          _vm._v("\n      " + _vm._s(_vm.toolTip.description) + "\n    "),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "tooltip-triangle" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }