
























































import Vue, {PropType} from 'vue';
import FilterModal from "../../../../frontend/components/filter/FilterModal.vue";
import Tweakwise from "./Tweakwise.vue";
import DealList from "./components/lists/DealList.vue";
import FilterService from "../../../../frontend/services/FilterService";
import {
  Badge,
  FilterModalType,
  FilterSearchActionType,
  NearbySearchLocation,
  SearchFilterEmit
} from "../../../../frontend/types/FilterTypes";
import {Deal} from "./domain/Deal";
import Message from "./components/Message.vue";
import {triggerEvent} from "../../../../frontend/helper/customEvents";
import ExperienceList from "./components/lists/ExperienceList.vue";
import {SearchService} from "../../../../frontend/services/SearchService";
import {Experiences} from "./domain/Experiences";
import {Experience} from "./domain/Experience";
import ClickEvent = JQuery.ClickEvent;

interface IProps {
  filterModalType: FilterModalType;
  nearbySearchBar: NearbySearchLocation;
}

export default Vue.extend( {
  name : 'SearchAndFilter',
  components: { ExperienceList, Message, DealList, Tweakwise, FilterModal },
  props : {
    filterModalType: {
      type: String as PropType<IProps['filterModalType']>,
      required: true
    },
    initialDeeplinkResponse: {
      type: String,
      required: true
    },
    cityUnique : {
      type : String,
      required : true,
    },
    citySlug : {
      type : String,
      required : false,
    },
    tagCloudData : {
      type     : Object,
      default : null
    },
    nearbySearchBar: {
      type: String,
      required: false
    }
  },
  data(): any {
    return {
      badge:  null,
      filteredDeals: [],
      isEmptyFilter: false,
      experiences: null,
      //determines whether to show the filter button
      hideFilterButton: false,
      // overrules hideFilterButton
      hideDealsWhenSearchBarIsActive: false,
      // overrules hideFilterButton
      favoritesIsActive: false,
      lastNearbyFilterConfig: {
        point: '',
        filterQueryString: ''
      },
      nearbyPageInfo: {
        totalPagesNearby: 0,
        currentPage: 1
      },
      isLoadingMoreNearbyDeals:false,
      nearbySearchBarData: this.nearbySearchBar,
      isInitNearbyInspirations: true
    }
  },
  methods : {
    onFilterButtonClick() {
      if(this.isMobile) {
        $( '.whatsapp-box' ).removeClass('visible');
        this.hideMobileFooter();

        if(this.isFilterModalTypeNearby) {
          $('.modal-toggle').hide();
        }
      }
    },
    onCloseFilter() {
      if(this.isMobile) {
        $( '.whatsapp-box' ).addClass('visible');
        this.showMobileFooter();
        if(this.isFilterModalTypeNearby) {
          $('.modal-toggle').show();
        }
      }
    },
    async getFilterSearchResults( pushState: boolean, payload?: SearchFilterEmit ) {
      if(!this.hideDealsWhenSearchBarIsActive) {
        this.hideFilterButton = false;
      }
      let filterSearchResults:any;
      let personalisationPlacement;
      let $personalizedDeals;

      if( this.isFilterModalTypeHome) {
        const clearTweakwise = new CustomEvent("cleartweakwise" );
        window.dispatchEvent(clearTweakwise);

        filterSearchResults = await FilterService.getHomeFilterSearchResultByCityUnique(this.cityUnique, payload.filterQueryParams);

        // jQuery DOM manipulation detach
        personalisationPlacement = !this.isMobile ? 5 : 2;
        const $personalizedLastViewed = $('.personalization-last-viewed' );
        $personalizedDeals = $personalizedLastViewed.filter( this.isMobile ? '.visible-xs' : '.hidden-xs' ).detach();
        $personalizedLastViewed.filter( !this.isMobile ? '.visible-xs' : '.hidden-xs' ).remove();
      } else {
        if(payload) {
          this.updateNearbyFilterLocation(payload.nearbySearchLocation.lat, payload.nearbySearchLocation.long)
          this.lastNearbyFilterConfig.filterQueryString = payload.filterQueryParams;
        }

        this.nearbyPageInfo.currentPage = 1;
        this.nearbyPageInfo.totalPagesNearby = 0;
        filterSearchResults = await FilterService.getNearbyFilterSearchResultByPoint(
            this.lastNearbyFilterConfig.point,
            this.lastNearbyFilterConfig.filterQueryString,
            this.nearbyPageInfo.currentPage);
        $( '#search-bar' ).data('get-deals',true);
        ($('#nearbyList') as any).boxLoader( 'hide' );

        // jQuery DOM manipulation detach
        personalisationPlacement = !this.isMobile ? 6 : 2;
        $personalizedDeals = $('.personalization-last-viewed' ).detach();
        // Until here
      }

      // jQuery jenk
      const $activeTagCloudItem = $('.tagcloud-container .tagcloud-item.active:visible');
      const categoryChangeEvent = new CustomEvent('tweakwiseCategoryChange', {
        detail: {
          category: $activeTagCloudItem.length === 1 ? $activeTagCloudItem.data('category') : 'popular'
        }
      })
      window.dispatchEvent( categoryChangeEvent );

      this.badgeUpdated(filterSearchResults.badge);
      switch(filterSearchResults.action) {
        case FilterSearchActionType.ChangeCity:
          window.location.href = `/deals/${filterSearchResults.slug}/?keep-filters=true`;
          return;
        case FilterSearchActionType.Deals:
          if(payload?.closeFilterCallback && typeof payload?.closeFilterCallback === 'function') {
            payload.closeFilterCallback();
          }

          let filteredDeals;
          if(this.filterModalType === FilterModalType.Home) {
            this.hidePlanning();
            filteredDeals = Deal.createFromPlanningResponse(filterSearchResults);
          } else {
            if(filterSearchResults.page === 1 && (filterSearchResults.count > filterSearchResults.deals.length)) {
              this.nearbyPageInfo.totalPagesNearby = Math.ceil(filterSearchResults.count / filterSearchResults.deals.length);
            }
            this.hideNearbyList();
            filteredDeals = Deal.createFromNearbyFilterResponse(filterSearchResults);
          }
          this.filteredDeals = filteredDeals;
          this.isEmptyFilter = this.filteredDeals.length < 1;
          if( this.isEmptyFilter && payload ) {
            await this.fetchExperiences(payload.inspirationTag);
          }
          break;
        case FilterSearchActionType.NearbyGetDeals:
        default:
          if(payload?.closeFilterCallback && typeof payload?.closeFilterCallback === 'function') {
            payload.closeFilterCallback();
          }
          this.filteredDeals = null;
          if(this.isFilterModalTypeHome) {
            this.showPlanning();
          } else {
            this.showNearbyList();
          }
          break;
      }

      // jQuery DOM manipulation put detach it back :)
      await this.$nextTick();
      const $originalList = this.isFilterModalTypeHome ? $('#lastMinuteDeals') : $('#nearby-row-deals');
      const $filterDealList = $( '.filtered-deal-list .dl-row' );

      let $insertList = $originalList.hasClass('hide') ? $filterDealList : $originalList;
      $personalizedDeals.insertAfter( $insertList.find(`.tw-deals:eq(${personalisationPlacement - 1}), .mix:eq(${personalisationPlacement - 1})`) );
      $personalizedDeals.removeClass('hidden');
      $personalizedDeals.find('.universal-carousel-scroll').trigger('scroll');
      // Until here

      const filterQueryParams = this.isFilterModalTypeNearby && !payload ? this.lastNearbyFilterConfig.filterQueryString : payload.filterQueryParams;
      const url = FilterService.removeDefaultValuesFromURL(filterQueryParams);
      if( payload && pushState ) {
        window.history.pushState(null, document.title, `?${url}`);
      }
    },
    updateNearbyFilterLocation(lat:string|number, long:string|number) {
      this.lastNearbyFilterConfig.point = lat + ',' + long;
    },
    showFavorites() {
      this.isEmptyFilter = false;
      this.hideFilterButton = true;
      this.favoritesIsActive = true;
      $('.personalization-last-viewed' ).addClass('hidden'); // Sorry little bit more jQuery
      this.showPlanning();
    },
    hideFavorites() {
      this.favoritesIsActive = false;
      if(this.isFilterModalTypeHome) {
        this.showPlanning();
      }
    },
    badgeUpdated(badge: Badge) {
      this.badge = badge;
      $('#filter_button #mobile-navbar-filter-badge').remove();
      if(badge) {
        this.cloneBadgeToMobileNavbar();
      }
      if(this.isMobile && this.isFilterModalTypeNearby) {
        const badgeUpdatedEvent = new CustomEvent("filter-badge-updated", { detail: badge });
        window.dispatchEvent(badgeUpdatedEvent);
      }
    },
    async handleClear() {
      this.hideDealsWhenSearchBarIsActive = false;
      if(!this.showFilterDeals || this.favoritesIsActive) {
        return;
      }
      this.hideFilterButton = false;
    },
    handleFilterButtonVisibility(isHidden: boolean) {
      // do not make it visible again in favorites
      if(this.favoritesIsActive || this.hideDealsWhenSearchBarIsActive) {
        return;
      }
      this.hideFilterButton = isHidden;
    },
    async showPlanning() {
      if(!this.hideDealsWhenSearchBarIsActive) {
        this.isEmptyFilter = false;
        await this.$nextTick();
        this.planningElement.classList.remove( 'hide' );
        triggerEvent( 'lastMinute-toggleFeatured', null );
      }
    },
    hidePlanning() {
      if( (this.detailBoxElement === null && !this.favoritesIsActive) || this.hideDealsWhenSearchBarIsActive) {
        this.planningElement.classList.add( 'hide' );
      }
    },
    hidePlanningAndFilter() {
      this.hideDealsWhenSearchBarIsActive = true;
      this.hidePlanning();
    },
    hideMobileFooter() {
      this.mobileFooterElement.classList.add('hide');
      this.openInAppElement.classList.add('hide');
    },
    showMobileFooter() {
      this.mobileFooterElement.classList.remove('hide');
      this.openInAppElement.classList.remove('hide');
    },
    hideNearbyList() {
      $('#nearbyList').find( '.box-content .row.placeholder' ).remove();
      this.nearbyRowDealsElement.classList.add( 'hide' );
    },
    showNearbyList() {
      this.nearbyRowDealsElement.classList.remove( 'hide' );
    },
    resetFilterOutsideModal(clickEvent:ClickEvent) {
      clickEvent.stopPropagation();
      const event = new CustomEvent('reset-filter-outside-modal');
      window.dispatchEvent(event);
    },
    onSearchbarFocus() {
      $('#favorite-results').css('margin-top', '0px');
    },
    onSearchbarBlur() {
      $('#favorite-results').removeAttr('style');
    },
    async fetchExperiences(categoryName:string): Promise<void> {
      const response = await SearchService.getExperiences( this.cityUnique, categoryName );
      this.experiences = Experiences.createFromApi( response );
    },
    handleExperienceItemClick(experience:Experience) {
      window.open( experience.url, '_blank' );
    },
    async cloneBadgeToMobileNavbar() {
      await this.$nextTick();
      const filterButton = document.getElementById('filter_button');
      const clonedBadgeIndicator = $('#filter-button-badge').clone().attr('id', 'mobile-navbar-filter-badge');
      if(clonedBadgeIndicator) {
        clonedBadgeIndicator.appendTo(filterButton);
      }
    },
    async handleNearbyFilterListScroll() {
      if(!this.isLoadingMoreNearbyDeals && this.loadMoreNearbyDeals) {
        const nearbyListRectBottom = this.nearbyFilterContainerElement.getBoundingClientRect().bottom;
        if (nearbyListRectBottom >= 0 && nearbyListRectBottom <= this.extendedViewportWithTwoRowsOfDeals) {
          this.isLoadingMoreNearbyDeals = true;
          this.nearbyPageInfo.currentPage += 1;
          const filterSearchResults:any = await FilterService.getNearbyFilterSearchResultByPoint(
              this.lastNearbyFilterConfig.point,
              this.lastNearbyFilterConfig.filterQueryString,
              this.nearbyPageInfo.currentPage);
          this.isLoadingMoreNearbyDeals = false;
          this.filteredDeals = this.filteredDeals.concat(Deal.createFromNearbyFilterResponse(filterSearchResults));
        }
      }
    },
    handleUpdatedNearbySearchbar(updatedNearbySearchbar:any) {
      const searchbarData = updatedNearbySearchbar.detail.searchbar;
      const updatedSearchbar = JSON.stringify({
        lat: searchbarData.latitude,
        long: searchbarData.longitude,
        placeholder: searchbarData.location
      });
      this.nearbySearchBarData = btoa(updatedSearchbar);
      if(updatedNearbySearchbar.detail.updateFilterDeals) {
        this.updateNearbyFilterLocation(searchbarData.latitude, searchbarData.longitude);
        this.getFilterSearchResults( false );
      }
    },
    handleActivateFilterModalFromNearbyInspirations( event:any ) {
      window.history.pushState( null, document.title, `?categories=${event.detail}` );
      this.isInitNearbyInspirations = false;

      window.removeEventListener('activate-after-nearby-inspirations', this.handleActivateFilterModalFromNearbyInspirations);
    },
    nearbyLocationUpdated(nearbyLocation:any) {
      const updatedSearchbarData = {
        lat: nearbyLocation.latitude,
        long: nearbyLocation.longitude,
        placeholder: nearbyLocation.locationLabel ?? nearbyLocation.location,
        place_id: nearbyLocation.place_id
      }
      this.nearbySearchBarData = btoa(JSON.stringify(updatedSearchbarData));
    }
  },
  mounted() {
    this.isInitNearbyInspirations = ( document.getElementById( 'dealMapBox') && document.getElementById('dealMapBox').classList.contains( 'hidden' ) );

    document.addEventListener('scroll', this.handleNearbyFilterListScroll);
    window.addEventListener('activate-after-nearby-inspirations', this.handleActivateFilterModalFromNearbyInspirations);
    window.addEventListener('updated-nearby-searchbar', this.handleUpdatedNearbySearchbar);
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.handleNearbyFilterListScroll);
    window.removeEventListener('activate-after-nearby-inspirations', this.handleActivateFilterModalFromNearbyInspirations);
    window.removeEventListener('updated-nearby-searchbar', this.handleUpdatedNearbySearchbar)
  },
  computed : {
    loadMoreNearbyDeals():boolean {
      return this.$data.nearbyPageInfo.totalPagesNearby > 1 ? this.$data.nearbyPageInfo.currentPage < this.$data.nearbyPageInfo.totalPagesNearby : false;
    },
    extendedViewportWithTwoRowsOfDeals() {
      //rowHeight = 400, so 4 rows
      return window.innerHeight + 1600;
    },
    FilterModalType() {
      return FilterModalType;
    },
    isMobile(): boolean {
      return sdViewport.isMobile();
    },
    planningElement(): HTMLElement {
      return document.getElementById( 'lastMinuteDeals' );
    },
    nearbyRowDealsElement(): HTMLElement {
      return document.getElementById( 'nearby-row-deals' );
    },
    nearbyFilterContainerElement(): HTMLElement {
      return document.getElementById( 'nearby-filter-container' );
    },
    detailBoxElement(): HTMLElement {
      return document.getElementById( 'detailBox' );
    },
    mobileFooterElement(): HTMLElement {
      return document.getElementsByClassName( 'mobile-footer' )[0] as HTMLElement;
    },
    openInAppElement(): HTMLElement {
      return document.getElementsByClassName( 'open-in-app' )[0] as HTMLElement;
    },
    showFilterDeals(): boolean {
      return this.badge?.label.length > 0 && !this.favoritesIsActive;
    },
    showFilterBadge():boolean {
      const showFilter = !this.hideFilterButton && !this.hideDealsWhenSearchBarIsActive && !this.favoritesIsActive;
      //nextTick because on page load the dom is not updated when hidden is removed from the filter button.
      this.$nextTick(() => {
        document.getElementById('filter_button')?.classList.remove('hidden');

        showFilter
            ? document.getElementById('mobile-navbar-filter-badge')?.classList.remove('hidden')
            : document.getElementById('mobile-navbar-filter-badge')?.classList.add('hidden');
      });
      return showFilter;
    },
    isFilterModalTypeHome():boolean {
      return this.filterModalType === FilterModalType.Home;
    },
    isFilterModalTypeNearby():boolean {
      return this.filterModalType === FilterModalType.Nearby;
    }
  }
} );

