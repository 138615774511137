export class Emoji {
    constructor(
        private readonly text: string
    ) { }

    public toHtml(): string {
        const emojis = this.text.split( ' ' );
        for ( let i = 0; i < emojis.length; i++ ) {
            emojis[ i ] = emojis[ i ].replace( 'U+', '&#x' );
        }

        return emojis.join( '' );
    }
}
