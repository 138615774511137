import ValueObject from "../../../../../frontend/ValueObject";
import { Price } from "./Price";

export interface ArrangementProps {
    title: string,
    discount: any,
    sales: null,
    from_price: Price,
    price: Price,
    sold_out: boolean,
    alerts: any,
    is_purchasable: boolean,
    is_lmd_for_sale: boolean,
    has_availability: boolean,
    multi_tab_name: string,
    pills?: any[],
    prices?: {
        discount_label: string,
        from_price: Price,
        price: Price
    },
    stats: {
        discount: string,
        sales: string
    },
    multi_deal_description: string,
    unique: string
}

export class Arrangement extends ValueObject<ArrangementProps> {
    get title() {
        return this._props.title ?? this._props.multi_deal_description;
    }

    get tab() {
        return this._props.multi_tab_name;
    }

    get fromPrice(): Price {
        return this._props.prices.from_price;
    }

    get price(): Price {
        return this._props.prices.price;
    }

    get stats(): any {
        return this._props.stats;
    }

    get pills(): any {
        return this._props.pills;
    }

    get unique(): any {
        return this._props.unique;
    }

    get isPurchasable() {
        return ( this._props.is_purchasable || this._props.is_lmd_for_sale ) && this._props.has_availability !== false;
    }

    static create( props: any ) {
        return new Arrangement( Object.assign( props, {
                title  : props.multi_deal_description ?? props.title,
                prices : {
                    discount_label : props.prices.discount_label,
                    sales_label    : props.stats.sales,
                    from_price     : Price.create( {
                        amount   : props.prices.from_price !== null ? props.prices.from_price.amount / 100 : 0,
                        currency : props.prices.from_price?.currency ?? { symbol : '€' }
                    } ),
                    price          : Price.create( {
                        amount   : props.prices.price !== null ? props.prices.price.amount / 100 : 0,
                        currency : props.prices.price?.currency ?? { symbol : '€' }
                    } ),
                },
                pills  : props.pills?.map( ( pill: any ) => {
                    return pill;
                } )
            }
        ) );
    }
}
