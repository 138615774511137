import ValueObject from "../../../../../../../frontend/ValueObject";
import { AvailabilityPrices } from "../../../../../../Deals/view/vue/domain/AvailabilityPrices";
import Month from "./Month";
import Day from "./Day";
import Week from "./Week";
import DateShortcut from "./DateShortcut";

export interface IDateShortcuts {
    items: DateShortcut[],
    selected?: string;
}

export interface ISDCalendar {
    months?: any,
    more?: any,
    params: any,
    reload: any,
    selected_value?: any,
    prices?: any,
    summary?: any,
    alerts?: any,
    type: string,
    box_checkin_checkout?: string,
    unselect: any,
    day_list: Day[],
    date_shortcuts: IDateShortcuts,
}

export default class SDCalendar extends ValueObject<ISDCalendar> {

    get params(): any {
        return this._props.params
    }

    get more(): any {
        return this._props.more;
    }

    get reload(): any {
        return this._props.reload;
    }

    get months(): Month[] {
        return this._props.months;
    }

    get selectedValue(): any {
        return this._props.selected_value;
    }

    get prices(): AvailabilityPrices {
        return this._props.prices ?? null;
    }

    get summary() {
        return this._props.summary;
    }

    get unselect(): any {
        return this._props.unselect;
    }

    get noAvailabilityAlert() {
        if ( this._props.alerts && this._props.alerts.no_availability ) {
            return this._props.alerts.no_availability;
        }

        return null;
    }

    get isRangeComplete(): boolean {
        return Boolean( this.selectedValue?.from && this.selectedValue?.till );
    }

    get isRangeCalendar(): boolean {
        return typeof this.selectedValue === "object" && this.selectedValue?.from
    }

    get isSingleDateCalendar(): boolean {
        return typeof this.selectedValue === "string";
    }

    get isFromDateSelected(): boolean {
        return Boolean( this.selectedValue?.from );
    }

    get isTillDateSelected(): boolean {
        return Boolean( this.selectedValue?.till );
    }

    get boxCheckinCheckout(): any {
        return this._props.box_checkin_checkout;
    }

    get type(): string {
        return this._props.type;
    }

    get dayList(): Day[] {
        return this._props.day_list;
    }

    get dateShortcuts(): DateShortcut[] {
        return this._props.date_shortcuts ? this._props.date_shortcuts.items : [];
    }

    get activeShortcut(): string | null {
        return this._props.date_shortcuts ? this._props.date_shortcuts.selected : null;
    }

    set prices( prices ) {
        this._props.prices = prices;
    }

    get toSearchBar(): any {
        return {
            date          : this.selectedValue,
            from_date     : this.selectedValue?.from,
            till_date     : this.selectedValue?.till,
            date_shortcut : this.activeShortcut
        }
    }

    public toAvailabilityDTO() {
        return {
            'selected_value[from]' : this.selectedValue?.from ? this.selectedValue?.from : this.selectedValue,
            'selected_value[till]' : this.selectedValue?.till,
        }
    }

    static fromApi( calendarData: any ): SDCalendar {
        const days = [] as Day[];
        const months = calendarData.months.map( ( month: any ) => Month.create( month ) );
        months.forEach( ( month: Month ) => month.weeks.forEach( ( week: Week ) => week.days.forEach( ( day: Day ) => days.push( day ) ) ) );

        return new SDCalendar( {
            months               : months,
            params               : calendarData.params,
            more                 : calendarData.more,
            reload               : calendarData.reload,
            selected_value       : calendarData.selected_value ?? null,
            summary              : calendarData.summary,
            prices               : calendarData.prices ? AvailabilityPrices.create( calendarData.prices ) : null,
            alerts               : calendarData.alerts ? calendarData.alerts : null,
            type                 : calendarData.type,
            box_checkin_checkout : calendarData.box_checkin_checkout ?? null,
            unselect             : calendarData.unselect,
            date_shortcuts       : calendarData.date_shortcuts ? {
                items    : calendarData.date_shortcuts.items.map( ( shortcut: any ) => DateShortcut.create( shortcut ) ),
                selected : calendarData.date_shortcuts.selected_unique
            } : null,
            day_list             : days
        } );
    }
}
