var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "details-arrangements-list" },
    _vm._l(_vm.arrangements, function (arrangement, arrangementKey) {
      return _c("DealArrangement", {
        key: arrangementKey,
        attrs: {
          arrangement: arrangement,
          "is-active": _vm.current && _vm.current.unique === arrangement.unique,
          "is-display-type-title": _vm.isDisplayTypeTitle,
        },
        on: {
          setArrangement: function ($event) {
            return _vm.setArrangement(arrangement)
          },
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }