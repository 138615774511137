var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "inbox" } },
    [
      _vm.isLoading
        ? [_c("Loader", { staticClass: "fixed" })]
        : [
            _vm.isSending ? _c("Loader", { staticClass: "fixed" }) : _vm._e(),
            _vm._v(" "),
            _vm.hasMessages
              ? [
                  _c(
                    "div",
                    { staticClass: "reversed-collection" },
                    [
                      _vm._l(
                        _vm.presentationMessageResponse.groups,
                        function (group, groupIndex) {
                          return _c(
                            "div",
                            [
                              _c("DateTimeLabel", {
                                attrs: { label: group.header },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "reversed-collection" },
                                _vm._l(
                                  group.messages,
                                  function (presentationMessage, messageIndex) {
                                    return _c(
                                      "div",
                                      { key: messageIndex },
                                      [
                                        _vm.presentationMessageResponse
                                          .presentation !== undefined &&
                                        _vm.presentationMessageResponse
                                          .presentation.new_message_label !==
                                          undefined &&
                                        presentationMessage.presentation
                                          .show_new_message_label
                                          ? _c("Divider", {
                                              attrs: {
                                                text: _vm
                                                  .presentationMessageResponse
                                                  .presentation
                                                  .new_message_label,
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "Message",
                                          _vm._b(
                                            {
                                              staticClass: "inbox-message",
                                              class: {
                                                "first-message":
                                                  groupIndex === 0 &&
                                                  messageIndex === 0,
                                              },
                                              attrs: {
                                                show_avatar:
                                                  presentationMessage
                                                    .presentation.show_avatar,
                                                presentation_time:
                                                  presentationMessage
                                                    .presentation.time,
                                              },
                                            },
                                            "Message",
                                            presentationMessage.message,
                                            false
                                          )
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          )
                        }
                      ),
                      _vm._v(" "),
                      _vm.presentationMessageResponse.next
                        ? _c(
                            "div",
                            { staticClass: "load-more-loader" },
                            [_c("Loader")],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.subMessageVisible
                    ? _c("div", {
                        staticClass: "sub-message",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.postPresentationMessageResponse.presentation
                              .confirmation_label
                          ),
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "fixed-section" }, [
                    _c("div", { staticClass: "message-form" }, [
                      _c("div", {
                        ref: "contentEditable",
                        staticClass: "content-editable",
                        attrs: {
                          contenteditable: "true",
                          placeholder: _vm.$t(
                            "26138.App_InboxInputPlaceholder"
                          ),
                        },
                        on: {
                          click: function ($event) {
                            _vm.focus = true
                          },
                          paste: _vm.onPaste,
                          input: _vm.onInput,
                          keydown: _vm.onKeyDown,
                        },
                      }),
                      _vm._v(" "),
                      _vm.showSendMessageButton
                        ? _c(
                            "div",
                            {
                              staticClass: "message-submit-link",
                              class: _vm.focus ? "focus" : "",
                              on: { click: _vm.submitMessage },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.$t("26139.App_InboxInputSendButton")
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]
              : [
                  _c("div", { staticClass: "empty-state" }, [
                    _c("img", {
                      attrs: {
                        src: "https://media.socialdeal.nl/img/chat-empty-state.png",
                        width: "260",
                        height: "180",
                        alt: _vm.$t("26116.App_InboxTitleNoMessage"),
                      },
                    }),
                    _vm._v(" "),
                    _c("h2", [
                      _vm._v(_vm._s(_vm.$t("26116.App_InboxTitleNoMessage"))),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(_vm._s(_vm.$t("26117.App_InboxMessageNoMessage"))),
                    ]),
                  ]),
                ],
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }