import { ISearchBar, SearchBar } from "./SearchBar";
import { ITag } from "../components/TagSelect.vue";

export interface INearbySearchBar extends ISearchBar {
    unique?: string | null,
    nearby_identifier: string,
    nearby_label: string,
    nearby_view: string,
}

export default class NearbySearchBar extends SearchBar<INearbySearchBar> {
    get unique() {
        return this._props.unique;
    }

    get nearbyView() {
        return this._props.nearby_view;
    }

    public setNearbyView( tag: ITag ) {
        return this._props.nearby_view = tag.view;
    }

    get nearbyIdentifier() {
        return this._props.nearby_identifier;
    }

    public setNearbyIdentifier( tag: ITag ) {
        return this._props.nearby_identifier = tag.identifier;
    }

    get nearbyLabel() {
        return this._props.nearby_label;
    }

    public setNearbyLabel( tag: ITag ) {
        return this._props.nearby_label = tag.label;
    }

    static fromApi( props: any ) {
        return new NearbySearchBar( {
            ...props,
            ...props.search_bar
        } );
    }

    public toSearchBarDTO(): any {
        return {
            unique            : this.unique,
            latitude          : this.latLng.lat,
            longitude         : this.latLng.lng,
            location          : this.location,
            nearby_identifier : this.nearbyIdentifier,
            nearby_view       : this.nearbyView,
            nearby_label      : this.nearbyLabel,
        }
    }
}
