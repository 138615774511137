var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "calendar-no-availability" }, [
    _c("div", { staticClass: "calendar-no-availability-box" }, [
      _c("div", { staticClass: "calendar-no-availability-box-title" }, [
        _vm._v("\n      " + _vm._s(_vm.alert.title) + "\n    "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "calendar-no-availability-box-content" }, [
        _vm._v("\n      " + _vm._s(_vm.alert.message) + "\n    "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "calendar-no-availability-box-button" }, [
        _c("a", { attrs: { href: "tel:+310882050505" } }, [
          _vm._v(_vm._s(_vm.alert.buttons[0].label)),
        ]),
        _vm._v(" "),
        _c(
          "span",
          {
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [_vm._v(_vm._s(_vm.alert.buttons[1].label))]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }