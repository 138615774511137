export type GetPresentationMessagesPayload = {
    // This is not necessary for the first call. Last previously received date in YYYY-MM-DD format. Messages from before this date will be fetched.
    last?: string
}
export type PresentationMessagesResponse = {
    next?: string;
    groups: Group[];
    presentation?: {
        new_message_label?: string,
    }
}

export type UnreadMessageCountResponse = {
    count: number;
    has_unread_messages: boolean,
    popup_duration_seconds: number
}

export type PostPresentationMessagePayload = {
    id?: string;
    type: string;
    content: {
        text: string;
    };
}

export type PostReadReceiptPayload = {
    id: string;
    message_id:string;
}

export type PostPresentationMessageResponse = {
    presentation: {
        confirmation_label: string;
    }
}

type Group = {
    header: string;
    messages: MessagePresentation[];
}

type MessagePresentation = {
    message: Message;
    presentation: Presentation
}

type Presentation = {
    show_avatar: boolean;
    show_new_message_label: boolean;
    time: string;
}

export type Message = {
    id: string;
    type: MessageType;
    sent_by: User;
    sent_on: string;
    read_on: string;
    content: MessageText | MessageLink;
}

export type User = {
    avatar?: string;
    email: string;
    initial?: string;
    first_name: string;
    last_name:string;
    is_current_user: boolean;
}
export enum MessageType {
    PLAINTEXT = 'plain_text',
    LINK = 'link'
}

export type MessageText = {
    text: string;
}

export type MessageLink = {
    href:string,
    type:LinkType,
    text:string,
    preview:LinkPreview
}

enum LinkType {
    PAYMENTREQUEST = 'payment_request'
}

type LinkPreview = {
    icon:string,
    header:string,
    description:string
}