import ValueObject from "../../../../../frontend/ValueObject";
import { IExperiences } from "./interfaces/IExperiences";
import { Experience } from "./Experience";

export class Experiences extends ValueObject<IExperiences> implements  IExperiences {

    public get experiences(): Array<Experience> {
        return this._props.experiences;
    }

    public get index(): number {
        return this._props.index;
    }

    public get title(): string {
        return this._props.title;
    }

    public static createFromApi( props: any ): Experiences {

        return new Experiences({
            experiences: Experience.createFromApi( props.experiences ),
            title: props.title,
            index: props.index
        })
    }
}
