

























import Vue, { PropType } from "vue";
import { debounce, find } from "@s-libs/micro-dash";

interface AmountOption {
  value_amount: any,
  value_label: any,
  key: any
}

export default Vue.extend( {
  name     : 'AmountSelect',
  props    : {
    options  : {
      type     : Array as PropType<AmountOption[]>,
      required : true
    },
    value    : {
      type     : Number,
      required : true
    },
    disabled : {
      type    : Boolean,
      default : false
    }
  },
  computed : {
    optionIndex(): number {
      return this.options.findIndex( option => option.key === this.value )
    },
    maxReached(): boolean {
      return ( this.optionIndex + 1
             ) === this.options.length;
    },
    minReached(): boolean {
      return this.optionIndex === 0;
    },
    valueOption(): any {
      return this.options.find( ( option: any ) => option.key === this.value )
    }
  },
  methods  : {
    increaseAmount(): void {
      if ( this.maxReached || this.disabled ) {
        return;
      }

      const key = this.valueOption.key;

      this.debouncedSubmit( () => {
        this.$emit( 'change', key + 1 );
      } );
    },
    decreaseAmount(): void {
      if ( this.minReached || this.disabled ) {
        return;
      }

      const key = this.valueOption.key;
      this.debouncedSubmit( () => {
        this.$emit( 'change', key - 1 );
      } );
    },
    debouncedSubmit : debounce( ( callback: Function ): any => {
      return callback();
    }, 600 ),
    handleSelectChange( e: any ): void {
      this.$emit( 'change', parseInt( e.target.value ) );
    }
  }
} )
