var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "popular-phrase-container" }, [
    _c("div", { staticClass: "popular-phrase-label" }, [
      _vm._v(_vm._s(_vm.popularPhrase.title)),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "popular-phrase-list" },
      _vm._l(_vm.popularPhrase.items, function (item, index) {
        return _c("PopularPhraseItem", {
          key: index,
          attrs: { item: item },
          on: {
            itemClick: function ($event) {
              return _vm.handleItemClick(item)
            },
          },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }