import { render, staticRenderFns } from "./PopularPhraseList.vue?vue&type=template&id=e25ac01a&scoped=true&"
import script from "./PopularPhraseList.vue?vue&type=script&lang=ts&"
export * from "./PopularPhraseList.vue?vue&type=script&lang=ts&"
import style0 from "./PopularPhraseList.vue?vue&type=style&index=0&id=e25ac01a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e25ac01a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/code/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e25ac01a')) {
      api.createRecord('e25ac01a', component.options)
    } else {
      api.reload('e25ac01a', component.options)
    }
    module.hot.accept("./PopularPhraseList.vue?vue&type=template&id=e25ac01a&scoped=true&", function () {
      api.rerender('e25ac01a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "module/Deals/view/vue/components/lists/PopularPhraseList.vue"
export default component.exports