import { render, staticRenderFns } from "./DealArrangementsList.vue?vue&type=template&id=0944e906&"
import script from "./DealArrangementsList.vue?vue&type=script&lang=ts&"
export * from "./DealArrangementsList.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/code/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0944e906')) {
      api.createRecord('0944e906', component.options)
    } else {
      api.reload('0944e906', component.options)
    }
    module.hot.accept("./DealArrangementsList.vue?vue&type=template&id=0944e906&", function () {
      api.rerender('0944e906', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "module/Deals/view/vue/components/DealArrangementsList.vue"
export default component.exports