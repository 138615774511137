var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "searchBarModal",
      staticClass: "search-bar-modal",
      class: [_vm.active ? "active" : ""],
      attrs: { id: "search-bar-modal" },
    },
    [
      _c("div", { staticClass: "modal-heading" }, [
        _c("h3", [_vm._v(_vm._s(_vm.$t("12010.App_SearchDataScreenTitle")))]),
        _vm._v(" "),
        _c(
          "i",
          {
            staticClass: "material-icons close-icon",
            on: { click: _vm.closeSelf },
          },
          [_vm._v(" close ")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal-spacing" },
        [
          _c("CitySelect", {
            attrs: { labels: _vm.labels, value: _vm.searchBar.location },
            on: { select: _vm.setCity },
          }),
          _vm._v(" "),
          _vm.filters
            ? _c("TagSelect", {
                attrs: {
                  tags: _vm.filters,
                  value: _vm.searchBar.nearbyIdentifier,
                },
                on: { select: _vm.selectTag },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("ModalSubmitButton", {
        attrs: { type: "nearby" },
        on: { submit: _vm.submit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }