var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.type, attrs: { id: "hotels-availability" } },
    [
      _vm.availability.arrangements.length > 1
        ? _c("h2", { attrs: { id: "arrangement-title" } }, [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.$t("15083.App_DealDetailsBeschikbaarheidReserveerTitle")
                ) +
                "\n  "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.availability.tabs &&
      _vm.availability.tabs.length > 1 &&
      _vm.tabsFunctionalityActive
        ? _c("DealTabs", {
            attrs: { current: _vm.currentTab, tabs: _vm.availability.tabs },
            on: { set: _vm.setTab },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.filteredArrangements && _vm.availability.arrangements.length > 1
        ? _c("DealArrangements", {
            attrs: {
              alerts: _vm.availability.alerts,
              arrangements: _vm.filteredArrangements,
              current: _vm.currentArrangement,
              "deal-type": _vm.type,
              "display-type": _vm.availability.arrangementDisplayType,
            },
            on: { select: _vm.setArrangementAndSubmit },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.availability.arrangements.length <= 1
        ? _c("h2", { attrs: { id: "arrangement-title" } }, [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.$t("15083.App_DealDetailsBeschikbaarheidReserveerTitle")
                ) +
                "\n  "
            ),
          ])
        : !_vm.isFlexFlow
        ? _c(
            "h2",
            { staticClass: "mt-4", attrs: { id: "availability-title" } },
            [
              _vm._v(
                "\n    " + _vm._s(_vm.$t("4180.App_linkAvailability")) + "\n  "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { id: "amount-wrapper" } },
        [
          _vm.availability.amount && _vm.amount
            ? _c("AmountSelect", {
                attrs: {
                  disabled: !_vm.isForSale,
                  options: _vm.availability.amount.options,
                  value: _vm.amount,
                },
                on: { change: _vm.setAmount },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.availability.additionalAmount && _vm.additionalAmount
            ? _c("AmountSelect", {
                attrs: {
                  disabled: !_vm.isForSale,
                  options: _vm.availability.additionalAmount.options,
                  value: _vm.additionalAmount,
                },
                on: { change: _vm.setAdditional },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.calendar && _vm.calendar.boxCheckinCheckout
        ? _c(
            "div",
            {
              attrs: { id: "range-placeholder-wrapper" },
              on: { click: _vm.scrollToRangeSelect },
            },
            [
              _c("DateRangePlaceholder", {
                attrs: { box: _vm.calendar.boxCheckinCheckout },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { id: "hotels-availability-calendar" } },
        [
          _vm.calendar
            ? _c("SDCalendar", {
                attrs: { calendar: _vm.calendar, type: "hotels" },
                on: { loadMore: _vm.loadMore, update: _vm.updateCalendar },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.buyNowButtonType
        ? _c(_vm.buyNowButtonType, {
            tag: "component",
            attrs: {
              caption: _vm.isRestaurantsFlow
                ? null
                : _vm.availability.button.caption,
              text: _vm.isRestaurantsFlow ? _vm.buttonText : null,
              "is-for-sale": _vm.isForSale,
              prices:
                _vm.calendar !== undefined && _vm.calendar.prices !== undefined
                  ? _vm.calendar.prices
                  : _vm.availability.dealPrices,
              summary: _vm.isRestaurantsFlow
                ? _vm.availability.labels.call_to_action
                : _vm.calendar.summary,
              icon: _vm.isRestaurantsFlow ? "cart" : "hotel",
              type: _vm.type,
            },
            on: { submit: _vm.manualSubmitCart },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.drawerOrModalActive || _vm.isMobile
        ? _c(
            "BottomDrawer",
            {
              attrs: {
                visible: _vm.drawerOrModalVisible,
                "drawer-title": _vm.drawerTitle,
                "is-in-modal": !_vm.isMobile && _vm.isFlexFlow,
                "is-loading": _vm.isLoading,
              },
              on: { closeSelf: _vm.manualCloseDrawer },
            },
            [
              _c(
                "div",
                {
                  staticClass: "modal-list-wrapper",
                  class: { "with-timeslots": _vm.hasTimeSlots },
                },
                [
                  _vm.hasTimeSlots
                    ? _c("TimeSlots", {
                        attrs: { items: _vm.timeSlots, justify: "start" },
                        on: { select: _vm.setTimeSlot },
                      })
                    : _vm.availability.arrangementOptions.length > 1
                    ? _c("DealArrangementsOptions", {
                        attrs: {
                          arrangementOptions:
                            _vm.availability.arrangementOptions,
                          current: _vm.currentArrangementOption,
                          "deal-type": _vm.type,
                        },
                        on: {
                          select: _vm.setArrangementOptionAndSubmit,
                          close: _vm.manualCloseDrawer,
                          "reset-calendar": _vm.closeDrawerAndResetCalendar,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.currentAlert
        ? _c("Alert", {
            attrs: { alert: _vm.currentAlert },
            on: {
              onConfirm: _vm.closeAlertScrollArrangements,
              onDecline: _vm.closeAlerts,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }