var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "filter-modal" }, [
    _c("div", { staticClass: "open-filter-button-container" }, [
      _c(
        "button",
        {
          staticClass: "open-filter-button",
          on: { click: _vm.onFilterButtonClick },
        },
        [
          _c("i", { staticClass: "sd2-icons filter open-filter-button-icon" }),
          _vm._v(" "),
          _c("span", { staticClass: "open-filter-button-text" }, [
            _vm._v(_vm._s(_vm.$t("36078.App_FilterHomeLabelButton"))),
          ]),
          _vm._v(" "),
          _vm.badge && _vm.showBadge
            ? _c(
                "span",
                {
                  staticClass: "open-filter-button-count",
                  style: { background: _vm.badge.color },
                  attrs: { id: "filter-button-badge" },
                },
                [_vm._v(_vm._s(_vm.badge.label))]
              )
            : _vm._e(),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      [
        _vm.showFilterModal
          ? _c(
              "BottomDrawer",
              {
                staticClass: "filter-modal-open",
                attrs: {
                  visible: true,
                  "header-action-title": _vm.$t(
                    "36112.App_FilterResetButtonTop"
                  ),
                  "drawer-title": _vm.$t("36113.App_FilterTitleFilterScreen"),
                  "is-in-modal": !_vm.isMobile,
                  "is-loading": _vm.loadState.showLoader,
                  "is-large": true,
                },
                on: {
                  "header-action-click": _vm.resetFilter,
                  closeSelf: _vm.closeSelf,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "footer",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "filter-button-container" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "filter-button",
                                  on: {
                                    click: function ($event) {
                                      return _vm.filterOnActiveCriteria(
                                        _vm.FilterFlow.Filter
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "filter-button-text" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.filterResultsTranslation)
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.loadCitySelect
                            ? _c(
                                "div",
                                {
                                  staticClass: "over-whole-modal",
                                  class: { active: _vm.visibleCitySelect },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "city-select-header" },
                                    [
                                      _c("h6", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "22348.App_InspirationLocationInputTitle"
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "close-wrap",
                                          on: { click: _vm.closeCitySelect },
                                        },
                                        [
                                          _c(
                                            "i",
                                            { staticClass: "material-icons" },
                                            [_vm._v("close")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("CitySelect", {
                                    attrs: {
                                      labels: _vm.labels,
                                      value: null,
                                      "is-in-filter": true,
                                      "is-opened": true,
                                    },
                                    on: { select: _vm.setCity },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  1237122212
                ),
              },
              [
                !_vm.loadState.hideContent
                  ? _c("div", { staticClass: "filter-content-container" }, [
                      _c(
                        "div",
                        { staticClass: "filter-content" },
                        [
                          _c("span", { staticClass: "filter-item-title" }, [
                            _vm._v(
                              _vm._s(_vm.$t("36081.App_FilterSortByFilter"))
                            ),
                          ]),
                          _vm._v(" "),
                          _c("SortList", {
                            key: _vm.sortListRenderKey,
                            staticClass: "filter-component",
                            attrs: {
                              "sort-list-items":
                                _vm.filter.sections.sorting.options,
                              "selected-value":
                                _vm.filter.sections.sorting.selected,
                            },
                            on: {
                              "sort-list-item-click":
                                _vm.handleSortListItemClick,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.filterModalType === _vm.FilterModalType.Nearby
                        ? _c(
                            "div",
                            { staticClass: "filter-content" },
                            [
                              _c("span", { staticClass: "filter-item-title" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "37423.App_FilterNearbyLocationTitle"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("SearchLocation", {
                                staticClass: "filter-component",
                                attrs: {
                                  location:
                                    _vm.formattedNearbySearchBar.placeholder,
                                },
                                on: {
                                  "on-search-location-click":
                                    _vm.showCitySelect,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "filter-content-category" },
                        [
                          _c("span", { staticClass: "filter-item-title" }, [
                            _vm._v(
                              _vm._s(_vm.$t("36082.App_FilterCategoryTitle"))
                            ),
                          ]),
                          _vm._v(" "),
                          _c("CategoryFilter", {
                            key: _vm.categoryRenderKey,
                            staticClass: "filter-component",
                            attrs: {
                              "category-items":
                                _vm.filter.sections.categories.options,
                              "selected-categories":
                                _vm.filter.sections.categories.selected,
                              "select-all-key":
                                _vm.filter.sections.categories.select_all_key,
                            },
                            on: {
                              "on-category-clicked": _vm.handleCategoryClicked,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "filter-content" },
                        [
                          _c("span", { staticClass: "filter-item-title" }, [
                            _vm._v(
                              _vm._s(_vm.$t("36083.App_FilterPriceTitle"))
                            ),
                          ]),
                          _vm._v(" "),
                          _c("PriceSlider", {
                            staticClass: "filter-component price-slider",
                            attrs: {
                              "parent-top-position": _vm.parentTopPosition,
                              "price-range": _vm.filter.sections.price_range,
                            },
                            on: {
                              "price-slider-values-changed":
                                _vm.handleSliderValuesChanged,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "distance-filter filter-content" },
                        [
                          _c("span", { staticClass: "filter-item-title" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "36108.App_FilterDistanceFromYourLocation"
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("DistanceFilter", {
                            ref: "distanceFilter",
                            staticClass: "filter-component",
                            attrs: {
                              "is-distance-options-open":
                                _vm.isDistanceOptionsOpen,
                              "selected-distance-key":
                                _vm.filter.sections.distance.selected,
                              "distance-options": _vm.mappedDistanceFilterItems,
                              "parent-top-position": _vm.parentTopPosition,
                              "current-location-text":
                                _vm.filter.sections.distance.label,
                            },
                            on: {
                              "on-current-location-clicked":
                                _vm.onCurrentLocationClick,
                              "on-distance-changed": _vm.onDistanceChanged,
                              "toggle-distance-options":
                                _vm.toggleDistanceOptions,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.filter.sections.rating
                        ? _c(
                            "div",
                            { staticClass: "filter-content" },
                            [
                              _c("span", { staticClass: "filter-item-title" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("36111.App_FilterReviewTitle"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("ReviewFilter", {
                                key: _vm.ratingListRenderKey,
                                staticClass: "filter-component",
                                attrs: {
                                  "review-items":
                                    _vm.filter.sections.rating.options,
                                  "init-selected-review-key":
                                    _vm.filter.sections.rating.selected,
                                },
                                on: {
                                  "review-filter-item-click":
                                    _vm.handleReviewItemClick,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }