import ValueObject from "../../../../../frontend/ValueObject";
import {CurrencyDTO, PriceDTO} from "./dto/PricesDTO";

export interface IPrice {
    amount: number,
    currency?: CurrencyDTO,
    locale?: string,
}

export class Price extends ValueObject<IPrice> {
    get currency(): CurrencyDTO {
        return this._props.currency;
    }

    get amount(): number {
        return this._props.amount;
    }

    get locale(): string {
        return this._props.locale.toUpperCase();
    }

    get value(): string {
        return this.toHtml(true);
    }

    toString() {
        const {base, decimals} = this.format();
        const currencySymbol = this.currency?.symbol ?? '€';

        let priceString = base.toString();
        if (decimals > 0) {
            priceString += ',' + Math.floor(decimals);
        }
        switch (this.locale) {
            case 'DE':
            case 'FR':
                return priceString + currencySymbol;
            default:
                return currencySymbol + priceString;
        }
    }

    format() {
        let base = Math.floor(this.amount);
        let decimals = parseFloat((this.amount % 1
        ).toFixed(2)) * 100;

        return {
            base,
            decimals
        }
    }

    toHtml(bigger: boolean = false): string {
        const {base, decimals} = this.format();
        const currencySymbol = this.currency?.symbol ?? '€';

        let html = base.toString();
        if ( decimals > 0 ) {
            html += bigger ? '<span>' : '<sub>';
            html += ',';
            html += ( '0' + decimals ).slice( -2 );
            html += bigger ? '</span>' : '</sub>';
        }
        switch (this.locale) {
            case 'DE':
            case 'FR':
                return html + currencySymbol;
            default:
                return currencySymbol + html;
        }
    }

    static create(amountObject: any): Price {
        return new Price({
            amount: amountObject.amount,
            currency: amountObject.currency,
            locale: amountObject.locale ?? document.body.getAttribute('data-locale') ?? 'NL'
        });
    }

    static createPrice(price: PriceDTO): Price {
        return new Price({
            amount: price.amount / 100,
            currency: price.currency,
            locale: document.body.getAttribute('data-locale') ?? 'NL'
        });
    }
}
