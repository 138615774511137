import ValueObject from "../../../../../frontend/ValueObject";
import { IPopularPhraseItem } from "./interfaces/IPopularPhraseItem";

export class PopularPhraseItem extends ValueObject<IPopularPhraseItem> implements IPopularPhraseItem {
    public get backgroundColor(): { hex: string } {
        return this._props.backgroundColor;
    }

    public get highlightedBorderColor(): { hex: string } {
        return this._props.highlightedBorderColor;
    }

    public get label(): string {
        return this._props.label;
    }

    public get query(): string {
        return this._props.query;
    }

    public get textColor(): { hex: string } {
        return this._props.textColor;
    }

    public static createFromApi( data : Array<any> ): Array<PopularPhraseItem> {
        return data.map( ( props : any ) => new PopularPhraseItem({
            textColor: {
                ...props.text_color
            },
            query: props.query,
            label: props.label,
            highlightedBorderColor: {
                ...props.highlighted_border_color
            },
            backgroundColor: {
                ...props.background_color
            }
        } ) );
    }
}
