import ValueObject from "../../../../../../../frontend/ValueObject";
import Week from "./Week";

export interface ICalendarMonth {
    header: string[],
    title: string,
    weeks: Week[]
}

export default class Month extends ValueObject<ICalendarMonth> {

    get title() {
        return this._props.title;
    }

    get header() {
        return this._props.header;
    }

    get weeks() : Week[] {
        return this._props.weeks;
    }

    public static create(props : any) {
        return new Month( {
            header: props.header,
            title: props.title,
            weeks: props.weeks.map((week : any) => Week.create( week ))
        } );
    }
}