var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-buttons" },
    _vm._l(_vm.buttons, function (button, buttonKey) {
      return _c(
        "div",
        {
          key: buttonKey,
          ref: "dateShortcutButtons-" + button.unique,
          refInFor: true,
          staticClass: "search-btn",
          class: { active: button.unique === _vm.activeUnique },
          attrs: { id: "search-btn " + button.id },
          on: {
            click: function ($event) {
              return _vm.clickButton($event, button)
            },
          },
        },
        [
          _c("span", {
            staticClass: "icon",
            domProps: { innerHTML: _vm._s(button.icon.toHtml()) },
          }),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(button.label))]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }