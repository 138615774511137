











import Vue, {PropType} from "vue";
import {Deal} from "../../domain/Deal";
import DealCardAutocomplete from "../items/AutocompleteDealItem.vue";

export default Vue.extend({
  name: 'AutocompleteDeals',
  components: { DealCardAutocomplete },
  props: {
    deals: {
      type: Array as PropType<Array<Deal>>
    }
  },
  methods: {
    handleLoading() {
      this.$emit('loading');
    }
  }
})
