











import Vue, {PropType} from "vue";
export default Vue.extend( {
  name  : 'SearchLocation',
  props : {
    location : {
      type : String
    }
  },
  data() {
    return {
      selectedDistance:this.$props.selectedValue,
      isDistanceOptionsActive: false,
      parentBottomPosition: 0
    }
  },
  methods: {
    decodeEntities(encodedString: string) : string {
      const textArea = document.createElement('textarea');
      textArea.innerHTML = encodedString;
      return textArea.value;
    },
    onClick() {
      this.$emit('on-search-location-click')
    },
  },
  computed: {
    locationOrNearby(): string {
      return this.location ?? ( this as any ).$t( '12013.App_NearbyDealsLabel' );
    }
  }
} );
