import ValueObject from "../../../../../frontend/ValueObject";
import {IExperienceParams} from "./interfaces/IExperienceParams";

export class ExperienceParams extends ValueObject<IExperienceParams> implements IExperienceParams{

    public get experience(): string {
        return this._props.experience;
    }

    public get experience_id(): number {
        return this._props.experience_id;
    }

    public get tag(): string {
        return this._props.tag;
    }

    public get target(): string {
        return this._props.target;
    }
}
