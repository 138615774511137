var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "date-range-placeholder" }, [
    _c("div", { staticClass: "check-in" }, [
      _c("div", { staticClass: "range-label" }, [
        _vm._v("\n      " + _vm._s(_vm.box.check_in.title) + "\n    "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "range-placeholder",
          class: { active: _vm.box.check_in.value },
        },
        [_vm._v("\n      " + _vm._s(_vm.checkInValueOrPlaceholder) + "\n    ")]
      ),
    ]),
    _vm._v(" "),
    _c("i", {
      staticClass: "material-icons",
      attrs: { "data-ligature": "arrow_forward" },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "check-out" }, [
      _c("div", { staticClass: "range-label" }, [
        _vm._v("\n      " + _vm._s(_vm.box.check_out.title) + "\n    "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "range-placeholder",
          class: { active: _vm.box.check_out.value },
        },
        [_vm._v("\n      " + _vm._s(_vm.checkOutValueOrPlaceholder) + "\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }