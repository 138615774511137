var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "category-filter" }, [
    _vm.isDesktop && _vm.showLeftNavigationButton
      ? _c(
          "div",
          {
            staticClass: "category-navigation category-navigation-left",
            on: {
              click: function ($event) {
                return _vm.onNavigateLeftClick()
              },
            },
          },
          [
            _c("i", {
              staticClass: "material-icons",
              attrs: { "data-ligature": "arrow_back" },
            }),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { ref: "categoryContainer", staticClass: "category-filter-container" },
      _vm._l(_vm.categoryItems, function (category, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "category-item-container",
            class: [
              "category-container-" + index,
              { "last-category-row-item": _vm.isLastCategoryRow(index) },
            ],
            on: {
              mouseover: function ($event) {
                return _vm.setHoveredCategory(category.key)
              },
              mouseleave: function ($event) {
                return _vm.removeHoveredCategory()
              },
              click: function ($event) {
                return _vm.toggleActiveCategory(category.key)
              },
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "selection-circle",
                style: category.isSelected
                  ? {
                      borderColor: category.color,
                      backgroundColor: category.color,
                    }
                  : {},
              },
              [
                category.isSelected
                  ? _c("i", {
                      staticClass: "sd2-icons selection-check-icon check",
                    })
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "category-filter-item",
                style: category.isSelected
                  ? { borderColor: category.color, borderWidth: "2px" }
                  : {},
              },
              [
                _c("div", { staticClass: "icon" }, [
                  _c("div", {
                    staticClass: "sd2-icons category-filter-icon",
                    class: "tagcloud-" + category.icon,
                    style: _vm.isCategoryActive(
                      category.key,
                      category.isSelected
                    )
                      ? { color: category.color }
                      : {},
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "title",
                    style: _vm.isCategoryActive(
                      category.key,
                      category.isSelected
                    )
                      ? { color: category.color, fontWeight: 600 }
                      : {},
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(category.label) + "\n        "
                    ),
                  ]
                ),
              ]
            ),
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _vm.isDesktop && _vm.showRightNavigationButton
      ? _c(
          "div",
          {
            staticClass: "category-navigation category-navigation-right",
            on: {
              click: function ($event) {
                return _vm.onNavigateRightClick()
              },
            },
          },
          [
            _c("i", {
              staticClass: "material-icons",
              attrs: { "data-ligature": "arrow_forward" },
            }),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }