var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "alert-wrapper",
      class: _vm.alert.positionAbsolute ? "absolute" : "",
    },
    [
      _c("div", { staticClass: "alert-inner" }, [
        _c("div", { staticClass: "alert-title" }, [
          _vm._v("\n      " + _vm._s(_vm.alert.title) + "\n    "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "alert-content" }, [
          _vm._v("\n      " + _vm._s(_vm.alert.message) + "\n    "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "alert-buttons" },
          [
            _vm.alert.button
              ? _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.$emit("onConfirm")
                      },
                    },
                  },
                  [_vm._v("\n        " + _vm._s(_vm.alert.button) + "\n      ")]
                )
              : _vm.alert.buttons.length > 0
              ? _vm._l(_vm.alert.buttons, function (button, buttonKey) {
                  return _c(
                    "span",
                    {
                      key: buttonKey,
                      on: {
                        click: function ($event) {
                          return _vm.action(button.action)
                        },
                      },
                    },
                    [_vm._v("\n        " + _vm._s(button.label) + "\n      ")]
                  )
                })
              : _vm._t("default"),
            _vm._v(" "),
            _vm.alert.decline
              ? _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.$emit("onDecline")
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.alert.decline) + "\n      "
                    ),
                  ]
                )
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }