import Entity from "../../../../../frontend/Entity";
import { IPlanningResponse } from "../../../../../frontend/services/PlanningService";
import { SearchProduct } from "../../../../../frontend/services/search/SearchProduct";
import {Prices} from "./Prices";
import {DealTravelDistance, INearbyFilterResponse} from "../../../../../frontend/services/FilterService";

const hexagonColors: any = {
    "food"     : {
        name  : "food",
        color : "rgb(0, 174, 234)"
    },
    "events"   : {
        name  : "events",
        color : "rgb(42, 201, 161)"
    },
    "hotel"    : {
        name  : "hotel",
        color : "rgb(108, 41, 204)"
    },
    "wellness" : {
        name  : "wellness",
        color : "rgb(191, 32, 196)"
    },
    "store"    : {
        name  : "store",
        color : "rgb(245, 44, 45)"
    },
    "sport"    : {
        name  : "sport",
        color : "rgb(42, 62, 188)"
    },
    "course"   : {
        name  : "course",
        color : "rgb(237, 150, 47)"
    }
};

export interface IDeal {
    id: string;
    link: string;
    discount: string;
    imageUrl: string;
    title: string;
    city: string;
    hexagon: {
        color: string;
        name: string;
    } | null,
    company: string;
    location: string;
    review: string;
    prices: Prices,
    soldLabel: string;
    campaignUnique: string;
    tags: Array<string>;
    rank: string;
    dealUnique: string;
    ga?: Array<any>;
    travelDistance?: DealTravelDistance,
}
``
export class Deal extends Entity<IDeal> implements IDeal {

    constructor( props: IDeal ) {
        super( props, props.id );
    }

    public get company(): string {
        return this._props.company;
    }

    public get city(): string {
        return this._props.city;
    }

    public get discount(): string {
        return this._props.discount;
    }
    public get travelDistance(): DealTravelDistance {
        return this._props.travelDistance;
    }
    public get hexagon(): IDeal["hexagon"] {
        return this._props.hexagon;
    }

    public get imageUrl(): string {
        if ( !this._props.imageUrl ) {
            return ``;
        }

        // TODO: Weghalen halen zodra alle deals in Tweakwise relative path heeft
        if ( this._props.imageUrl.startsWith( 'http' ) ) {
            return this._props.imageUrl;
        }

        // TODO: Vervangen Base URL vanuit Config
        return `https://images.socialdeal.nl${this._props.imageUrl}`;
    }

    public get link(): string {
        return this._props.link;
    }

    public get location(): string {
        return this._props.location;
    }
    public get prices(): Prices {
        return this._props.prices;
    }

    public get review(): string {
        return this._props.review;
    }

    public get soldLabel(): string {
        return this._props.soldLabel;
    }

    public get title(): string {
        return this._props.title;
    }

    public get campaignUnique(): string {
        return this._props.campaignUnique;
    }

    public get tags(): Array<string> {
        return this._props.tags;
    }

    public get rank(): string {
        return this._props.rank;
    }

    public get dealUnique(): string {
        return this._props.dealUnique;
    }

    public static createFromSearchPredictionsResponse( items: Array<SearchProduct>, preferenceCity: string ): Array<Deal> {
        return items.map( item => {
            const tags = item.getAttribute( 'tags' );

            let hexagon = null;
            if( item.prices?.price?.amount > 0 ) {
                const hexagonTypes = Object.keys( hexagonColors );
                tags?.forEach( ( t ) => {
                    if ( hexagonTypes.includes( t ) ) {
                        hexagon = hexagonColors[ t ];
                    }
                } );
            }

            /* Add GA tags to link */
            let link = item.getAttribute( 'url' )?.[ 0 ] ?? item.url;
            const URLObject = new URL( window.location.origin + link );

            // Add PreferenceCity so the redirect tries to stay in the city of your Planning
            URLObject.searchParams.append( 'preference-city', preferenceCity );

            link = URLObject.pathname + URLObject.search;
            return new Deal( {
                id             : item.itemno,
                company        : item.brand,
                city           : item.getAttribute( 'city' )?.[ 0 ],
                prices         : new Prices(item.prices),
                hexagon,
                imageUrl       : item.image,
                link           : link,
                title          : item.title,
                review         : item.getAttribute( 'review' )?.[ 0 ],
                soldLabel      : item.getAttribute( 'sold_label' )?.[ 0 ],
                rank           : item.getAttribute( 'rank' )?.[ 0 ],
                dealUnique     : item.getAttribute( 'deal_unique' )?.[ 0 ],
                location       : item.getAttribute( 'city' )?.[ 0 ],
                discount       : item.getAttribute( 'discount' )?.[ 0 ],
                campaignUnique : item.getAttribute( 'campaign_unique' )?.[ 0 ],
                tags           : item.getAttribute( 'tags' ) as Array<string>,
            } )
        } )
    }

    public static createFromNearbyFilterResponse( response: INearbyFilterResponse ): Array<Deal> {
        return response.deals.map( ( props ) => {
            const deal = props[0];
            const tags = deal.tags;
            let formattedTagArray:Array<string> = [];
            let hexagon = null;
            const hexagonTypes = Object.keys( hexagonColors );
            tags?.forEach( t => {
                formattedTagArray.push(t.name);
                if ( hexagonTypes.includes( t.name ) ) {
                    hexagon = hexagonColors[ t.name ];
                }
            } );
            return new Deal( {
                id             : deal.unique,
                prices         : new Prices(deal.prices),
                dealUnique     : deal.unique,
                tags           : formattedTagArray,
                city           : deal.location,
                company        : deal.company_name,
                title          : deal.title,
                hexagon,
                imageUrl       : deal.image,
                link           : `/deals/${deal.city_slug}/${deal.company_slug}/${deal.deal_slug}/`,
                campaignUnique : deal.campaign_unique,
                soldLabel      : deal.sold,
                discount       : deal.discount ? `${deal.discount}%` : null,
                rank           : deal.travel_distance?.distance.toString(),
                review         : deal.review_stats?.average.toString(),
                location       : deal.location,
                travelDistance : deal.travel_distance
            } );
        } );
    }

    public static createFromPlanningResponse( response: IPlanningResponse ): Array<Deal> {
        return response.deals.map( ( props ) => {
            const tags = (props.tags ?? ``).split( ',' );

            let hexagon = props?.diamond ?? null;
            if( typeof props?.diamond === 'undefined' && props.prices?.price?.amount > 0 ) {
                const hexagonTypes = Object.keys(hexagonColors);
                tags?.forEach(t => {
                    if (hexagonTypes.includes(t)) {
                        hexagon = hexagonColors[t];
                    }
                });
            }
            if( !tags.includes('is_for_sale') && !tags.includes('sold_out') ) {
                tags.push( 'sold_out');
            }

            return new Deal( {
                id             : props.unique,
                prices         : new Prices(props.prices),
                dealUnique     : props.unique,
                tags,
                city           : props.city?.name,
                company        : props.company?.name,
                title          : props.title.label,
                hexagon,
                imageUrl       : props.image,
                link           : `/deals/${props.city.slug}/${props.company.slug}/${props.title.slug}/`,
                campaignUnique : props.campaign_unique,
                soldLabel      : props.sold_label,
                discount       : props.prices.discount_label,
                rank           : props.sort_by_attribute.distance.toString(),
                review         : props.review_label,
                location       : props.city.name,
            } );
        } );
    }
}
