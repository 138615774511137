


































































import Vue from "vue";
import TweakwiseSearchModal from "./TweakwiseSearchModal.vue";
import TweakwiseSearchBar from "./TweakwiseSearchBar.vue";
import { triggerEvent } from "../../../../frontend/helper/customEvents";
import { Deal } from "./domain/Deal";
import { ISearchPredictionResponse, SearchPredictionSuggestion, SearchService } from "../../../../frontend/services/SearchService";
import { SearchHistoryItem } from "./domain/SearchHistoryItem";
import { Experience } from "./domain/Experience";
import { TagCloud } from "./domain/TagCloud";
import { TagCloudCategory } from "./domain/TagCloudCategory";
import { ISearchHistoryDeeplinkDTO, SearchHistoryDTO } from "./domain/dto/SearchHistoryDTO";
import DeeplinkService from "../../../../frontend/services/DeeplinkService";
import FavoriteService from "../../../../frontend/services/FavoriteService";
import { getUrlQueryParam, hasUrlQueryParam, removeUrlQueryParam, setUrlQueryParam } from "../../../../frontend/helper/urlParams";
import { SearchProduct } from "../../../../frontend/services/search/SearchProduct";
import { sdDevice } from "../../../../frontend/helper/device";
import { SearchDealsConfiguration } from "./domain/SearchDealsConfiguration";
import { PopularPhraseItem } from "./domain/PopularPhraseItem";
import { PopularPhrase } from "./domain/PopularPhrase";
import { Experiences } from "./domain/Experiences";
import { SearchHistory } from "./domain/SearchHistory";

export enum SearchState {
  INITIAL    = "initial",
  SEARCHING  = "searching",
  RESULTS    = "results",
  NO_RESULTS = "no_results",
}

type Data = {
  query: string;
  submitQuery: string | null;
  placeholder: string;
  overridePlaceholder: string | null;
  autoCompleteState: SearchState;
  searchState: SearchState;
  deals: Array<Deal>;
  autoComplete: Array<SearchPredictionSuggestion>;
  autoCompleteDeals: Array<Deal>;
  searchHistory: SearchHistory;
  experiences: Experiences | null;
  popularPhrase: PopularPhrase | null;
  showModal: boolean;
  tagCloudCategory: TagCloudCategory | null;
  latestProductsResponse: ISearchPredictionResponse | null;
  isLoading: boolean;
  inputValue: string;
  GA: any;
  qUrlParamTimeout: any;
  searchDealsConfiguration: SearchDealsConfiguration;
}

export default Vue.extend( {
  components : {
    TweakwiseSearchModal,
    TweakwiseSearchBar
  },
  props      : {
    initialDeeplinkResponse: {
      type: String,
      required: true
    },
    tagCloudData : {
      type     : Object,
      default : null
    },
    dealUnique   : {
      type    : String,
      default : null
    },
    cityUnique   : {
      type     : String,
      required : true
    },
    citySlug     : {
      type     : String,
      required : true
    },
    isDealDetail : {
      type    : Boolean,
      default : false
    }
  },
  data       : (): Data => ({
    isLoading                : false,
    query                    : '',
    submitQuery              : null,
    placeholder              : '',
    overridePlaceholder      : null,
    inputValue               : null,
    autoCompleteState        : SearchState.INITIAL,
    searchState              : SearchState.INITIAL,
    deals                    : [],
    autoComplete             : [],
    autoCompleteDeals        : [],
    searchHistory            : null,
    experiences              : null,
    popularPhrase            : null,
    showModal                : false,
    tagCloudCategory         : null,
    latestProductsResponse   : null,
    GA                       : null,
    qUrlParamTimeout         : null,
    searchDealsConfiguration : null
  }),
  async mounted() {
    window.addEventListener( 'tweakwiseCategoryChange', this.handleCategoryChange );
    window.addEventListener( 'tweakwise.modal.open', this.handleTweakwiseModalOpen );
    window.addEventListener( 'popstate', this.handlePopState );

    if ( this.isDealDetail ) {
      const options = await SearchService.getOptions( 'deal-details', this.dealUnique );
      this.searchDealsConfiguration = SearchDealsConfiguration.create( options );
    } else {
      const deeplinkResponse = await DeeplinkService.getDeeplink( window.location.href, JSON.parse( atob( this.initialDeeplinkResponse ) ) );
      this.query = deeplinkResponse.params?.query ?? deeplinkResponse.params?.q ?? ``;
      this.tagCloudCategory = this.tagCloud.getCategory( deeplinkResponse.params?.tag ?? deeplinkResponse.params?.category ?? 'popular' );
      this.searchDealsConfiguration = this.tagCloudCategory.searchDealsConfiguration;

      /** Start from deeplink, when deeplink query is valid fetch search results and history is saved **/
      if ( this.query.length > 0 && this.isDealDetail === false ) {
        const historyDto: ISearchHistoryDeeplinkDTO = {
          cityUnique   : this.cityUnique,
          experienceId : deeplinkResponse.params.experience_id,
          tag          : deeplinkResponse.params[ 'tag-cloud' ] ?? this.tagCloudCategory?.name
        };

        if ( deeplinkResponse.params.query ) {
          historyDto.query = deeplinkResponse.params.query;
        }

        const searchHistory = SearchHistoryDTO.createForDeeplink( historyDto );
        this.submit( searchHistory );
      }
    }

    if ( this.searchDealsConfiguration.active ) {
      const searchButton = document.getElementById('search_button');
      if( searchButton ) {
        searchButton.classList.remove('hidden');
      }
    }

    this.placeholder = this.searchDealsConfiguration?.input?.placeholder ?? ``;
    this.initSearchByQueryParam();

    /** Open Search Modal when on mobile **/
    if ( sdDevice.isMobile() && hasUrlQueryParam( 'q' ) && hasUrlQueryParam( 'modal' ) ) {
      this.openModal();
    }

    this.fetchSearchHistoryAndExperiences();
    this.fetchFavoritesCampaignUniques();
  },
  beforeDestroy() {
    window.removeEventListener( 'tweakwiseCategoryChange', this.handleCategoryChange );
    window.removeEventListener( 'tweakwise.modal.open', this.handleTweakwiseModalOpen );
    window.removeEventListener( 'popstate', this.handlePopState );
  },
  methods  : {
    initSearchByQueryParam() {
      if ( hasUrlQueryParam( 'q' ) ) {
        const q = getUrlQueryParam( 'q' );
        this.query = q;

        if ( sdDevice.isMobile() || this.isDealDetail ) {
          this.fetchAutocomplete( q );
        }
      }
    },
    handlePopState(): void {
      const url = new URL( window.location.href );
      if ( url.searchParams.has('q') ) {
        this.query = url.searchParams.get( 'q' );
      }

      if ( url.searchParams.has('modal') ) {
        this.openModal();
      } else {
        this.closeModal();
      }
    },
    openModal() {
      this.fetchSearchHistoryAndExperiences();
      this.fetchFavoritesCampaignUniques();
      this.setShowModal( true );
      triggerEvent( 'searchBar-closeMobileTabs', null );
      document.body.classList.add( 'open' );
    },
    closeModal() {
      this.setShowModal( false );

      if ( !hasUrlQueryParam( 'q' ) ) {
        this.showPlanning();
      }

      triggerEvent( 'searchBar-closeMobileTabs', null );
      document.body.classList.remove( 'open' );
    },
    async submit( searchHistory?: SearchHistoryDTO ) {
      if ( sdDevice.isMobile() && this.isDealDetail ) {
        window.location.href = `/?q=${this.submitQuery ?? this.query}`;
        return;
      }

      await this.$nextTick();
      if( typeof page !== 'undefined' ) {
        page.scrollTop(0);
      }

      await this.fetchProducts();

      if ( searchHistory ) {
        if (this.deals.length > 0) {
          const deal = this.deals[0];
          searchHistory.setFirstDeal({
            distance: deal.rank,
            unique: deal.dealUnique
          })
        }

        if (searchHistory) {
          this.overridePlaceholder = this.query;
          await this.saveHistory(searchHistory);
        }
      }
    },
    handleCategoryChange( evt: any ): void {
      this.tagCloudCategory = this.tagCloud.getCategory( evt.detail.category ?? `popular` );
      if ( this.tagCloudCategory ) {
        this.placeholder = this.tagCloudCategory.searchDealsConfiguration.input.placeholder;
        this.searchDealsConfiguration = this.tagCloudCategory.searchDealsConfiguration;
      }
      this.toggleTweakwiseElement( this.searchDealsConfiguration != null ? this.searchDealsConfiguration.active : false );
      this.fetchAutocomplete( this.query );

      if ( this.query.length > 0 || (this.submitQuery ?? ``).length > 0 ) {
        this.submit();
      }
    },
    handleQueryChange( query: string ): void {
      this.submitQuery = null;
      this.query = query;

      if ( query.length < 3 ) {
        this.autoComplete = [];
        this.autoCompleteState = SearchState.INITIAL;

        this.showPlanning();
        this.showFilterButton();
      } else {
        this.hideFilterButton();
      }
    },
    async handleSearchHistoryItemClick( search: SearchHistoryItem ): Promise<void> {
      this.query = search.query;
      const searchHistory = SearchHistoryDTO.createForManualSearch( {
        cityUnique : this.cityUnique,
        query      : search.query,
        tag        : search.tag ?? this.tagCloudCategory?.name ?? undefined
      } );

      this.inputValue = null;
      this.setQueryParam( search.query );
      await this.submit( searchHistory );
    },
    async handleExperienceButtonClick( experience: Experience ): Promise<void> {
      this.isLoading = true;
      const searchHistory = SearchHistoryDTO.createForExperience( {
        experienceId : experience.params.experience_id,
        query        : experience?.query,
        tag          : experience.params?.tag ?? this.tagCloudCategory?.name ?? undefined,
        cityUnique   : experience?.city_unique ?? this.cityUnique
      } );

      const target = experience.params.target;
      if ( target === "_blank" ) {
        window.open( experience.url, target );

        await this.saveHistory( searchHistory );
        this.isLoading = false;
        return;
      }


      if ( experience.params.experience ) {
        this.inputValue = experience.params.experience;

        if ( experience.query ) {
          this.handleQueryChange( experience.query );
          this.setQueryParam( experience.query );
        }
      } else if ( experience.query ) {
        this.handleQueryChange( experience.query );
        this.setQueryParam( experience.query );
      } else if ( experience.url ) {
        await this.saveHistory( searchHistory );
        window.open( experience.url, target );
        return;
      }

      this.closeModal();

      this.isLoading = false;
      await this.submit( searchHistory );
    },
    async handleAutocompleteClick( suggestion: SearchPredictionSuggestion ): Promise<void> {
      this.inputValue = null;

      this.handleQueryChange( suggestion.match );
      this.setQueryParam( suggestion.match );

      this.submit(
          SearchHistoryDTO.createForManualSearch( {
            query      : suggestion.match,
            cityUnique : this.cityUnique,
            tag        : this.tagCloudCategory?.name ?? undefined
          } )
      );
    },
    async handlePopularPhraseItemClick( popularPhraseItem: PopularPhraseItem ): Promise<void> {
      this.inputValue = null;

      this.handleQueryChange( popularPhraseItem.query );
      this.setQueryParam( popularPhraseItem.query );

      this.submit(
          SearchHistoryDTO.createForManualSearch( {
            query      : popularPhraseItem.query,
            cityUnique : this.cityUnique,
            tag        : this.tagCloudCategory?.name ?? undefined
          } )
      );
    },
    async fetchHistory(): Promise<void> {
      const response = await SearchService.getHistory();
      this.searchHistory = SearchHistory.createFromApi( response );
    },
    async  fetchExperiences(): Promise<void> {
      const experiencesForTag = this.tagCloudCategory == null || this.tagCloudCategory.name === 'favorite' ? 'popular' : this.tagCloudCategory.name;
      const response = await SearchService.getExperiences( this.cityUnique, experiencesForTag );
      this.experiences = Experiences.createFromApi( response );
    },
    async fetchPopularPhrases(): Promise<void> {
      const response = await SearchService.getPopularPhrases( this.cityUnique );
      this.popularPhrase = PopularPhrase.createFromApi( response );
    },
    async fetchSearchHistoryAndExperiences(): Promise<void> {
      await Promise.all( [
        this.fetchHistory(),
        this.fetchExperiences(),
        this.fetchPopularPhrases()
      ] );
    },
    async fetchFavoritesCampaignUniques(): Promise<void> {
      const favoriteCampaignUniques = await FavoriteService.getFavoriteCampaignUniques();
      this.$store.commit( 'setFavoriteUniques', favoriteCampaignUniques );
    },
    async fetchAutocomplete( query: string ): Promise<void> {
      if ( query.length === 0 ) {
        this.autoCompleteDeals = [];
        this.autoCompleteState = SearchState.INITIAL;
        return;
      }

      const searchPredictions =
                sdDevice.isDesktop() ?
                await SearchService.getPredictionsAndProducts( query, this.searchDealsConfiguration.tweakwise.popular_cid, this.searchDealsConfiguration.tweakwise.cid ) :
                await SearchService.getPredictions( query, this.searchDealsConfiguration.tweakwise.popular_cid, this.searchDealsConfiguration.tweakwise.cid );
      const products = searchPredictions.products.map( ( p: any ) => new SearchProduct( p ) );

      this.autoComplete = searchPredictions.suggestions;
      const autocompleteDeals = Deal.createFromSearchPredictionsResponse( products, this.citySlug );
      this.autoCompleteState = autocompleteDeals.length > 0 ? SearchState.RESULTS : SearchState.NO_RESULTS;
      this.autoCompleteDeals = autocompleteDeals;

      if ( sdDevice.isDesktop() ) {
        this.latestProductsResponse = searchPredictions;

        this.deals = [];
        if ( this.autoCompleteDeals.length === 0 ) {
          this.searchState = SearchState.NO_RESULTS;
        } else {
          this.searchState = SearchState.INITIAL;
        }
      }

      if ( this.autoCompleteState === SearchState.NO_RESULTS || this.query.length < 3 ) {
        this.showPlanning();
      } else {
        this.hidePlanning();
      }

      if ( this.searchModalRef ) {
        this.searchModalRef.scrollTop();
      }
    },
    handleFetchMore(): void {
      if (
          this.searchState !== SearchState.SEARCHING &&
          this.latestProductsResponse != null &&
          this.latestProductsResponse.current_page < this.latestProductsResponse.num_pages
      ) {
        this.fetchProducts( true );
      }
    },
    fetchMoreAutocomplete(): void {
      if (
          this.searchState !== SearchState.SEARCHING &&
          this.latestProductsResponse != null &&
          this.latestProductsResponse.current_page < this.latestProductsResponse.num_pages
      ) {
        this.fetchProducts( true, true );
      }
    },
    handleSearchBarFocus( e : Event ): void {
      if ( sdDevice.isMobile() ) {
        e.preventDefault();

        this.searchBarRef.blur();
        return;
      }
      this.fetchSearchHistoryAndExperiences();
      this.fetchFavoritesCampaignUniques();

      const query = this.submitQuery ?? this.query;
      if ( query.length > 2 ) {
        this.fetchAutocomplete( query );
      }

      this.inputValue = null;
      this.$emit('on-focus');
    },
    handleBlur() {
      if(this.query.length === 0) {
        this.$emit('clear');
        this.handleQueryChange( '');
      }
      this.$emit('on-blur');
    },
    async fetchProducts( append: boolean = false, autoComplete: boolean = false ): Promise<void> {
      const query = this.submitQuery ?? this.query;
      if ( query.length === 0 ) {
        this.deals = [];
        this.autoCompleteDeals = [];
        this.setSearchState( SearchState.INITIAL );
        this.latestProductsResponse = null;
        return;
      }

      this.setSearchState( SearchState.SEARCHING );
      let page = 1;
      if (
          append &&
          this.latestProductsResponse != null &&
          this.latestProductsResponse.current_page < this.latestProductsResponse.num_pages
      ) {
        page = this.latestProductsResponse.current_page + 1;
      }

      const productsResponse = await SearchService.getProducts( query, this.searchDealsConfiguration.tweakwise.popular_cid, this.searchDealsConfiguration.tweakwise.cid, page )
      const products = productsResponse.products.map( ( p: any ) => new SearchProduct( p ) );
      const deals = Deal.createFromSearchPredictionsResponse( products, this.citySlug );

      if ( sdDevice.isDesktop() && autoComplete === false ) {
        this.autoCompleteDeals = [];
        this.autoCompleteState = SearchState.INITIAL;
      }

      if ( append ) {
        if ( this.autoComplete ) {
          this.autoCompleteDeals.push( ...deals );
        } else {
          this.deals.push( ...deals )
        }
      } else {
        if ( productsResponse.action?.do === "navigate" && productsResponse.action?.params?.relative ) {
          window.location.href = productsResponse.action.params.relative;
          return;
        }
       this.deals = deals;
      }

      const searchState = deals.length > 0 ? SearchState.RESULTS : SearchState.NO_RESULTS;
      if ( searchState === SearchState.NO_RESULTS || this.query.length < 3 ) {
        this.showPlanning();
      } else {
        this.hidePlanning();
      }

      this.latestProductsResponse = productsResponse;
      this.setSearchState( searchState );
    },
    async saveHistory( searchHistory: SearchHistoryDTO ): Promise<void> {
      await SearchService.saveSearchAction( searchHistory );
    },
    setSearchState( searchState: SearchState ) {
      this.searchState = searchState;
      if ( searchState === SearchState.INITIAL ) {
        this.showPlanning();
      }
      if( this.query.length > 3) {
        this.hideFilterButton();
      }
    },
    showPlanning() {
      this.$emit('show-planning')
    },
    hidePlanning() {
      this.$emit('hide-planning')
    },
    hideFilterButton() {
      this.$emit('hide-filter-button');
    },
    showFilterButton() {
      this.$emit('show-filter-button');
    },
    toggleTweakwiseElement( visible: boolean ) {
      if ( visible ) {
        this.tweakwiseElement.classList.add( 'tweakwise-container' );
      } else {
        this.tweakwiseElement.classList.remove( 'tweakwise-container' );
      }
    },
    handleClear() {
      this.inputValue = null;
      this.overridePlaceholder = null;
      this.autoComplete = [];
      this.autoCompleteState = SearchState.INITIAL;

      triggerEvent( 'lastMinute-toggleFeatured', null );
      this.$emit('clear')
    },
    setShowModal( showModal: boolean ) {
      this.showModal = showModal;
    },
    getGAParams( query: string ): any {
      return Object.assign( this.searchDealsConfiguration.ga, {
        utm_term : query
      } );
    },
    setQueryParam( value: string ) {
      clearTimeout( this.qUrlParamTimeout );
      this.qUrlParamTimeout = setTimeout( () => {
        if ( value.length > 0 ) {
          setUrlQueryParam( 'q', value, true );
        } else {
          removeUrlQueryParam( 'q', true )
        }
      }, 500 );
    },
    handleTweakwiseModalOpen(): void {
      this.setShowModal( true );
    }
  },
  computed : {
    isActive(): boolean {
      return this.searchDealsConfiguration != null ? this.searchDealsConfiguration.active : false;
    },
    tagCloud(): TagCloud {
      return TagCloud.createFromTagCloudData( this.tagCloudData );
    },
    tweakwiseElement(): HTMLElement {
      return document.getElementById( 'tweakwise-wrapper' );
    },
    searchModalRef(): any | null {
      return this.$refs.searchModal as any | null;
    },
    searchBarRef(): any | null {
      return this.$refs.searchBar as any | null;
    },
    inputPlaceholder(): string {
      return this.overridePlaceholder ?? this.placeholder;
    },
    isMobile(): boolean {
      return sdDevice.isMobile()
    }
  }
} );
