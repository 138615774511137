var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "suggestion", on: { click: _vm.handleClick } },
    [
      _c("div", { staticClass: "sd2-icons tw-store-icon", class: _vm.icon }),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.searchLabel))]),
      _c("span", { staticClass: "highlight" }, [
        _vm._v(_vm._s(_vm.highlightLabel)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }