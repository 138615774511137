









































import BuyNowButton from "./BuyNowButton";

export default BuyNowButton.extend(
    {
      name : 'HotelsBuyNowButton',
    } );
