var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "list", staticClass: "dl-row", attrs: { id: "dl-row" } },
    [
      _vm._l(_vm.deals, function (deal, index) {
        return _vm.deals
          ? _c(
              "div",
              {
                key: index,
                staticClass: "col-xs-12 col-m-12 tw-deals",
                class:
                  _vm.firstPairDealsAsFeatured && index < 2
                    ? "col-sm-6"
                    : "col-sm-4",
              },
              [
                _c("DealCard", {
                  attrs: {
                    deal: deal,
                    "is-deal-detail": _vm.isDealDetail,
                    "load-strategy": index < 10 ? "eager" : "lazy",
                  },
                  on: { loadDeal: _vm.handleLoadDeal },
                }),
              ],
              1
            )
          : _vm._e()
      }),
      _vm._v(" "),
      _vm.isLoading ? _c("TweakwiseLoader") : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }