import { APIService } from "./APIService";
import NearbySearchBar from "../../module/SearchBar/view/vue/domain/NearbySearchBar";

export default class TagCloudFilterService extends APIService {
    public static getFiltersForLocation( searchBar: NearbySearchBar ): any {
        const location = searchBar.location && searchBar.unique ? { 'city-unique' : searchBar.unique } : { 'geo-point' : searchBar.latitude + ',' + searchBar.longitude};
        const params = {
            ...location,
            ...{
                'selected-view'       : searchBar.nearbyView,
                'selected-identifier' : searchBar.nearbyIdentifier,
            }
        }

        return this.get<any>( `/proxy/tag-cloud/nearby/`, {
            params,
            headers : {
                "accept" : "application/vnd.tags-api.v4+json",
            }
        } );
    }
}
