var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "details-arrangements-grid" },
    _vm._l(_vm.itemsToGrid, function (row, rowKey) {
      return _c(
        "div",
        { key: rowKey, staticClass: "arrangements-row" },
        _vm._l(row, function (arrangement, arrangementKey) {
          return _c("DealArrangement", {
            key: arrangementKey,
            attrs: {
              arrangement: arrangement,
              "is-active":
                _vm.current && _vm.current.unique === arrangement.unique,
              "is-display-type-title": _vm.isDisplayTypeTitle,
            },
            on: {
              setArrangement: function ($event) {
                return _vm.setArrangement(arrangement)
              },
            },
          })
        }),
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }