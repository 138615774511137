import {APIService} from "./APIService";
import {SearchHistoryDTO} from "../../module/Deals/view/vue/domain/dto/SearchHistoryDTO";
import { SearchProductAttribute } from "./search/SearchProduct";

export interface ISearchResponse {
    search_history: Array<{
        action?: string;
        icon?: string;
        match?: string;
        params?: {
            navigation_id: number;
        };
        url?: {
            absolute: string;
            relative: string;
        };
        city_unique?: string;
        input_value?: string;
        query?: string;
        tag?: string;
        target?: string;
    }>;
    experiences: Array<{
        label: string;
        unicode_icon: string;
        target: string;
        url: string;
        query?: string;
        city_unique?: string;
        params: {
            experience?: string;
            experience_id?: number;
            tag?: string;
            target?: string;
        }
    }>;
}

export interface ISearchPredictionResponse {
    current_page: number;
    num_pages: number;
    num_products: number;
    num_suggestions: number;
    num_total_products: number;
    popular_cid: string;
    preference_cid: string;
    products: Array<SearchProductItem>;
    query: string;
    suggestions: Array<SearchPredictionSuggestion>;
}

export type SearchProductItem = {
    attributes: Array<SearchProductAttribute>
    brand: string;
    image: string;
    itemno: string;
    price: number;
    title: string;
    url: string;
}

export type SearchPredictionSuggestion = {
    match: string;
    icon: string;
    action: string;
    target?: string;
    url?: {
        absolute: string;
        relative: string;
    },
    params?: {
        navigate_id: number;
    }
};

export class SearchService extends APIService {

    private static defaultHeaders = {
        "Content-Type": "application/json"
    };

    public static async getHistory(): Promise<any> {
        return await this.get<any>(`/proxy/search/history/`, {
            headers: this.defaultHeaders
        });
    }

    public static async getExperiences( cityUnique: string, tag: string ): Promise<any> {
        return await this.get<any>(`/proxy/search/experiences/${cityUnique}/${tag}/`, {
            headers: this.defaultHeaders
        });
    }

    public static async saveSearchAction( data: SearchHistoryDTO ): Promise<void> {
        return await this.post<FormData, any>('/proxy/search/action/', data.toJson(), {
            headers: this.defaultHeaders
        } );
    }

    public static async getPredictions( query: string, popularCid: string, preferenceCid?: string, page?: number ): Promise<any> {
        return this.get('/proxy/search/predictions/', {
            headers: this.defaultHeaders,
            params: {
                popular_cid: popularCid,
                preference_cid: preferenceCid,
                query,
                page
            }
        });
    }

    public static async getProducts( query: string, popularCid: string, preferenceCid?: string, page?: number ) : Promise<any> {
        return this.get('/proxy/search/products/', {
            headers: this.defaultHeaders,
            params : {
                popular_cid: popularCid,
                preference_cid: preferenceCid,
                query,
                page
            }
        })
    }

    public static async getOptions( type: string, unique: string ) : Promise<any> {
        return this.get(`/proxy/search/options/${type}/`, {
            params: {
                unique
            }
        })
    }

    public static async getPredictionsAndProducts( query: string, popularCid: string, preferenceCid?: string, page?: number ): Promise<any> {
        return this.get('/proxy/search/predictions-and-products/',{
            headers: this.defaultHeaders,
            params: {
                popular_cid: popularCid,
                preference_cid: preferenceCid,
                query,
                page
            }
        })
    }

    public static async getPopularPhrases( cityUnique: string ): Promise<any> {
        return this.get(`/proxy/search/popular-phrases/${cityUnique}/`, {
            headers: this.defaultHeaders
        })
    }
}
