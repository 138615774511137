var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "message" }, [
    _vm.imageSrc
      ? _c("img", {
          staticClass: "message-img img-responsive",
          attrs: { src: _vm.imageSrc },
        })
      : _vm._e(),
    _vm._v(" "),
    !_vm.imageSrc && _vm.icon
      ? _c("div", { staticClass: "sd2-icons", class: _vm.icon })
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "message-text" }, [
      _vm._v("\n    " + _vm._s(_vm.label) + "\n  "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }