import { APIService } from "./APIService";
// @ts-ignore
import { sdDevice } from "../helper/device";
import SDCalendarInstance from "../../module/Availability/submodule/Calendar/view/vue/domain/SDCalendarInstance";

export const CalendarFlows = {
    buy_with_availability_with_price               : 'buy-with-availability-with-price',
    hotel_search_bar_date_range_calendar           : 'hotel-search-bar-date-range-calendar',
    date_range_buy_with_availability_hotel_details : 'date-range-buy-with-availability-hotel-details',
    lmd_nearby                                     : 'lmd-nearby-search-bar-calendar',
    restaurants                                    : 'search-bar-restaurants',
}

export default class CalendarService extends APIService {

    public static async getSearchBarCalendar( flow: string, amount: number, cityUnique: string, params: any ): Promise<SDCalendarInstance> {
        if ( !sdDevice.isMobile() ) {
            params.desktop = true;
        }

        const response = await this.get<any>( `/proxy/V3/calendar/${flow}/amount/${amount}/city/${cityUnique}/`, {
            params  : params,
            headers : this.getHeaders( {
                'x-provider' : 'availability',
                'X-Origin' : 'socialdeal'
            } )
        }, );

        return SDCalendarInstance.fromApi( response )
    }

    public static getCalendarByFlow( flow: string, amount: any, cityUnique: string, params: any ): any {
        if ( !sdDevice.isMobile() ) {
            params.website = true;
        }
        return this.get<any>( `/proxy/V3/calendar/${flow}/amount/${amount}/city/${cityUnique}/`, {
            params  : params,
            headers : this.getHeaders( {
                'x-provider' : 'availability',
                'X-Origin' : 'socialdeal'
            } )
        }, );
    }

    public static getCalendarByFlowAndUnique( flow: string, unique: string, params: any, amount: any ): any {
        if ( !sdDevice.isMobile() ) {
            params.website = true;
        }
        return this.get<any>( `/proxy/V3/calendar/${flow}/${unique}/amount/${amount}/`, {
            params  : params,
            headers : this.getHeaders( {
                'x-provider' : 'availability',
                'X-Origin' : 'socialdeal'
            } )
        }, );
    }
}
