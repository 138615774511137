




















import Vue, { PropType } from "vue";
import DealCard from "../../components/DealCard.vue";
import { Deal } from "../../domain/Deal";
import Loader from "../../../../../../frontend/components/Loader.vue";
import TweakwiseLoader from "../TweakwiseLoader.vue";

export default Vue.extend( {
  name       : 'DealList',
  components : {
    TweakwiseLoader,
    Loader,
    DealCard
  },
  props      : {
    deals : {
      type     : Array as PropType<Array<Deal>>,
      default: []
    },
    isDealDetail: {
      type    : Boolean,
      default : false
    },
    firstPairDealsAsFeatured: {
      type    : Boolean,
      default : false
    }
  },
  data       : () => ({
    isLoading : false
  }),
  mounted() {
    window.addEventListener( 'scroll', this.handleWindowScroll )
  },
  beforeDestroy() {
    window.removeEventListener( 'scroll', this.handleWindowScroll );
  },
  methods : {
    handleWindowScroll( _: any ) {
      const listRef = this.$refs.list as HTMLDivElement;
      const listHeight = listRef.clientHeight;
      const triggerHeight = listHeight - (window.innerHeight * 2);

      if (
          window.scrollY < listHeight &&
          window.scrollY > triggerHeight
      ) {
        this.$emit( 'fetchMore' );
      }
    },
    handleLoadDeal(): void {
      this.isLoading = true;

      this.$emit('loading');
    }
  }
} )
