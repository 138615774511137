var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex justify-center items-center text-gray-700" },
    [
      _c("div", { staticClass: "flex items-center" }, [
        _c("div", {
          staticClass: "mr-2 line-through",
          domProps: { innerHTML: _vm._s(_vm.fromPrice) },
        }),
        _vm._v(" "),
        _c("div", {
          staticClass: "font-semibold text-green-500",
          domProps: { innerHTML: _vm._s(_vm.price) },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }