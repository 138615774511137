























































import Vue from "vue";
import NearbyModal from "./NearbyModal.vue";
import LmdModal from "./LmdModal.vue";
import HotelsModal from "./HotelsModal.vue";
import SearchBarInfo from "./components/SearchBarInfo.vue"
import { triggerEvent } from "../../../../frontend/helper/customEvents";
import SearchBarService from "../../../../frontend/services/SearchBarService";
import HotelsSearchbar from "./domain/HotelsSearchbar";
import NearbySearchBar from "./domain/NearbySearchBar";
import LmdSearchbar from "./domain/LmdSearchbar";
import Loader from "../../../../frontend/components/Loader.vue";
import ClickEvent = JQuery.ClickEvent;
import {ICity} from "./domain/City";
import {ITag} from "./components/TagSelect.vue";

const searchBarTypes = {
  NEARBY : 'nearby',
  LMD    : 'lmd-nearby',
  HOTELS : 'hotels'
}

export default Vue.extend( {
  name       : 'SearchBar',
  components : {
    Loader,
    SearchBarInfo,
    NearbyModal,
    LmdModal,
    HotelsModal
  },
  data() {
    return {
      labels      : {
        title       : null,
        description : null
      },
      searchBar   : null as any,
      activeModal : '',
      fixed       : false,
      loading     : false,
      badge       : {color: '', label: ''},
      selectedCategories: []
    }
  },
  props : {
    searchBarType : {
      type     : String,
      required : true
    },
    cityUnique    : {
      type     : String,
      required : true
    },
    deprecatedCalendar: {
      type: Boolean,
      default: false
    }
  },
  async mounted(): Promise<any> {
    const searchBarResponse = await SearchBarService.getSearchBarByType( this.searchBarType, this.cityUnique );
    const searchBarData = {
      ...searchBarResponse,
      ...{ 'city_unique' : this.cityUnique }
    };
    this.setSearchBar( searchBarData );

    window.addEventListener( 'scroll', this.handleScrollState );
    window.addEventListener( 'doGps', this.handleGpsFromJquery );
    window.addEventListener( 'openCitySelect', this.openCitySelectFromJquery );
    window.addEventListener( 'sync-nearby-filter', this.syncNearbyFilter );
    window.addEventListener( 'nearby-filter-categories-changed', this.syncNearbyFilterCategories );
    window.addEventListener("filter-badge-updated", (event: CustomEventInit) => {
        this.badge = event.detail ? {color: event.detail.color, label: event.detail.label} : null;
    });
    this.handleScrollState();
  },
  beforeDestroy(): void {
    window.removeEventListener( 'scroll', this.handleScrollState );
    window.removeEventListener( 'doGps', this.handleGpsFromJquery );
    window.removeEventListener( 'openCitySelect', this.openCitySelectFromJquery );
    window.removeEventListener( 'sync-nearby-filter', this.syncNearbyFilter );
    window.addEventListener( 'nearby-filter-categories-changed', this.syncNearbyFilterCategories );
  },
  methods : {
    setSearchBar( searchBarResponse: any ) {
      const SearchBars = {
        [ searchBarTypes.NEARBY ] : NearbySearchBar,
        [ searchBarTypes.LMD ]    : LmdSearchbar,
        [ searchBarTypes.HOTELS ] : HotelsSearchbar,
      }
      const SearchBarInstance = SearchBars[ this.searchBarType ];

      if ( SearchBarInstance ) {
        this.searchBar = SearchBarInstance.fromApi( searchBarResponse );
        this.labels = {
          title       : this.searchBar.title,
          description : this.searchBar.description,
        }
      }
    },
    syncNearbyFilterCategories(e:any) {
      this.selectedCategories = e.detail.selectedCategories;
      const identifier = this.selectedCategories.length === 1 ? this.selectedCategories[0] : 'deal'
      this.searchBar.setNearbyIdentifier( {identifier: identifier} as ITag );


    },
    async syncNearbyFilter(e:any) {
      const selectedCity = e.detail.selectedCity;
      if(selectedCity) {
        this.searchBar.setLocation(selectedCity);
        const $searchableCityList = ($('#search-bar .searchableCityList') as any);

        if(selectedCity.location) {
          let  $city = $searchableCityList.find( `li.city[data-latitude="${ selectedCity.latitude }"][data-longitude="${ selectedCity.longitude }"][data-is-synonym="false"]:first` );
          if( $city.length === 0 ) {
            $city = $searchableCityList.find( '.search-location .trigger');
            $city.data( 'name', selectedCity.location );
            $city.data( 'latitude', selectedCity.latitude );
            $city.data( 'longitude', selectedCity.longitude );
          }

          $searchableCityList.extCitySelect( 'setPlaceholder', $city, null);
          $searchableCityList.extCitySelect( 'addToHistory', $city,null);
        } else {
          $searchableCityList.extCitySelect( 'updateHistoryAndClosestCityList', { location : 'gps', latitude : selectedCity.latitude, longitude : selectedCity.longitude } );
        }

      }

      await this.updateSearchBar(this.searchBar);
    },
    handleScrollState(): void {
      if ( window.pageYOffset > 50 && !this.fixed ) {
        this.fixed = true;
      }

      if ( window.pageYOffset < 50 ) {
        this.fixed = false;
      }
    },
    categoryChanged(selectedTags:string[]) {
      if(selectedTags) {
        this.selectedCategories = selectedTags;
      }
    },
    async updateSearchBar( searchBar: any ): Promise<any> {
      this.loading = true;
      const updateResponse = await SearchBarService.updateSearchBar( this.searchBarType, this.cityUnique, searchBar.toSearchBarDTO() );
      triggerEvent( 'searchBar-updateFromMobileSearchbar', updateResponse );
      const searchBarResponse = await SearchBarService.getSearchBarByType( this.searchBarType, this.cityUnique );
      this.setSearchBar( Object.assign( {}, searchBarResponse, { 'city_unique' : this.cityUnique } ) );

      this.closeModal();
      this.loading = false;
    },
    handleGpsFromJquery( e: any ): void {
      this.searchBar.setLocation( e.detail.searchBarData );
      this.labels = Object.assign( this.labels, e.detail.labels );
    },
    openCitySelectFromJquery( e: any ): void {
      this.openModal();
    },
    closeModal(): void {
      if($('.mobile-footer').hasClass('push-out')) {
        triggerEvent( 'searchBar-closeMobileTabs', null );
      }
      this.activeModal = '';
      document.body.classList.remove( 'open' );
    },
    openModal(): void {
      if ( document.querySelectorAll( '.modal-toggle > .alpha' ).length > 0 ) {
        return;
      }

      this.activeModal = this.searchBarType;
      triggerEvent( 'searchBar-closeMobileTabs', null );
      document.body.classList.add( 'open' );
    },
    handleOpenFilterClick(clickEvent:ClickEvent) {
      clickEvent.stopPropagation();
      const handleOpenFilterClickEvent = new CustomEvent("open-mobile-nearby-filter-click", { detail: clickEvent });
      window.dispatchEvent(handleOpenFilterClickEvent);
    }
  },
  computed : {
    showBadge():boolean {
      return !!this.badge;
    },
    showFilterButton():boolean {
      return this.$data.fixed && this.searchBarType === searchBarTypes.NEARBY && this.searchBar?.nearbyView === 'tag';
    }
  }
} );
