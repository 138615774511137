






















































































import Vue, { PropType } from "vue";
import SearchInput from "./components/SearchInput.vue";
import SearchHistoryList from "./components/lists/SearchHistoryList.vue";
import AutocompleteSuggestionList from "./components/lists/AutocompleteSuggetionList.vue";
import AutoCompleteDealList from "./components/lists/AutoCompleteDealList.vue";
import Message from "./components/Message.vue";
import ExperienceList from "./components/lists/ExperienceList.vue";
import { Deal } from "./domain/Deal";
import { SearchState } from "./Tweakwise.vue";
import { Experience } from "./domain/Experience";
import { SearchHistoryItem } from "./domain/SearchHistoryItem";
import { SearchHistoryDTO } from "./domain/dto/SearchHistoryDTO";
import { TagCloudCategory } from "./domain/TagCloudCategory";
import Loader from "../../../../frontend/components/Loader.vue";
import PlanningService from "../../../../frontend/services/PlanningService";
import DealList from "./components/lists/DealList.vue";
import TweakwiseLoader from "./components/TweakwiseLoader.vue";
import { SearchPredictionSuggestion } from "../../../../frontend/services/SearchService";
import { removeUrlQueryParam, removeUrlQueryParams, setUrlQueryParam } from "../../../../frontend/helper/urlParams";
import { IPopularPhrase } from "./domain/interfaces/IPopularPhrase";
import { PopularPhrase } from "./domain/PopularPhrase";
import PopularPhraseList from "./components/lists/PopularPhraseList.vue";
import { PopularPhraseItem } from "./domain/PopularPhraseItem";
import { Experiences } from "./domain/Experiences";
import { SearchHistory } from "./domain/SearchHistory";

type Data = {
  query: string;
  delayTimeout: any;
  isInputInvalid: boolean;
  planningDeals: Array<Deal>;
  previousContentScrollTop: number;
  isLoadingDeal: boolean;
}

export default Vue.extend( {
  name       : 'TweakwiseSearchModal',
  components : {
    PopularPhraseList,
    TweakwiseLoader,
    DealList,
    Loader,
    ExperienceList,
    Message,
    SearchHistoryList,
    SearchInput,
    AutocompleteSuggestionList,
    AutoCompleteDealList
  },
  props      : {
    initialQuery      : {
      type    : String,
      default : ''
    },
    isLoading         : {
      type     : Boolean,
      required : true
    },
    cityUnique        : {
      type     : String,
      required : true
    },
    tagCloudCategory  : {
      type : Object as PropType<TagCloudCategory>
    },
    placeholder       : {
      type     : String,
      required : true
    },
    searchState       : {
      type     : String as PropType<SearchState>,
      required : true
    },
    autoComplete      : {
      type     : Array as PropType<Array<SearchPredictionSuggestion>>,
      required : true
    },
    autoCompleteDeals : {
      type    : Array as PropType<Array<Deal>>,
      default : null
    },
    searchHistory        : {
      type     : Object as PropType<SearchHistory>,
      default  : null
    },
    experiences   : {
      type     : Object as PropType<Experiences>,
      default  : null
    },
    popularPhrase     : {
      type     : Object as PropType<PopularPhrase>,
      default  : null
    }
  },
  data       : (): Data => ({
    query                    : '',
    delayTimeout             : null,
    isInputInvalid           : false,
    planningDeals            : [],
    previousContentScrollTop : 0,
    isLoadingDeal            : false
  }),
  mounted() {
    setUrlQueryParam( 'modal', 'search' );
    this.contentRef.addEventListener( 'scroll', this.handleContentScroll );
    this.fetchPlanning();
    this.query = this.initialQuery;
  },
  beforeDestroy() {
    this.contentRef.removeEventListener( 'scroll', this.handleContentScroll );
  },
  methods  : {
    async fetchPlanning(): Promise<void> {
      const response = await PlanningService.getPlanning( this.cityUnique );
      this.planningDeals = Deal.createFromPlanningResponse( response );
    },
    handleContentScroll( event: any ): void {
      const hideKeyboard = event.target.scrollTop > this.previousContentScrollTop;
      this.previousContentScrollTop = event.target.scrollTop;

      if ( hideKeyboard ) {
        this.searchBarRef.blur();
      }
    },
    handleSearchBarChange( event: any ): void {
      const value = event.target.value;
      this.query = value;

      this.$emit( 'setQueryParams', value );

      if ( this.query.length > 2 ) {
        this.isInputInvalid = false;

        if ( this.delayTimeout ) {
          clearTimeout( this.delayTimeout );
        }

        this.delayTimeout = setTimeout( () => {
          this.$emit( 'onFetchAutocomplete', this.query );
        }, 200 );
      }
    },
    handleSearchHistoryItemClick( search: SearchHistoryItem ): void {
      this.$emit( 'clickHistoryItem', search );
      this.closeSelf();
    },
    handleAutocompleteItemClick( suggestion: SearchPredictionSuggestion ): void {
      if ( suggestion.match && suggestion.match.length > 2 ) {
        this.isInputInvalid = false;
      }

      this.closeSelf();

      this.$emit( 'clickAutocompleteItem', suggestion );
      this.$emit( 'onFetchAutocomplete', suggestion.match );
    },
    handleSuggestionClick( suggestion: Experience ): void {
      this.$emit( 'clickExperienceItem', suggestion );
      if ( suggestion.query ) {
        this.$emit( 'onFetchAutocomplete', suggestion.query );
      }
    },
    handlePopularPhraseItemClick ( popularPhraseItem: PopularPhraseItem ): void {
      this.closeSelf();
      this.$emit('clickPopularPhraseItem', popularPhraseItem );
      this.$emit( 'onFetchAutocomplete', popularPhraseItem.query );
    },
    changeQuery( query: string ): void {
      if ( query && query.length > 2 ) {
        this.isInputInvalid = false;
      }

      this.$emit( 'onChangeQuery', query );
    },
    cancel(): void {
      if ( (document.referrer.indexOf( window.location.host ) !== -1) && history && history.length > 1 ) {
        window.history.back();
      } else {
        this.closeSelf();
      }
    },
    closeSelf(): void {
      removeUrlQueryParam( 'modal' );

      this.$emit( 'close' );
    },
    submit(): void {
      if ( this.query.length < 3 || (this.autocompleteNoResults && this.autoCompleteDeals.length === 0) ) {
        this.isInputInvalid = true;
        return;
      }

      if ( this.query && this.query.length > 2 ) {
        this.isInputInvalid = false;
      }

      this.$emit( 'onChangeQuery', this.query );
      this.closeSelf();

      this.$emit( 'submit', SearchHistoryDTO.createForManualSearch( {
        query      : this.query,
        cityUnique : this.cityUnique,
        tag        : this.tagCloudCategory?.name ?? undefined
      } ) );
    },
    scrollTop(): void {
      this.contentRef.scrollTo( { top : 0 } );
    },
    handleLoading() {
      this.isLoadingDeal = true;
    }
  },
  computed : {
    isSearchStateInitial(): boolean {
      return this.searchState === SearchState.INITIAL;
    },
    isSearchStateNoResults(): boolean {
      return this.searchState === SearchState.NO_RESULTS;
    },
    isSearchStateResults(): boolean {
      return this.searchState === SearchState.RESULTS;
    },
    isSearchStateSearching(): boolean {
      return this.searchState === SearchState.SEARCHING;
    },
    autocompleteNoResults(): boolean {
      return this.autoComplete.length === 0;
    },
    isEmptyState(): boolean {
      return this.query.length > 2 && this.isSearchStateNoResults && !this.isSearchStateSearching && this.autocompleteNoResults;
    },
    contentRef(): HTMLDivElement {
      return this.$refs.content as HTMLDivElement;
    },
    searchBarRef(): any {
      return this.$refs.searchbar as any;
    },
    hasSearchHistory(): boolean {
      return this.searchHistory.searchHistory.length > 0;
    }
  }
} )
