import { ISearchBar, SearchBar } from "./SearchBar";
import SDCalendarInstance from "../../../../Availability/submodule/Calendar/view/vue/domain/SDCalendarInstance";

export interface IHotelSearchbar extends ISearchBar {
    num_rooms: number,
    room_options: any[]
    from_date?: string,
    till_date?: string,
    date_shortcut?: string,
}

export default class HotelsSearchbar extends SearchBar<IHotelSearchbar> {

    get title() {
        return this._props.title;
    }

    get description() {
        return this._props.description;
    }

    get roomOptions(): any[] {
        return this._props.room_options
    }

    get roomValue(): any {
        return this._props.room_options.find( option => option.key === this._props.num_rooms )
    }

    get location(): string {
        return this._props.location
    }

    get latitude(): any {
        return this._props.latitude;
    }

    get longitude(): any {
        return this._props.longitude;
    }

    get numRooms(): any {
        return this._props.num_rooms < this.roomOptions.length ? this._props.num_rooms : this.roomOptions.length;
    }

    get fromDate(): any {
        return this._props.from_date;
    }

    get tillDate(): any {
        return this._props.till_date;
    }

    get dateShortcut(): string {
        return this._props.date_shortcut;
    }

    static fromApi( props: any ) {
        return new HotelsSearchbar( {
            ...props,
            ...props.search_bar
        } );
    }

    static fromCalendar( searchBar: HotelsSearchbar, calendar: SDCalendarInstance ) {
        return new HotelsSearchbar( Object.assign( searchBar.toJson(), {
            from_date     : calendar.selectedValue?.from ?? null,
            till_date     : calendar.selectedValue?.till ?? null,
            date_shortcut : calendar.activeShortcut ?? null,
        } ) );
    }

    get dates(): any {
        return {
            from_date : this._props.from_date,
            till_date : this._props.till_date,
        }
    }

    public toJson(): IHotelSearchbar {
        return {
            title        : this._props.title,
            description  : this._props.description,
            from_date    : this._props.from_date,
            till_date    : this._props.till_date,
            location     : this._props.location,
            num_rooms    : this._props.num_rooms,
            room_options : this._props.room_options,
            latitude     : this._props.latitude,
            longitude    : this._props.longitude,
            city_unique  : this._props.city_unique,
        }
    }

    public toSearchBarDTO(): any {
        return {
            latitude      : this.latitude,
            longitude     : this.longitude,
            location      : this.location,
            num_rooms     : this.numRooms,
            from_date     : this.fromDate,
            till_date     : this.tillDate,
            date_shortcut : this.dateShortcut,

        }
    }

    public toAvailabilityDTO(): any {
        return {
            amount        : this.numRooms,
            'range[from]' : this.fromDate,
            'range[till]' : this.tillDate,
        }
    }

    public setAmount( amountOptionKey: any ) {
        this._props.num_rooms = amountOptionKey;
    }

    public setLocation( cityDTO: any ) {
        Object.assign( this._props, cityDTO )
    }
}
