var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.searchHistory
    ? _c("div", { staticClass: "search-history-container" }, [
        _vm.searchHistory.title
          ? _c("div", { staticClass: "search-history-label" }, [
              _vm._v(_vm._s(_vm.searchHistory.title) + "Zoekgeschiedenis"),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "search-history-list" },
          _vm._l(_vm.searchHistory.searchHistory, function (search, index) {
            return _c("SearchHistoryItem", {
              attrs: { search: search },
              on: { click: _vm.handleClick },
            })
          }),
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }