


















































import Vue from "vue";
import DealPrice from '../deal-price.vue';
import {Arrangement} from "../domain/Arrangement";
import {ArrangementOption} from "../domain/ArrangementOption";

export default Vue.extend({
    name: 'DealArrangement',
    components: {
        DealPrice
    },
    props: {
        arrangement: {
            type: [ Arrangement as any, ArrangementOption as any ],
            required: true
        },
        isDisplayTypeTitle: {
            type: Boolean,
            default: false,
        },
        isActive: {
            type: Boolean,
            default: false,
        },
    }
});
