import Vue from "vue";
import { triggerEvent } from "../../../../../../frontend/helper/customEvents";

export default Vue.extend( {
    props : {
        isForSale : {
            type     : Boolean,
            required : true
        },
        summary   : {
            type    : String,
            default : undefined
        },
        text      : {
            type    : String,
            default : ''
        },
        caption   : {
            type    : String,
            default : null
        },
        icon      : {
            type     : String,
            required : true
        },
        prices    : {
            type    : Object,
            default : null
        }
    },
    mounted() {
        triggerEvent( 'availability-cloneBuyNowButton', {
            summary : this.summary
        } );
    },
    /* HACK ALERT, sync the buy now button everytime anything changes in this component. */
    watch    : {
        $props : {
            handler() {
                triggerEvent( 'availability-cloneBuyNowButton', {
                    summary : this.summary
                } );
            },
            deep      : true,
            immediate : true,
        }
    },
    computed : {
        priceString(): any {
            if ( !this.prices ) {
                return;
            }

            if( this.prices.price ) {
                return this.prices.price.toHtml( true );
            }

            if( this.prices.average ) {
                return this.prices.average.toHtml( true )
            }

            if( this.prices.lowest ) {
                return this.prices.lowest.toHtml( true )
            }

            return '';
        }
    }
} );
