


























import Vue, {PropType} from "vue";
import {ReviewFilterItem} from "../../types/FilterTypes";

interface IData {
  stars:number[],
  selectedReviewKey: number;
}

interface IMethods {
  getSelectedReviewItem: () => ReviewFilterItem;
  setActiveValue: (key:number) => void;
  handleReviewFilterItemClick: (reviewFilterItem: ReviewFilterItem) => void;
}

interface IProps {
  reviewItems:ReviewFilterItem[];
  initSelectedReviewKey: number
}
export default Vue.extend<IData, IMethods, {}, IProps>( {
  name  : 'ReviewFilter',
  props : {
    reviewItems : {
      type     : Array as PropType<IProps['reviewItems']>,
      required : true
    },
    initSelectedReviewKey : {
      type: Number,
      required : false
    }
  },
  data() {
    return {
      stars:[1,2,3,4,5],
      selectedReviewKey: this.initSelectedReviewKey ?? 0
    }
  },
  methods: {
    getSelectedReviewItem() {
      return this.reviewItems.find((reviewItem) => reviewItem.key === this.selectedReviewKey);
    },
    setActiveValue(value:number) {
      this.selectedReviewKey = value;
    },
    handleReviewFilterItemClick (reviewFilterItem: ReviewFilterItem) {
      this.setActiveValue(reviewFilterItem.key);
      this.$emit('review-filter-item-click', reviewFilterItem);
    }
  }
} );
