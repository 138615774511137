var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "search-bar-calendar-wrapper" } },
    [
      _c("div", { staticClass: "close-layer", on: { click: _vm.closeSelf } }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "no-preference",
          on: { click: _vm.handleNoPreferenceClick },
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("11414.App_HotelSearchDateButtonNoPreference")) +
              "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _vm.calendar && !!_vm.calendar.dateShortcuts
        ? _c("ButtonTabs", {
            attrs: {
              buttons: _vm.calendar.dateShortcuts,
              "active-unique": _vm.calendar.activeShortcut,
            },
            on: { buttonClick: _vm.handleShortcutClick },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.calendar && _vm.calendar.boxCheckinCheckout
        ? _c(
            "div",
            { staticClass: "range-placeholder-wrapper" },
            [
              _c("DateRangePlaceholder", {
                attrs: { box: _vm.calendar.boxCheckinCheckout },
                on: { click: _vm.handleDateRangePlaceholderClick },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.calendar
        ? _c("SDCalendar", {
            attrs: {
              calendar: _vm.calendar,
              type: "hotels",
              "no-preference-allowed": false,
            },
            on: {
              update: _vm.updateCalendar,
              loadMore: _vm.handleLoadMoreEvent,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }