import { APIService } from "./APIService";

export default class AvailabilityService extends APIService {

    public static getByUnique( flow: string, unique: string, params: any ): any {
        return this.get<any>( `/proxy/availability/${flow}/${unique}/`, {
            params  : params,
            headers : this.getHeaders(),
        } );
    }

    public static getAvailability( unique: string, params: any ): any {
        return this.get<any>( `/proxy/reservation/availability/${unique}/`, {
            params  : params,
            headers : this.getHeaders( {
                'X-Supports' : 'additional_people',
            } ),
        } );
    }

    public static getOptions( unique: string, params: any ): any {
        return this.get<any>( `/proxy/reservation/availability/for-deal/${unique}/`, {
            params  : params,
            headers : this.getHeaders( {
                'X-Supports' : 'additional_people',
            } ),
        } );
    }

    public static getTimeSlots( flow: string, unique: string, date: any, params: any ): any {
        return this.get<any>( `/proxy/time-slot/${flow}/${unique}/${date}/`, {
            params  : params,
            headers : this.getHeaders( {
                'X-Provider' : 'availability',
                'X-Origin'   : 'socialdeal'
            } ),
        } );
    }
}
