
















import Vue, {PropType} from "vue";
import SearchHistoryItem from "../items/SearchHistoryItem.vue";
import { SearchHistory } from "../../domain/SearchHistory";

export default Vue.extend({
  name: 'SearchHistoryList',
  components: {
    SearchHistoryItem
  },
  props: {
    searchHistory: {
      type: Object as PropType<SearchHistory>,
      default : null
    }
  },
  methods: {
    handleClick( search : any ): void {
      this.$emit('click', search );
    }
  }
})
