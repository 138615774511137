import { ITagCloudData } from "./interfaces/ITagCloudData";
import ValueObject from "../../../../../frontend/ValueObject";
import { TagCloudCategory } from "./TagCloudCategory";

export class TagCloud extends ValueObject<ITagCloudData>{

     public getCategory( category: string = 'popular' ): TagCloudCategory {
         const fallbackCategory = 'popular';
         if( !this._props[ category ] ) {
             category = fallbackCategory;
         }

         return TagCloudCategory.createFromCategoryData( {
             name                       : category,
             search_deals_configuration : this._props[ category ]
         } );
     }

     public static createFromTagCloudData( tagCloudData: ITagCloudData ): TagCloud {
          return new TagCloud( tagCloudData );
     }
}
