var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        _vm.getArrangementView,
        _vm._b(
          {
            tag: "component",
            on: {
              select: function ($event) {
                return _vm.$emit("select", $event)
              },
            },
          },
          "component",
          this.$props,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }