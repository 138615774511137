var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "icon-badge" }, [
    _vm.isBadgeVisible
      ? _c(
          "div",
          {
            staticClass: "inbox-popup",
            class: _vm.direction,
            on: { click: _vm.openInbox },
          },
          [
            !_vm.isInline
              ? _c("i", {
                  staticClass: "material-icons inbox-popup-icon",
                  attrs: { "data-ligature": "email" },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("span", { staticClass: "inbox-popup-text" }, [
              _vm._v(_vm._s(this.unreadMessageCount.count)),
            ]),
            _vm._v(" "),
            !_vm.isInline
              ? _c("span", { staticClass: "inbox-popup-attribute" })
              : _vm._e(),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.isInline && _vm.isDotVisible
      ? _c("div", { staticClass: "inbox-dot", class: _vm.direction })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }