





























































































































import Vue, {PropType} from "vue";
import AmountSelect from '../../../../../frontend/components/AmountSelect.vue';
import {HotelsAvailability} from "../domain/HotelsAvailability";
import {triggerEvent} from "../../../../../frontend/helper/customEvents";
import SDCalendar from "../../../submodule/Calendar/view/vue/components/SDCalendar.vue";
import SDCalendarInstance from "../../../submodule/Calendar/view/vue/domain/SDCalendarInstance";
import {HotelDetailsType, flows} from "./HotelsAvailabilityWrapper.vue";
import {sdDevice} from "../../../../../frontend/helper/device";
import DealArrangements from "../../../../Deals/view/vue/DealArrangements.vue";
import BottomDrawer from "../../../../../frontend/components/BottomDrawer.vue";
import RestaurantsBuyNowButton from "./buttons/RestaurantsBuyNowButton.vue";
import HotelsBuyNowButton from "./buttons/HotelsBuyNowButton.vue";
import TimeSlots from "../../../submodule/Calendar/view/vue/components/TimeSlots.vue";
import {fetchTimeSlots, TimeSlotsParams} from "../controllers/DealAvailabilityController";
import DealArrangementsOptions from "../../../../Deals/view/vue/components/DealArrangementOptions.vue";
import MultiFlexBuyNowButton from "./buttons/MultiFlexBuyNowButton.vue";

export default Vue.extend({
  name: 'HotelsAvailability',

  components: {
    DealArrangementsOptions,
    DealArrangements,
    SDCalendar,
    AmountSelect,
    BottomDrawer,
    HotelsBuyNowButton,
    RestaurantsBuyNowButton,
    MultiFlexBuyNowButton,
    TimeSlots,
    DealArrangementsList: () => import('../../../../Deals/view/vue/components/DealArrangementsList.vue'),
    DealTabs: () => import('../../../../Deals/view/vue/deal-tabs.vue'),
    DateRangePlaceholder: () => import('../../../../../frontend/components/DateRangePlaceholder.vue'),
    Alert: () => import('../../../../../frontend/components/Alert.vue'),
  },
  props: {
    isForSale: {
      type: Boolean,
      required: true
    },
    type: {
      type: String as PropType<HotelDetailsType>,
      required: true
    },
    availability: {
      type: Object as PropType<HotelsAvailability>,
      required: true
    },
    calendar: {
      type: Object as PropType<SDCalendarInstance>,
      default: null
    },
    amount: {
      type: [ Number, null ],
      required: true
    },
    additionalAmount: {
      type: null,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  },
  async mounted() {
    if (this.availability.arrangements.length === 1) {
      this.currentArrangement = this.availability.arrangements[0];
    }

    if (this.availability.tabs && this.tabsFunctionalityActive) {
      this.setTab(this.availability.tabs[0]);
    }
  },
  data() {
    return {
      tabsFunctionalityActive: false,
      drawerOrModalActive: false,
      drawerOrModalVisible: false,
      currentTab: null,
      currentArrangement: null,
      currentArrangementOption: null,
      currentType: null,
      timeSlot: null as any,
      timeSlots: [] as string[],
      currentAlert: false
    }
  },
  methods: {
    setArrangementAndSubmit(arrangement: any) {
      this.setArrangement(arrangement, this.autoSubmitCartFromArrangements)
    },
    setArrangementOptionAndSubmit(arrangement: any) {
      this.setArrangementOption(arrangement, this.autoSubmitCartFromArrangements)
    },
    closeAlertScrollArrangements() {
      this.closeAlerts();
      this.scrollToArrangements();
    },
    openArrangementsAlert() {
      this.currentAlert = this.arrangementAlert;
    },
    openSoldOutAlert() {
      this.currentAlert = this.availability.alerts.sold_out;
    },
    closeAlerts() {
      this.currentAlert = null;
    },
    manualCloseDrawer() {
      if (this.hasTimeSlots) {
        this.updateCalendar({
          params: this.calendar.unselect
        });
      }
      this.closeDrawerOrModal();
    },
    closeDrawerAndResetCalendar() {
      this.updateCalendar({
        params: this.calendar.unselect
      });
      this.closeDrawerOrModal();
    },
    closeDrawerOrModal() {
      this.drawerOrModalActive = false;
      this.drawerOrModalVisible = false;
      this.moveWhatsApp();
    },
    setTab(tab: any) {
      this.currentTab = tab;

      if (this.currentArrangement) {
        this.setArrangement(this.filteredArrangements[0])
      }
    },
    setArrangement(arrangement: any, callback: Function = null) {
      this.currentArrangement = arrangement;
      if (!callback) {
        callback = this.scrollToRangeSelect;
      }

      this.$emit('updateAvailability', {
        unique: arrangement.unique,
        callback: callback
      });
    },
    setArrangementOption(arrangement: any, callback: Function = null) {
      this.currentArrangementOption = arrangement;

      if (callback) {
        callback();
      }
    },
    setAmount(amountOptionKey: number) {
      this.$emit('updateAmount', amountOptionKey);
      this.$emit('updateAvailability', {
        unique: this.currentArrangement?.unique ?? this.availability.unique
      });
    },
    setAdditional(amountOptionKey: any) {
      this.$emit('updateAdditional', amountOptionKey);
    },
    loadMore(params: any) {
      this.$emit('updateCalendar', {
        params: params,
        amount: this.amount,
        callback: null,
        reloadAvailability: false
      });
    },
    updateCalendar(params: any) {
      if (!this.isForSale) {
        this.openSoldOutAlert()
        return;
      }

      if (!this.currentArrangement && this.availability.isTimeSensitive) {
        this.openArrangementsAlert();
        return;
      }

      this.$emit('updateCalendar', {
        params: params,
        amount: this.amount,
        callback: this.autoSubmitCartFromCalendar,
        reloadAvailability: true
      });
    },
    scrollToRangeSelect() {
      if (document.getElementById('availability-title') !== null) {
        this.scrollToElement('#availability-title');
        return;
      }

      this.scrollToElement('#amount-wrapper');
      return;
    },
    scrollToArrangements() {
      this.scrollToElement('#hotels-availability-wrapper');
      return
    },
    scrollToElement(elementSelector: string) {
      if (this.type === flows.FLEX) {
        window.scrollTo(elementSelector, 150, 0);
        return;
      }
      triggerEvent('availability-scrollToElement', elementSelector);
    },
    openTimeslotsOrArrangementOptions() {
      this.drawerOrModalActive = true;
      /**
       *  Add delay for mobile transition
       */
      if (this.shouldAnimateArrangements) {
        setTimeout(() => {
          this.openDrawerOrModal()
        }, 100);
      } else {
        this.openDrawerOrModal()
      }
    },
    openDrawerOrModal() {
      this.drawerOrModalVisible = true;
      this.moveWhatsApp();
    },

    async fetchTimeSlots() {
      const date = this.calendar.selectedValue;
      const payload: TimeSlotsParams = {
        flow: this.availability.calendarFlow,
        unique: this.availability.calendarUnique,
        date: date
      };
      this.timeSlots = await fetchTimeSlots(payload, {
        amount: this.amount
      })
    },
    async setTimeSlot(timeSlot: string) {
      this.timeSlot = timeSlot;
      this.manualSubmitCart();
    },
    /**
     * Manually press buy now button
     */
    manualSubmitCart() {
      if (!this.isForSale) {
        this.openSoldOutAlert()
        return;
      }
      this.submitCart();
    },
    /**
     * Auto submit cart from arrangements
     */
    autoSubmitCartFromArrangements() {
      if (this.drawerOrModalActive) {
        this.closeDrawerOrModal();
      }

      if (this.calendar.selectedValue === null || (this.calendar.isRangeCalendar && !this.calendar.isRangeComplete)) {
        this.scrollToRangeSelect();
        return;
      }

      this.submitCart();
    },
    /**
     * Auto submit cart from calendar
     */
    autoSubmitCartFromCalendar() {
      if (this.calendar.isRangeCalendar && !this.calendar.isRangeComplete) {
        this.closeDrawerOrModal();
        return;
      }

      this.submitCart();
    },
    async submitCart() {
      if (!this.currentArrangement) {
        this.scrollToArrangements();
        return;
      }

      /**
       * Date requirements not met
       * */
      if (this.calendar.selectedValue === null) {
        this.closeDrawerOrModal();
        this.scrollToRangeSelect();
        return;
      }

      if (this.availability.isTimeSensitive && this.calendar.selectedValue && !this.timeSlot) {
        await this.fetchTimeSlots();
        this.openTimeslotsOrArrangementOptions();
        return;
      }

      if (this.availability.arrangementOptions.length > 0 && !this.currentArrangementOption) {
        this.openTimeslotsOrArrangementOptions();
        return;
      }

      const cartData = this.getCartData();
      this.$emit('submit', cartData);
      this.closeDrawerOrModal();
      this.currentArrangementOption = null;
    },
    getCartData() {
      const cartData = {
        time: this.availability.isTimeSensitive ? this.timeSlot : '13:00',
        unique: this.currentArrangementOption ? this.currentArrangementOption.unique : this.currentArrangement.unique,
        via: 'availability',
        timeSensitive: this.availability.isTimeSensitive,
      } as any;

      const property = typeof this.calendar.selectedValue === 'string' ? 'date' : 'range';
      cartData[property] = this.calendar.selectedValue;

      if( cartData.range !== undefined && cartData.range.till === null ) {
        delete cartData.range.till;
      }

      return cartData;
    },
    moveWhatsApp() {
      setTimeout(() => {
        $whatsappBox.move();
      }, 250);
    },
  },
  computed: {
    arrangementAlert(): any {
      const translate = (this as any
      ).$t;
      return {
        title: translate('15955.App_RestaurantPerDealReserveerJouwDeal'),
        message: translate('19576.App_AlertMessageSelectDealRestaurants'),
        buttons: [
          {
            label: translate('19578.App_AlertBackButtonSelectDealRestaurants'),
            action: 'decline'
          },
          {
            label: translate('19577.App_AlertButtonSelectDealRestaurants'),
            action: 'confirm'
          },
        ]
      }
    },
    shouldAnimateArrangements(): boolean {
      return this.isMobile || this.isDateRangeFlow || this.isRestaurantsFlow
    },
    buttonText(): string {
      return this.availability.button.title
    },
    isMobile(): boolean {
      return sdViewport.isMobile()
    },
    isFlexFlow(): boolean {
      return this.type === flows.FLEX;
    },
    isRestaurantsFlow(): boolean {
      return this.type === flows.RESTAURANT;
    },
    isDateRangeFlow(): boolean {
      return this.type === flows.DATE_RANGE;
    },
    hasTimeSlots(): boolean {
      return this.timeSlots && this.timeSlots.length > 0 && this.availability.isTimeSensitive;
    },
    filteredArrangements(): any {
      if (this.currentTab) {
        return this.availability.arrangements.filter((arrangement: any) => {
          return arrangement.tab === this.currentTab;
        })
      }
      return this.availability.arrangements;
    },
    drawerTitle(): string {
      if (this.hasTimeSlots) {
        return (this as any).$t('3968.App_availableTimesLabel');
      }

      return (this as any).$t('24188.App_arrangementOptionDrawerTitle');
    },
    buyNowButtonType(): string | null {
      if (this.calendar) {
        if (this.isRestaurantsFlow) {
          return 'RestaurantsBuyNowButton';
        }
        if (this.isDateRangeFlow) {
          return 'HotelsBuyNowButton';
        }
        if (this.isFlexFlow) {
          return 'MultiFlexBuyNowButton';
        }
      }

      return null;
    }

  }
});
