var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { "force-overlay-modal": _vm.drawerOrModalVisible },
      attrs: { id: "preview-availability" },
    },
    [
      _c("h2", [_vm._v(_vm._s(_vm.$t("2906.Deals_hashAvailability")))]),
      _vm._v(" "),
      _c("div", { staticClass: "mobile-spacing" }, [
        _vm.shouldShowArrangementSelect
          ? _c(
              "div",
              { staticClass: "availability-options z-30" },
              [
                _c("SDSelectBox", {
                  attrs: {
                    label: _vm.$t("4198.App_arrangementTitle"),
                    placeholder: _vm.$t("6976.App_selectReservationDeal"),
                    options: _vm.arrangements,
                    value: _vm.activeArrangementKey,
                    active: _vm.optionsVisible,
                  },
                  on: {
                    close: _vm.closeOptions,
                    open: _vm.openOptions,
                    setOption: _vm.setArrangement,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "preview-availability-calendar",
          attrs: { id: "preview-availability-calendar" },
        },
        [
          _vm.calendar
            ? _c("SDCalendar", {
                attrs: { calendar: _vm.calendar, type: "hotels" },
                on: {
                  update: _vm.updateCalendar,
                  loadMore: _vm.loadMoreCalendar,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }