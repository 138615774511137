import ValueObject from "../../../../../../../frontend/ValueObject";
import SingleDayCalenderParams from "./SingleDayCalenderParams";
import Month from "./Month";
import { IDateShortcuts } from "./SDCalendarInstance";
import DateShortcut from "./DateShortcut";

export interface ISingleDayCalendar {
    months: any,
    params: SingleDayCalenderParams,
    selectedValue: any,
    summary: any,
    date_shortcuts: IDateShortcuts,
}

export default class SingleDayCalendar extends ValueObject<ISingleDayCalendar> {

    get params () {
        return this._props.params
    }

    get months (): Month[] {
        return this._props.months;
    }

    get selectedValue () {
        return this._props.selectedValue;
    }

    get summary () {
        return this._props.summary;
    }

    get dateShortcuts(): DateShortcut[] {
        return this._props.date_shortcuts ? this._props.date_shortcuts.items : [];
    }

    get activeShortcut(): string | null {
        return this.params.dateShortcut;
    }

    static fromApi ( data: any ): SingleDayCalendar {
        return new SingleDayCalendar(
            {
                months         : data.months.map( ( month: any ) => Month.create( month ) ),
                params         : SingleDayCalenderParams.fromApi( data.params ),
                selectedValue  : data.selectedValue,
                summary        : data.summary,
                date_shortcuts : data.date_shortcuts ? {
                    items    : data.date_shortcuts.items.map( ( shortcut: any ) => DateShortcut.create( shortcut ) ),
                    selected : data.date_shortcuts.selected_unique
                } : null,
            } );
    }
}
