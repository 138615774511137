var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "arrangements__item",
      class: [
        { active: _vm.isActive },
        { "not-purchasable": !_vm.arrangement.isPurchasable },
      ],
      on: {
        click: function ($event) {
          return _vm.$emit("setArrangement", _vm.arrangement)
        },
      },
    },
    [
      _c("div", { staticClass: "arrangements__item-content" }, [
        _c("div", { staticClass: "item-content__top" }, [
          _c("div", { staticClass: "arrangements__title-wrapper" }, [
            _c("div", { staticClass: "arrangements__item-radio" }),
            _vm._v(" "),
            _c("h6", [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.arrangement.title) +
                  "\n                "
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.arrangement.stats.discount && !_vm.isDisplayTypeTitle
            ? _c("div", { staticClass: "item-content__pill" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.arrangement.stats.discount) +
                    "\n            "
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _vm.arrangement.subtitle
          ? _c("div", { staticClass: "item-content__row" }, [
              _c("p", { staticClass: "arrangements__subtitle" }, [
                _vm._v(_vm._s(_vm.arrangement.subtitle)),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.isDisplayTypeTitle
          ? _c("div", { staticClass: "item-content__row" }, [
              _c("div", { staticClass: "sold" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.arrangement.stats.sales) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "prices" }, [
                _vm.arrangement.price.amount < _vm.arrangement.fromPrice.amount
                  ? _c("div", {
                      staticClass: "old-price",
                      domProps: {
                        innerHTML: _vm._s(_vm.arrangement.fromPrice.value),
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", {
                  staticClass: "new-price",
                  domProps: { innerHTML: _vm._s(_vm.arrangement.price.value) },
                }),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.arrangement.pills.length > 0 && !_vm.isDisplayTypeTitle
          ? _c("div", { staticClass: "item-content__row" }, [
              _c(
                "div",
                { staticClass: "pills" },
                _vm._l(_vm.arrangement.pills, function (pill, pillKey) {
                  return _c(
                    "div",
                    {
                      key: pillKey,
                      staticClass: "pill",
                      style: "background-color: " + pill.background_color.hex,
                    },
                    [
                      _c("span", { style: "color: " + pill.label.color.hex }, [
                        _vm._v(
                          "\n                      " +
                            _vm._s(pill.label.label) +
                            "\n                    "
                        ),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }