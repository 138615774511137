import ValueObject from "../../../../../frontend/ValueObject";
import { AmountSelect } from "./AmountSelect";
import { Price } from "../../../../Deals/view/vue/domain/Price";

export interface IDealsAvailability {
    multi_tab_name: string,
    multi_deal_description: string,
    stats: any[],
    price: number,
    from_price: number,
    originalPrice: number,
    input_alerts?: any,
    default_start_option: number,
    calendar_flow: string,
    options: any,
    option_list: any,
    calendar_unique: string,
    unique: string,
    additional_people_list?: any[],
    additional_people_placeholder?: string,
    additional_people_placeholder_input_label?: string,
    is_time_sensitive?: boolean,
    is_purchasable?: boolean,
    requirements?: string[],
}

export class DealsAvailability extends ValueObject<IDealsAvailability> {

    set alerts( alerts: any ) {
        this._props.input_alerts = alerts;
    }

    get alerts() {
        return this._props.input_alerts;
    }

    get title() {
        return this._props.multi_tab_name;
    }

    get price() {
        return this._props.price;
    }

    get fromPrice() {
        return this._props.from_price;
    }

    get stats() {
        return this._props.stats;
    }

    get description() {
        return this._props.multi_deal_description;
    }

    get calendarFlow() {
        return this._props.calendar_flow;
    }

    get calendarUnique() {
        return this._props.calendar_unique;
    }

    get unique() {
        return this._props.unique;
    }

    get amount(): AmountSelect {
        return this._props.options.amount;
    }

    get additionalAmount(): AmountSelect {
        return this._props.options.additionalAmount;
    }

    get isPurchasable(): boolean {
        return this._props.is_purchasable;
    }

    get isTimeSensitive(): boolean {
        return this._props.is_time_sensitive;
    }

    get isAdditionalRequired(): boolean {
        return this._props.requirements.some( ( requirement: string ) => requirement === 'additional_people' )
    }

    get requirements(): string[] {
        return this._props.requirements;
    }

    public static create( props: any ) {
        return new DealsAvailability( props );
    }

    public static fromApi( props: any ) {

        const amount = AmountSelect.create( {
            label           : props.input_label,
            placeholder     : props.placeholder,
            options         : props.option_list,
            selected_option : props.default_start_option
        } );

        const additionalAmount = AmountSelect.create( {
            label       : props.additional_people_placeholder_input_label,
            placeholder : props.additional_people_placeholder,
            options     : props.additional_people_list
        } );

        return DealsAvailability.create( {
            calendar_flow          : props.calendar_flow,
            calendar_unique        : props.calendar_unique,
            options                : {
                amount           : amount,
                additionalAmount : additionalAmount
            },
            unique                 : props.unique,
            input_alerts           : props.input_alerts,
            is_time_sensitive      : props.is_time_sensitive,
            is_purchasable         : props.is_purchasable,
            multi_tab_name         : props.multi_tab_name,
            multi_deal_description : props.multi_deal_description,
            stats                  : props.stats,
            requirements           : props.requirements,
            from_price             : Price.create( {
                amount   : (props.prices?.from_price?.amount ?? 0) / 100,
                currency : {
                    symbol : props.prices?.from_price?.currency?.symbol ?? '€'
                }
            } ),
            price                  : Price.create( {
                amount   : (props.prices?.price?.amount ?? 0 ) / 100 ,
                currency : {
                    symbol : props.prices?.price?.currency?.symbol ?? '€'
                }
            } ),
        } )
    }
}
