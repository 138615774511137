var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "day-wrapper",
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
        mouseover: function ($event) {
          return _vm.$emit("mouseover")
        },
        mouseleave: function ($event) {
          return _vm.$emit("mouseleave")
        },
      },
    },
    [
      _vm.day.someToolTip && _vm.day.tooltipVisible
        ? _c("ToolTip", {
            attrs: { "day-key": _vm.dayKey, "tool-tip": _vm.day.someToolTip },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.shouldShowBefore
        ? _c("div", {
            class: ["before", "bg-" + _vm.day.colors.background_left],
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            "calendar-day calendar-day-with-price",
            _vm.day.additionalClassList,
            { "calendar-day-with-price": _vm.day.dayPrice },
            { "hover-active": _vm.day.isHoverActive },
            { "hover-allowed": !!_vm.day.hoverColors },
          ],
        },
        [
          _c(
            "div",
            {
              class: [
                "day-text text-" + _vm.day.colors.text,
                _vm.day.colors.underline
                  ? "border-b-2 border-" + _vm.day.colors.underline
                  : "",
              ],
            },
            [_vm._v("\n      " + _vm._s(_vm.day.label) + "\n    ")]
          ),
          _vm._v(" "),
          _vm.day.dayPrice
            ? _c(
                "div",
                {
                  class: [
                    "day-price",
                    _vm.day.colors.price ? "text-" + _vm.day.colors.price : "",
                  ],
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.day.dayPrice.toString()) + "\n    "
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.shouldShowAfter
        ? _c("div", {
            class: ["after", "bg-" + _vm.day.colors.background_right],
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }