















import Vue, { PropType } from 'vue';
  import { PopularPhraseItem } from "../../domain/PopularPhraseItem";

  export default Vue.extend({
    name : 'PopularPhraseItem',
    props : {
      item: {
        type : Object as PropType<PopularPhraseItem>,
        required : true
      }
    },
    methods: {
      handleClick() {
        this.$emit('itemClick', this.item );
      }
    }
  })
