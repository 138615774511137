var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "modal-toggle",
          class: [_vm.fixed ? "fixed" : ""],
          on: { click: _vm.openModal },
        },
        [
          _c("span", {
            staticClass: "material-icons search-icon",
            attrs: { "data-ligature": "search" },
          }),
          _vm._v(" "),
          _c("SearchBarInfo", { attrs: { labels: _vm.labels } }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showFilterButton,
                  expression: "showFilterButton",
                },
              ],
              staticClass: "searchbar-filter-button-icon-container",
              on: { click: _vm.handleOpenFilterClick },
            },
            [
              _c("div", { staticClass: "icon-badge-wrapper" }, [
                _c("i", {
                  staticClass: "sd2-icons filter searchbar-filter-button-icon",
                }),
                _vm._v(" "),
                _vm.showBadge
                  ? _c(
                      "span",
                      {
                        staticClass: "open-filter-button-count",
                        style: { background: _vm.badge.color },
                        attrs: { id: "filter-button-badge" },
                      },
                      [_vm._v(_vm._s(_vm.badge.label))]
                    )
                  : _vm._e(),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.activeModal === "nearby"
        ? _c("NearbyModal", {
            attrs: {
              "default-search-bar": _vm.searchBar,
              "default-labels": _vm.labels,
              "gps-label": _vm.$t("12013.App_NearbyDealsLabel"),
              "selected-categories": _vm.selectedCategories,
            },
            on: {
              "category-changed": _vm.categoryChanged,
              submit: _vm.updateSearchBar,
              close: _vm.closeModal,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.activeModal === "lmd-nearby"
        ? _c("LmdModal", {
            attrs: {
              "default-search-bar": _vm.searchBar,
              "default-labels": _vm.labels,
              "gps-label": _vm.$t("12014.App_NearbyRestaurantsLabel"),
              "deprecated-calendar": _vm.deprecatedCalendar,
            },
            on: { submit: _vm.updateSearchBar, close: _vm.closeModal },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.activeModal === "hotels"
        ? _c("HotelsModal", {
            attrs: {
              "default-search-bar": _vm.searchBar,
              "default-labels": _vm.labels,
              "gps-label": _vm.$t("12015.App_NearbyHotelsLabel"),
            },
            on: { submit: _vm.updateSearchBar, close: _vm.closeModal },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.activeModal && _vm.loading
        ? _c("Loader", { attrs: { type: "fixed" } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }