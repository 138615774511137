











































































import Vue, { PropType } from "vue";
import SearchInput from "./components/SearchInput.vue";
import DealList from "./components/lists/DealList.vue";
import SearchHistoryList from "./components/lists/SearchHistoryList.vue";
import ExperienceList from "./components/lists/ExperienceList.vue";
import Message from './components/Message.vue';
import { Deal } from "./domain/Deal";
import { SearchHistoryItem } from "./domain/SearchHistoryItem";
import { Experience } from "./domain/Experience";
import { SearchState } from "./Tweakwise.vue";
import { SearchHistoryDTO } from "./domain/dto/SearchHistoryDTO";
import { TagCloudCategory } from "./domain/TagCloudCategory";
import AutocompleteSuggestionList from "./components/lists/AutocompleteSuggetionList.vue";
import { hasUrlQueryParam } from "../../../../frontend/helper/urlParams";
import { SearchPredictionSuggestion } from "../../../../frontend/services/SearchService";
import { SearchDealsConfiguration } from "./domain/SearchDealsConfiguration";
import { PopularPhrase } from "./domain/PopularPhrase";
import PopularPhraseList from "./components/lists/PopularPhraseList.vue";
import { PopularPhraseItem } from "./domain/PopularPhraseItem";
import { Experiences } from "./domain/Experiences";
import { SearchHistory } from "./domain/SearchHistory";

type Data = {
  submitTimeout: any;
  delayTimeout: any;
  isInputFocussed: boolean;
  isInputInvalid: boolean;
}

export default Vue.extend( {
  name       : 'TweakwiseSearchBar',
  components : {
    PopularPhraseList,
    AutocompleteSuggestionList,
    ExperienceList,
    SearchHistoryList,
    DealList,
    SearchInput,
    Message
  },
  props      : {
    query                : {
      type     : String,
      required : true
    },
    submitQuery          : {
      type    : String,
      default : ''
    },
    cityUnique           : {
      type     : String,
      required : true
    },
    searchDealsConfiguration: {
      type : Object as PropType<SearchDealsConfiguration>,
      default: null
    },
    tagCloudCategory     : {
      type : Object as PropType<TagCloudCategory>
    },
    placeholder          : {
      type     : String,
      required : true
    },
    inputValue           : {
      type    : String,
      default : null
    },
    searchState          : {
      type     : String as PropType<SearchState>,
      required : true
    },
    autoCompleteSearchState : {
      type     : String as PropType<SearchState>,
      required : true
    },
    autoComplete         : {
      type     : Array as PropType<Array<SearchPredictionSuggestion>>,
      required : true
    },
    autoCompleteDeals    : {
      type     : Array as PropType<Array<Deal>>,
      default : null
    },
    autoCompleteLoadMore : {
      type    : Boolean,
      default : false
    },
    deals                : {
      type     : Array as PropType<Array<Deal>>,
      required : true
    },
    searchHistory        : {
      type     : Object as PropType<SearchHistory>,
      default : null
    },
    experiences      : {
      type     : Object as PropType<Experiences>,
      default  : null
    },
    popularPhrase: {
      type     : Object as PropType<PopularPhrase>,
      default  : null
    },
    isDealDetail : {
      type     : Boolean,
      default  : false
    }
  },
  data       : (): Data => ({
    submitTimeout   : null,
    delayTimeout    : null,
    isInputFocussed : false,
    isInputInvalid  : false
  }),
  mounted() {
    if ( hasUrlQueryParam( 'q' ) ) {
      (this.$refs.searchbar as any).focus();
    }

    window.addEventListener('cleartweakwise', this.handleSearchBarClean);
  },
  beforeDestroy() {
    window.removeEventListener('cleartweakwise', this.handleSearchBarClean);
  },
  computed : {
    value(): string {
      return this.inputValue ?? this.query;
    },
    isSearchStateNoResults(): boolean {
      return this.searchState === SearchState.NO_RESULTS;
    },
    isAutocompleteSearchStateNoResults(): boolean {
      return this.autoCompleteSearchState === SearchState.NO_RESULTS
    },
    isSearchStateResults(): boolean {
      return this.searchState === SearchState.RESULTS;
    },
    isSearchStateSearching(): boolean {
      return this.searchState === SearchState.SEARCHING;
    },
    isSearchStateInitial(): boolean {
      return this.searchState === SearchState.INITIAL;
    },
    isAutocompleteSearchStateResults(): boolean {
      return this.autoCompleteSearchState === SearchState.RESULTS
    },
    isValidQuery(): boolean {
      return this.query.length > 2 || (this.submitQuery ?? ``).length > 2;
    },
    hasInvalidResultAndFocussed(): boolean {
      return this.isSearchStateNoResults && this.isInputFocussed;
    },
    isEmptyState(): boolean {
      return this.isValidQuery && this.isSearchStateNoResults && !this.isSearchStateSearching;
    },
    hasSearchHistory(): boolean {
      return this.searchHistory.searchHistory.length > 0;
    }
  },
  methods  : {
    handleSubmit(): void {
      if ( this.query.length > 2 ) {
        this.$emit( 'submit', SearchHistoryDTO.createForManualSearch( {
          query      : this.query,
          cityUnique : this.cityUnique,
          tag        : this.tagCloudCategory?.name
        } ) );
      } else {
        this.isInputInvalid = true;
      }
    },
    handleSearchBarClick( event: any ): void {
      event.stopPropagation();
      event.preventDefault();

      if ( sdViewport.isMobile() ) {
        this.$emit( 'openModal' );
      }
    },
    handleSearchBarChange( event: any ): void {
      let value = event.target?.value ?? ``;
      value = value.trim();

      this.$emit( 'setQueryParams', value );

      if ( value.length > 2 ) {
        this.isInputInvalid = false;

        if ( this.delayTimeout ) {
          clearTimeout( this.delayTimeout );
        }

        this.delayTimeout = setTimeout( () => {
          this.$emit( 'onFetchAutocomplete', this.query );
        }, 200 );
      }

      this.$emit( 'onChangeQuery', value );
    },
    handleSearchBarClean(): void {
      this.$emit( 'clear' );
      this.$emit( 'onChangeQuery', '' );
    },
    handleSearchHistoryItemClick( search: SearchHistoryItem ): void {
      this.$emit( 'clickHistoryItem', search );
    },
    handlePopularPhraseItemClick( popularPhraseItem: PopularPhraseItem ) : void {
      this.$emit('clickPopularPhraseItem', popularPhraseItem );
    },
    handleExperienceItemClick( suggestion: Experience ): void {
      this.$emit( 'clickSuggestionItem', suggestion );
    },
    handleFocus( e: Event ) {
      this.$emit( 'onFocus', e );
      if ( window.innerWidth <= 768 ) {
        return;
      }

      this.isInputFocussed = true;

    },
    handleBlur(): void {
      /**
       * Added delay for closing the input out of focus because the blur event is fired before the mouse click.
       * So the user could not click the suggestion/history item
       */
      setTimeout( () => {
        this.$emit('blur');
        this.isInputFocussed = false;
        this.isInputInvalid = false;
      }, 200 );
    },
    handleFetchMore(): void {
      this.$emit( 'fetchMore' );
    },
    handleFetchMoreAutoCompleteDeals(): void {
      this.$emit('fetchMoreAutocomplete');
    },
    handleAutocompleteItemClick( suggestion: SearchPredictionSuggestion ): void {
      this.$emit( 'onChangeQuery', suggestion.match );
      this.$emit( 'clickAutocompleteItem', suggestion );
    },
    blur() {
      (this.$refs.searchbar as any).blur();
    }
  }
} )
