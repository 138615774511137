var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "deals-availability-wrapper" } },
    [
      _vm.availability
        ? _c(
            _vm.isPreviewAvailability
              ? "PreviewAvailability"
              : "DealsAvailability",
            {
              tag: "component",
              attrs: {
                availability: _vm.availability,
                arrangement: _vm.arrangement,
                options: _vm.options,
                calendar: _vm.calendar,
                amount: _vm.amount,
                "additional-amount": _vm.additionalAmount,
                "time-slots": _vm.timeSlots,
                "force-flow-active": _vm.forceFlowActive,
              },
              on: {
                setArrangement: _vm.setArrangement,
                setAmount: _vm.setAmount,
                setAdditionalAmount: _vm.setAdditionalAmount,
                setTimeSlot: _vm.setTimeSlot,
                setForceFlow: _vm.setForceFlow,
                updateCalendar: _vm.updateCalendar,
                resetCalendar: _vm.resetCalendar,
              },
            }
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.availability && _vm.initialized && !_vm.isPreviewAvailability
        ? _c("div", { staticClass: "availability-text" }, [
            _vm._v("\n    * "),
            _c("span", {
              staticClass: "translated-text",
              domProps: {
                innerHTML: _vm._s(
                  _vm
                    .$t("3117.Reservations_autoBuyReserveMessage")
                    .replace(
                      ":link",
                      "showCart(event, '/cart/add/" + this.unique + "/')"
                    )
                ),
              },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.calendarAlertVisible
        ? _c("CalendarNoAvailabilityAlert", {
            attrs: { alert: this.calendar.noAvailabilityAlert },
            on: { close: _vm.closeAvailabilityAlert },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isForSale && _vm.soldOutAlertVisible
        ? _c("SoldOutAlert", {
            attrs: { alert: this.availability.alerts.sold_out },
            on: { close: _vm.closeSoldOutAlert },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.initialized || _vm.loading.visible
        ? _c("Loader", { attrs: { type: _vm.loading.type } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }