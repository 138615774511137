var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "details-tabs" },
    _vm._l(_vm.tabs, function (tab, tabKey) {
      return _c(
        "div",
        {
          key: tabKey,
          staticClass: "details__tab-item",
          class: { active: _vm.current === tab },
          on: {
            click: function ($event) {
              return _vm.$emit("set", tab)
            },
          },
        },
        [
          _c("i", {
            staticClass: "sd2-icons",
            class:
              _vm.current === tab ? "radio-button-checked" : "radio-button",
          }),
          _vm._v("\n    " + _vm._s(tab) + "\n  "),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }