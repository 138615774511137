




































import Vue, { PropType } from "vue";
import AvailabilityService from "../../../../../frontend/services/AvailabilityService";
import CalendarService from "../../../../../frontend/services/CalendarService";
import { triggerEvent } from "../../../../../frontend/helper/customEvents";
import SDCalendarInstance from "../../../submodule/Calendar/view/vue/domain/SDCalendarInstance";
import { HotelsAvailability } from "../domain/HotelsAvailability";
import { sdDevice } from "../../../../../frontend/helper/device";
import { getUrlQueryParam } from "../../../../../frontend/helper/urlParams";

export type HotelDetailsType = 'flex' | 'date-range' | 'restaurant-deal';

export const flows = {
  DATE_RANGE : 'date-range',
  FLEX       : 'flex',
  RESTAURANT : 'restaurant-deal'
}

export default Vue.extend( {
  name       : 'HotelsAvailabilityWrapper',
  props      : {
    availabilityParams : {
      type    : Object,
      default : () => {
      }
    },
    unique             : {
      type    : String,
      default : null
    },
    type               : {
      type    : String as PropType<HotelDetailsType>,
      default : flows.DATE_RANGE
    },
    isForSale          : {
      type     : Boolean,
      required : true
    }
  },
  components : {
    Loader             : () => import('../../../../../frontend/components/Loader.vue'),
    HotelsAvailability : () => import('./HotelsAvailability.vue'),
    Alert              : () => import('../../../../../frontend/components/Alert.vue'),
  },
  data() {
    return {
      loading          : {
        visible : true,
        type    : 'absolute'
      },
      dealUnique       : this.availabilityParams?.dealUnique ?? this.unique,
      amount           : this.availabilityParams?.amount ?? null,
      additionalAmount : null,
      availability     : null as any,
      calendar         : null as SDCalendarInstance,
      currentAlert     : null
    }
  },
  async mounted() {
    const calendarParams = this.availabilityParams?.initial_calendar_params ?? {};

    const isInclusive = getUrlQueryParam( 'inclusive' ) !== 'false';

    let availabilityParams = {
      amount : this.amount
    }
    if( isInclusive && this.isDateRangeFlow ) {
      availabilityParams = {
        ...availabilityParams,
        ...{
          'range[from]' : this.availabilityParams?.from_date ?? null,
          'range[till]' : this.availabilityParams?.till_date ?? null,
        }
      }
    }

    await this.fetchAvailability( this.availabilityFlow, availabilityParams );
    await this.fetchCalendar(
        this.availability.calendarFlow,
        this.availability.calendarUnique,
        this.amount,
        isInclusive ? calendarParams : {}
    );

    if ( this.availability.additionalAmount ) {
      this.additionalAmount = this.availability.additionalAmount.selectedOptionKey;
    }

    this.isMobile ? this.loading.type = 'fixed' : this.loading.type = 'absolute';
  },
  methods : {
    async fetchAvailability( type: string, params: any ): Promise<void> {
      this.loading.visible = true;

      if( params.amount === null ) {
        delete params.amount;
      }
      const availabilityResponse = await AvailabilityService.getByUnique( type, this.dealUnique, params );
      this.availability = HotelsAvailability.fromApi( availabilityResponse );

      this.amount = this.availability.amount.selectedOptionKey;
      this.additionalAmount = this.availability.additionalAmount?.selectedOptionKey

      /* Some jQuery bullshit to manipulate te DOM */
      let $dealCard = $( '.deal-card-lmd-details, .deal-details-info-mobile');
      let $priceCol = $dealCard.find(' .stats-container .price-col' );
      let $cardRibbon = $dealCard.find('.card-ribbon' );

      let $dealCardDesktop = $( '#detailBox #deal-information' );
      let $priceStickyDealDesktop = $( '#desktop-sticky-deal').find( '.price-col' );
      let $priceColDesktop = $dealCardDesktop.find( '.left .deal-price' );
      let $cardRibbonDesktop = $dealCardDesktop.find( '.left .deal-statistics .discount-ribbon, .right .card-ribbon span')
      if( this.availability.dealPrices != null ) {
        $priceCol.find( '.price' ).html( this.availability.dealPrices.priceLabel ?? this.availability.dealPrices.price.toHtml( false ) );
        $priceCol.find( '.old-price' ).html( this.availability.dealPrices.from_price.toHtml( true ) );
        $cardRibbon.html( this.availability.dealPrices.discount_label );

        const newPrice = this.availability.dealPrices.priceLabel ?? this.availability.dealPrices.price.toHtml( false );
        const oldPrice = this.availability.dealPrices.from_price.toHtml( true )

        $priceColDesktop.find( '.new-price span').html( newPrice );
        $priceColDesktop.find( '.old-price span').html( oldPrice );
        $cardRibbonDesktop.html( this.availability.dealPrices.discount_label );
        $priceStickyDealDesktop.find( '.price' ).html( newPrice );
        $priceStickyDealDesktop.find( '.old-price').html( oldPrice );
      }
      $priceCol.parent().removeClass('hidden');
      $cardRibbon.removeClass( 'hidden' );
      /* Until here :') */

      this.loading.visible = false;
    },
    async fetchCalendar( flow: string, unique: string, amount: number, params: any ) {
      this.loading.visible = true;
      const calendarResponse = await CalendarService.getCalendarByFlowAndUnique(
          flow,
          unique,
          params,
          this.amount,
      );

      this.calendar = SDCalendarInstance.fromApi( calendarResponse );

      if ( this.calendar.noAvailabilityAlert ) {
        this.currentAlert = {
          ...this.calendar.noAvailabilityAlert,
          positionAbsolute : true
        };
      }

      this.loading.visible = false;
    },
    async updateAvailability( data: any ) {
      const { unique, callback, skipCalendarUpdate } = data;

      this.loading.visible = true;

      const payload: any = {
        amount            : this.amount,
        additional_amount : this.additionalAmount,
        ...this.calendar.toAvailabilityDTO()
      }

      if ( unique && unique !== this.availability.unique ) {
        payload.arrangement_unique = unique;
      }

      await this.fetchAvailability( this.availabilityFlow, payload );

      if ( !skipCalendarUpdate ) {
        await this.updateCalendar( {
          params             : this.calendar.params,
          amount             : this.amount,
          callback           : null,
          reloadAvailability : false
        } );
      }

      if ( callback ) {
        callback();
      }
      this.loading.visible = false;
    },

    async updateCalendar( payload: any ): Promise<any> {
      this.loading.visible = true;

      const { params, amount, callback, reloadAvailability } = payload;

      const calendar = await CalendarService.getCalendarByFlowAndUnique(
          this.availability.calendarFlow,
          this.availability.calendarUnique,
          params,
          amount
      );

      if ( calendar ) {
        this.calendar = Object.assign( this.calendar, SDCalendarInstance.fromApi( calendar ) );
      }

      if ( reloadAvailability ) {
        await this.updateAvailability( {
          unique             : this.availability.calendarUnique,
          skipCalendarUpdate : true
        } )
      }

      if ( typeof callback === 'function' ) {
        callback();
      }

      this.loading.visible = false;
    },

    updateAmount( amountOptionKey: number ) {
      this.amount = amountOptionKey;
    },

    updateAdditional( amountOptionKey: number ) {
      this.additionalAmount = amountOptionKey;
    },
    closeAlerts() {
      this.currentAlert = null;
    },
    submit( cartData: any ) {
      const cartPayload = Object.assign( cartData, {
        people           : this.amount,
        additionalPeople : this.additionalAmount,
      } );

      cartPayload.redirect = $( 'meta[property="modal-base"]' ).attr('content');

      ($ as any).sdGA( 'availability' );

      var baseLink = '/cart/add/' + cartPayload.unique

      if(this.isRestaurants) {
        baseLink = baseLink + '/dine';
      }

      var link = baseLink + '/?' +  ( jQuery as any
      ).param( cartPayload );

      triggerEvent( 'availability-closeAvailabilityModal', {} )
      showCart( null, link, {
        reloadOnClose : !this.isFlexFlow
      } );
    }
  },

  computed : {
    isRestaurants(): boolean {
      return this.type === flows.RESTAURANT
    },
    availabilityFlow(): string {
      return this.type === flows.RESTAURANT ? 'restaurant-deal' : 'hotel-deal'
    },
    isDateRangeFlow(): boolean {
      return this.type === flows.DATE_RANGE;
    },
    isFlexFlow(): boolean {
      return this.type === flows.FLEX;
    },
    isMobile() {
      return sdDevice.isMobile()
    },
  }
} );
