var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "search-modal" } }, [
    _c(
      "div",
      { staticClass: "search-modal-container" },
      [
        _c(
          "div",
          { staticClass: "bar" },
          [
            _c("SearchInput", {
              ref: "searchbar",
              attrs: {
                autocomplete: "tweakwise-modal",
                value: _vm.query,
                "disable-clear-button": true,
                invalid: _vm.isInputInvalid,
                placeholder: _vm.placeholder,
              },
              on: { onChange: _vm.handleSearchBarChange, submit: _vm.submit },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "close-button", on: { click: _vm.cancel } },
              [_c("div", { staticClass: "sd2-icons cross" })]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { ref: "content", staticClass: "content" },
          [
            _vm.isSearchStateInitial || _vm.query.length < 3
              ? _c(
                  "div",
                  { staticClass: "tweakwise-modal-suggestions-list" },
                  [
                    _vm.searchHistory
                      ? _c("SearchHistoryList", {
                          style: { order: _vm.searchHistory.index },
                          attrs: {
                            id: "tweakwise-mobile-historylist",
                            "search-history": _vm.searchHistory,
                          },
                          on: { click: _vm.handleSearchHistoryItemClick },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.popularPhrase
                      ? _c("PopularPhraseList", {
                          style: { order: _vm.popularPhrase.index },
                          attrs: { "popular-phrase": _vm.popularPhrase },
                          on: { itemClick: _vm.handlePopularPhraseItemClick },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.experiences
                      ? _c("ExperienceList", {
                          style: { order: _vm.experiences.index },
                          attrs: { experiences: _vm.experiences },
                          on: { click: _vm.handleSuggestionClick },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isEmptyState
              ? _c(
                  "div",
                  [
                    _c("Message", {
                      attrs: {
                        "image-src": "/img/no-deals-found.svg",
                        label: _vm
                          .$t("17307.App_SearchTweakwiseEmptyStateWithInput")
                          .replace(":search", _vm.query),
                      },
                    }),
                    _vm._v(" "),
                    _vm.experiences
                      ? _c("ExperienceList", {
                          attrs: { experiences: _vm.experiences },
                          on: { click: _vm.handleSuggestionClick },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.query.length > 2
              ? _c("AutocompleteSuggestionList", {
                  attrs: {
                    "search-query": _vm.query,
                    suggestions: _vm.autoComplete,
                  },
                  on: { click: _vm.handleAutocompleteItemClick },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.query.length > 2 &&
            (_vm.isSearchStateSearching || _vm.isSearchStateResults)
              ? _c("AutoCompleteDealList", {
                  attrs: { deals: _vm.autoCompleteDeals },
                  on: { loading: _vm.handleLoading },
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "deal-list-modal-container" },
              [
                _vm.isEmptyState
                  ? _c("DealList", { attrs: { deals: _vm.planningDeals } })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _vm.autoCompleteDeals &&
            _vm.autoCompleteDeals.length > 0 &&
            _vm.query.length > 2
              ? _c(
                  "div",
                  {
                    staticClass: "show-more-container",
                    on: { click: _vm.submit },
                  },
                  [
                    _c("div", { staticClass: "show-more-btn" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("13580.App_LoadMoreButtonSearchTweakwise")
                        )
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _vm.isLoadingDeal ? _c("TweakwiseLoader") : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "button", on: { click: _vm.submit } }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("12903.App_SearchTweakwiseSearchButton")) +
              "\n    "
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }