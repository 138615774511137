













import Vue from "vue";

export default Vue.extend( {
  name  : 'DealTabs',
  props : {
    tabs    : {
      type     : Array,
      required : true
    },
    current : {
      type    : String,
      default : null
    }
  },
} );
