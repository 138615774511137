var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "sdSelectBox",
      staticClass: "sd-select-box",
      class: { "z-0": _vm.setZIndexTo0 },
    },
    [
      _c("div", { staticClass: "box-layer" }, [
        _c(
          "select",
          {
            staticClass: "native-select",
            on: { change: _vm.setOptionViaEventValueKey },
          },
          [
            _vm.value === null
              ? _c("option", {
                  attrs: { hidden: "", disabled: "", selected: "", value: "" },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.options, function (option, optionKey) {
              return _c(
                "option",
                {
                  key: optionKey,
                  staticClass: "option",
                  domProps: {
                    value: optionKey,
                    selected: option.key === _vm.value,
                  },
                },
                [_vm._v("\n        " + _vm._s(option.value) + "\n      ")]
              )
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "border-decorator" }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "select-toggle",
            class: [
              { active: _vm.isOptionsActive || _vm.forceActive },
              { filled: _vm.value != null },
            ],
            on: { click: _vm.toggleOptions },
          },
          [
            _c("div", { staticClass: "input-label" }, [
              _vm._v(_vm._s(_vm.label)),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "input-placeholder" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.currentPlaceholder) + "\n      "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isOptionsActive,
                    expression: "isOptionsActive",
                  },
                ],
                staticClass: "option-list",
                class: _vm.optionsOffsetClass,
              },
              _vm._l(_vm.options, function (option, optionKey) {
                return _c(
                  "div",
                  {
                    key: optionKey,
                    staticClass: "option",
                    class: { active: option.key === _vm.value },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.setOption(option)
                      },
                    },
                  },
                  [_vm._v("\n          " + _vm._s(option.value) + "\n        ")]
                )
              }),
              0
            ),
          ]
        ),
        _vm._v(" "),
        _vm.isOptionsActive
          ? _c("div", {
              staticClass: "close-layer",
              on: { click: _vm.closeOptions },
            })
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }