var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "autocompleet-deals" },
    _vm._l(_vm.deals, function (deal, index) {
      return _c("DealCardAutocomplete", {
        key: index,
        attrs: { deal: deal },
        on: { loading: _vm.handleLoading },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }