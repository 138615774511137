import Entity from "../../../../../frontend/Entity";
import {IExperience} from "./interfaces/IExperience";
import {ISearchResponse} from "../../../../../frontend/services/SearchService";
import { Emoji } from "./Emoji";
import ValueObject from "../../../../../frontend/ValueObject";
import {ExperienceParams} from "./ExperienceParams";

export class Experience extends ValueObject<IExperience> implements  IExperience {
    public get unicode_icon(): string {
        const emoji = new Emoji( this._props.unicode_icon );
        return emoji.toHtml();
    }

    public get label(): string {
        return this._props.label;
    }

    public get target(): string {
        return this._props.target;
    }

    public get url(): string {
        return this._props.url;
    }

    public get params(): ExperienceParams {
        return new ExperienceParams( this._props.params );
    }

    public get city_unique(): string {
        return this._props.city_unique;
    }

    public get query(): string {
        return this._props.query;
    }

    public static createFromApi( data: any ): Array<Experience> {
        return data.map( ( props : any ) => new Experience({
            url: props.url,
            target: props.target,
            unicode_icon: props.unicode_icon,
            label: props.label,
            params: props.params,
            city_unique: props.city_unique,
            query: props.query
        } ) );
    }
}
