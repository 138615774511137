

































import Vue, { PropType } from "vue";
import SDCalendar from "../domain/SDCalendarInstance";
import CalendarDay from './days/index.vue';
import Day from "../domain/Day";
import ButtonTabs from "../../../../../../../frontend/components/ButtonTabs.vue";
import DateRangePlaceholder from "../../../../../../../frontend/components/DateRangePlaceholder.vue";

export default Vue.extend( {
  name       : 'CalendarDateRange',
  props      : {
    calendar : {
      type     : Object as PropType<SDCalendar>,
      required : true
    },
    type     : {
      type     : String,
      required : true
    }
  },
  components : {
    DateRangePlaceholder,
    ButtonTabs,
    CalendarDay
  },
  data() {
    return {}
  },
  methods : {
    clickDay( day: any ) {
      let params = day.params;

      if ( this.calendar.params.length !== 0 ) {
        params = Object.assign( {}, this.calendar.params, day.params );
      }

      if ( day.params ) {
        this.$emit( 'update', params );
      }
    },
    loadMore() {
      const params = Object.assign( this.calendar.params, this.calendar.more.params );
      this.$emit( 'loadMore', params );
    },
    startHoverFrom( day: Day ) {
      if ( this.calendar.isFromDateSelected && !this.calendar.isTillDateSelected ) {
        const dayList = this.calendar.dayList;

        if ( !day.hoverColors ) {
          return;
        }

        this.toggleHoverState( dayList, day );
        this.toggleToolTipState( dayList, day );
      }
    },
    endHoverFrom( day: any ) {
      day.tooltipVisible = false;
      this.calendar.dayList.forEach( ( dayFromList: Day ) => {
        dayFromList.hoverActive = false;

        if ( dayFromList.toolTip ) {
          dayFromList.tooltipVisible = true;
        }
      } );
    },
    toggleHoverState( dayList: Day[], currentDay: Day ) {
      for ( let i = 0; i < dayList.length - 1; i++ ) {
        const dayFromList = dayList[ i ];
        if ( dayFromList.hoverColors ) {
          dayFromList.hoverActive = true;
        }

        if ( dayFromList === currentDay ) {
          break;
        }
      }
    },
    toggleToolTipState( dayList: Day[], currentDay: Day ) {
      dayList.forEach( ( day: Day ) => day.tooltipVisible = false );
      currentDay.tooltipVisible = true;
    },
  }
} );
