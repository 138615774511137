import {ISearchResponse} from "../../../../../frontend/services/SearchService";
import {ISearchHistoryItem} from "./interfaces/ISearchHistoryItem";
import ValueObject from "../../../../../frontend/ValueObject";

export class SearchHistoryItem extends ValueObject<ISearchHistoryItem> implements  ISearchHistoryItem{

    public get action(): string {
        return this._props.action;
    }

    public get cityUnique(): string {
        return this._props.cityUnique;
    }

    public get icon(): string {
        return this._props.icon;
    }

    public get inputValue(): string {
        return this._props.inputValue;
    }

    public get match(): string {
        return this._props.match;
    }

    public get params(): { navigation_id: number } {
        return this._props.params;
    }

    public get query(): string {
        return this._props.query;
    }

    public get tag(): string {
        return this._props.tag;
    }

    public get target(): string {
        return this._props.target;
    }

    public get url(): { absolute: string; relative: string } {
        return this._props.url;
    }

    public static createFromApi( data: ISearchResponse['search_history'] ): Array<SearchHistoryItem> {

        return data.map(( props ) => new SearchHistoryItem({
            tag: props.tag,
            query: props.query,
            action: props.action,
            params: props.params,
            url: props.url,
            icon: props.icon,
            cityUnique: props.city_unique,
            match: props.match,
            target: props.target,
            inputValue: props.input_value
        }));
    }
}

