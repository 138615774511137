































import Vue, { PropType } from "vue";
import SDCalendar from "../../../submodule/Calendar/view/vue/components/SDCalendar.vue";
import TimeSlots from "../../../submodule/Calendar/view/vue/components/TimeSlots.vue";
import { DealsAvailability } from "../domain/DealsAvailability";
import SDCalendarInstance from "../../../submodule/Calendar/view/vue/domain/SDCalendarInstance";
import { triggerEvent } from "../../../../../frontend/helper/customEvents";
import { AmountDTO } from "../domain/DTO/AmountDTO";
import { ArrangementDTO } from "../domain/DTO/ArrangementDTO";

export default Vue.extend( {
  name       : 'DealsAvailability',
  components : {
    SDSelectBox          : () => import('../../../../../frontend/components/SDSelectBox.vue'),
    SDFakeSelectBox      : () => import('../../../../../frontend/components/SDFakeSelectBox.vue'),
    BottomDrawer         : () => import('../../../../../frontend/components/BottomDrawer.vue'),
    ListView             : () => import('../../../../../frontend/components/ListView.vue'),
    ItemCollapseView     : () => import('../../../../../frontend/components/ItemCollapseView.vue'),
    DateRangePlaceholder : () => import('./../../../../../frontend/components/DateRangePlaceholder.vue'),
    TimeSlots,
    SDCalendar
  },
  props      : {
    calendar         : {
      type : Object as PropType<SDCalendarInstance>
    },
    availability     : {
      type     : Object as PropType<DealsAvailability>,
      required : true
    },
    options          : {
      type     : Array as PropType<Array<DealsAvailability>>,
      required : true
    },
    timeSlots        : {
      type    : Array,
      default : Array as PropType<any[]>
    },
    arrangement      : {
      type     : Object as PropType<DealsAvailability>,
      required : false,
      default  : null
    },
    amount           : {
      type     : Number,
      required : true
    },
    additionalAmount : {
      type : Number || null
    },
    forceFlowActive  : {
      type     : Boolean,
      required : true
    }
  },
  data() {
    return {
      optionsVisible           : false,
      additionalOptionsVisible : false,
      drawerOrModalActive      : false,
      drawerOrModalVisible     : false,
      temporaryCalendarPayload : null
    }
  },
  methods  : {
    updateCalendar( payload: any ) {
      this.temporaryCalendarPayload = payload;
      this.$emit( 'updateCalendar', payload, this.amount );
    },
    loadMoreCalendar( payload: any ) {
      this.$emit( 'updateCalendar', payload, this.amount );
    },
    setArrangement( option: any ) {
      this.$emit( 'setArrangement', {
        data : option
      } );
      this.closeOptions();
    },
    openOptions() {
      this.closeOptions();
      this.optionsVisible = true;
    },
    closeOptions() {
      this.optionsVisible = false;
    }
  },
  computed : {
    activeArrangementKey(): number {
      if ( !this.arrangement ) {
        return null;
      }
      return this.arrangements.findIndex( ( arrangement: any ) => arrangement.unique === this.arrangement.unique )
    },
    shouldShowArrangementSelect(): any {
      return this.options?.length > 1;
    },
    isMobile(): boolean {
      return sdViewport.isMobile()
    },
    arrangements(): any {
      return this.options.map( ( option ) => ( {
            unique        : option.unique,
            title         : option.title,
            description   : option.description,
            value         : option.description,
            stats         : option.stats,
            fromPrice     : option.fromPrice,
            price         : option.price,
            isPurchasable : option.isPurchasable,
          }
      ) );

    },
  }
} );
