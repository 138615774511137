import {CurrencyDTO} from "../../module/Deals/view/vue/domain/dto/PricesDTO";
import {IPlanningDealResponse} from "../services/PlanningService";
import {INearbyFilterResponse} from "../services/FilterService";

export type SortListItem = {
    key: string;
    label: string;
    isSelected?: boolean;
}

export type SortListEmit = {
    toSelectedKey:string,
    fromSelectedKey:string
}

export type DistanceFilterItem = {
    key: string;
    label: string;
}
export type MappedDistanceFilterItem = {
    key: string;
    value: string
}
export type ReviewFilterItem = {
    key: number;
    label: string;
    isSelected?: boolean;
}
export type CategoryItem = {
    key: string;
    icon: string;
    color: string;
    label: string;
    isSelected?: boolean;
}

export type TagCloudItem = {
    label : string;
    name : string;
    colors : {active: TagCloudColor, default: TagCloudColor};
    type : TagCloudType;
    icon : TagCloudIcon;
    badge?: string;
}

export type TagCloudColor = {
    border: Color;
    text: Color;
    icon: Color;
}
export type Color = {
    red : number;
    green : number;
    blue : number;
    alpha : number;
    hex : string;
}
 export type TagCloudType = {
    name : string;
    params : {
        sortBy : string;
        searchConfiguration : any;
    }
 }

export type TagCloudIcon= {
    key : string;
    image : {
        default?: string;
        active?: string;
    }
    unicode?: string;
}

export type Badge = {
    label: string;
    color: string
}
export enum FilterModalType {
    Home = 'home',
    Nearby = 'nearby'
}
export type FilterCriteria = {
    persisted: boolean;
    sorting: string;
    categories: string;
    price_range: {
        min: number | null;
        max: number | null;
    }
    distance : {
        value: string;
        lat: number | null;
        lng: number | null;
    }
    rating: number
}

export type GetFilterCriteriaResponse = {
    badge: Badge | null;
    filters: FilterCriteria;
    execute_search_call: boolean
}

export type GetFilterResponse = {
    badge: Badge | null;
    result_count: number;
    sections: {
        categories: {
            options: CategoryItem[];
            select_all_key: string;
            selected: string | string[];
        };
        distance: {
            label: string;
            latitude: number;
            longitude: number;
            options: DistanceFilterItem[];
            selected: string
        };
        price_range: PriceRange;
        rating: {
            selected: number;
            options: ReviewFilterItem[];
        };
        sorting: {
            selected: string;
            options: SortListItem[];
        }
    }
}

type BaseFilterResponse = {
    action: FilterSearchActionType;
    unique: string;
    slug: string;
    badge?: Badge;
}

export type GetHomeFilterSearchResultByCityUniqueResponse = BaseFilterResponse & {
    deals: Array<IPlanningDealResponse>
}
export type GetNearbyFilterSearchResultByPointResponse = BaseFilterResponse & {
    deals: Array<INearbyFilterResponse>
}
export type PriceRange = {
    currency: CurrencyDTO;
    max_value: number;
    min_value: number;
    selected: {
        min: number | null;
        max: number | null;
    }
    step_size: number;
};

export enum FilterSearchActionType {
    Deals = 'deals',
    ChangeCity = 'change-city',
    CityPlanning = 'city-planning',
    NearbyGetDeals = 'deal-map/get-deals'
}

export enum FilterFlow {
    Filter = 'filter',
    Home = 'home',
    Deeplink = 'deeplink'
}

export type NearbySearchLocation = {
    lat?:string;
    long?:string;
    placeholder?:string;
    point?:string;
}

export type SearchFilterEmit = {
    filterQueryParams: string;
    inspirationTag: string;
    closeFilterCallback: Function;
    nearbySearchLocation?: NearbySearchLocation;
}
