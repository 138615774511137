import { APIService } from "./APIService";

export default class FavoriteService extends APIService {

    public static getFavoriteCampaignUniques(): Promise<Array<string>> {
        return this.get<Array<string>>(`/favorite-campaign-uniques/`, {
            headers : {
                "Content-Type" : "application/json",
                "x-requested-with": "XMLHttpRequest"
            }
        } );
    }

    public static addFavorite( unique: string ): Promise<any> {
        const bodyFormData = new FormData();
        bodyFormData.append( 'unique', unique );

        return this.post<any, any>('/favorite/add/', bodyFormData, {
            headers : {
                "x-requested-with": "XMLHttpRequest"
            }
        })
    }

    public static deleteFavorite( unique: string ): Promise<any> {
        return this.delete<any>(`/favorite/delete/${unique}/`, {
            headers : {
                "Content-Type" : "application/json",
                "x-requested-with": "XMLHttpRequest"
            }
        });
    }
}
