import ValueObject from "../../../../../../../frontend/ValueObject";

export interface ISingleDayCalenderParams {
    amount: number,
    selected?: string | null,
    date_shortcut: string | null
}

export default class SingleDayCalenderParams extends ValueObject<ISingleDayCalenderParams> {
    get amount() {
        return this._props.amount;
    }

    get selected() {
        return this._props.selected
    }

    get dateShortcut() {
        return this._props.date_shortcut;
    }

    public toJSON() {
        return {
            amount   : this.amount,
            selected : this.selected,
            date_shortcut : this.dateShortcut,
        }
    }

    public toSearchbar() {
        return {
            date : typeof this.selected !== 'undefined' ? this.selected : null,
            amount : this.amount,
            date_shortcut : this.dateShortcut,
        }
    }

    static fromDay( originalParams: any, dayParams: any ) : SingleDayCalenderParams {
        return new SingleDayCalenderParams( Object.assign( originalParams, dayParams ) )
    }

    static fromShortCutParams( originalParams: any, shortCutParams: any ) : SingleDayCalenderParams {
        return new SingleDayCalenderParams( Object.assign( originalParams, shortCutParams ) )
    }

    static fromSearchBar( data: any ) : SingleDayCalenderParams {
        return new SingleDayCalenderParams( data );
    }

    static fromApi( data: any ) : SingleDayCalenderParams {
        return new SingleDayCalenderParams( {
            amount   : data.amount,
            selected : data.selected,
            date_shortcut : data.date_shortcut
        } )
    }
}
