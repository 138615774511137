import { render, staticRenderFns } from "./DealList.vue?vue&type=template&id=389429b7&scoped=true&"
import script from "./DealList.vue?vue&type=script&lang=ts&"
export * from "./DealList.vue?vue&type=script&lang=ts&"
import style0 from "./DealList.vue?vue&type=style&index=0&id=389429b7&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "389429b7",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/code/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('389429b7')) {
      api.createRecord('389429b7', component.options)
    } else {
      api.reload('389429b7', component.options)
    }
    module.hot.accept("./DealList.vue?vue&type=template&id=389429b7&scoped=true&", function () {
      api.rerender('389429b7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "module/Deals/view/vue/components/lists/DealList.vue"
export default component.exports