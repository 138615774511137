






























import Vue, { PropType } from "vue";
import CitySelect from "./components/CitySelect.vue";
import TagSelect, { ITag } from "./components/TagSelect.vue";
import { ICity } from "./domain/City";
import NearbySearchBar from "./domain/NearbySearchBar";
import ModalSubmitButton from "./ModalSubmitButton.vue";
import TagCloudFilterService from "../../../../frontend/services/TagCloudFilterService";
import TagCloudFilter from "./domain/TagCloudFilter";

export default Vue.extend( {
  name  : 'NearbyModal',
  props : {
    defaultSearchBar   : {
      type     : Object as PropType<NearbySearchBar>,
      required : true
    },
    defaultLabels      : {
      type     : Object,
      required : true
    },
    active             : {
      type    : Boolean,
      default : false
    },
    gpsLabel           : {
      type     : String,
      required : true
    },
    selectedCategories : {
      type     : Array,
      required : true
    }
  },
  data(): any {
    return {
      searchBar : this.defaultSearchBar as NearbySearchBar,
      filters   : null as any,
      labels    : this.defaultLabels,
      selectedTag: null,
      originalSelectedCategories: this.selectedCategories
    }
  },
  async mounted() {
    await this.fetch();
  },
  components : {
    CitySelect,
    TagSelect,
    ModalSubmitButton
  },
  computed : {
    hasSelectedCategories(): boolean {
      return this.selectedCategories.length > 0;
    }
  },
  methods    : {
    async fetch() {
      const tagCloudFilterServiceResponse = await TagCloudFilterService.getFiltersForLocation( this.searchBar );
      this.filters = tagCloudFilterServiceResponse.map( ( item: any ) =>  {
        if( this.hasSelectedCategories ) {
          item.state = this.selectedCategories.includes(item.identifier) ? 'active' : 'default';
        } else {
          item.state = 'default'
          if(item.identifier === this.searchBar.nearbyIdentifier) {
            this.selectedTag = item;
            item.state = 'active';
          }
        }
        return TagCloudFilter.fromApi( item );
      });
    },
    closeSelf(): void {
      this.$emit( 'close' );
      this.$emit( 'category-changed', this.originalSelectedCategories );
    },
    setCity( city: ICity ) {
      city.unique = city.unique ?? null;
      city.location = city.location ?? city.locationLabel;

      this.labels.title = city.location ?? this.gpsLabel;

      this.searchBar.setLocation( city );
      this.fetch();
    },
    selectTag( selectedTag: ITag ) {
      this.searchBar.setNearbyView( selectedTag );
      this.searchBar.setNearbyLabel( selectedTag );
      this.searchBar.setNearbyIdentifier( selectedTag );
      this.selectedTag = selectedTag;
      this.filters.forEach((tag: ITag) => {
        tag.identifier === selectedTag.identifier ? tag.state = 'active' : tag.state = 'default';
      });
    },
    submit(): void {
      this.$emit( 'category-changed', [this.selectedTag.identifier] );
      if( this.selectedTag && this.selectedTag.view === 'tag' ) {
        vueTrigger( 'activate-after-nearby-inspirations', this.selectedTag.identifier );

        //prevent searchbar update because this component does it itself
        var evt = document.createEvent( 'CustomEvent' );
        evt.initCustomEvent( 'categorychange', false, false, {
          category : this.selectedTag.identifier,
          preventSyncNearbyFilter: true
        } );
        window.dispatchEvent( evt );
      }
      this.$emit( 'submit', this.searchBar );
    }
  }
} );
