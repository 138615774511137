var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fixed-info" }, [
    _vm.previewText
      ? _c("div", { staticClass: "preview-text" }, [
          _vm._v("\n    " + _vm._s(_vm.previewText) + "\n  "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "submit-btn",
        on: {
          click: function ($event) {
            return _vm.$emit("submit")
          },
        },
      },
      [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.type && _vm.type === "nearby"
                ? _vm.$t("17571.App_SearchInputSearchButtonNearby")
                : _vm.$t("12098.App_SearchInputSearchButton")
            ) +
            "\n  "
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }