import ValueObject from "../../../../../../frontend/ValueObject";
import { DealsAvailability } from "../DealsAvailability";
import { EmitDTO } from "./EmitDTO";

export class ArrangementDTO extends ValueObject<EmitDTO<DealsAvailability>> {
    get data() : DealsAvailability {
        return this._props.data;
    }
    get callback() : Function {
        return this._props.callback;
    }
    public static create( props: any ) {
        return new ArrangementDTO( {
            data   : props.data,
            callback : props.callback
        } );
    }
}