/**
 * Defined ViewPort and "cutoff" functions
 * Desktop Mode is 1056 px and wider
 * Tablet mode is 768 pixels and wider (till desktop mode)
 * Mobile mode is below 768 pixels
 */

export const sdDevice = {
    currentWidth      : null as any,
    newWidth          : null as any,
    updateTimer       : 0,
    intervalTime      : 150,
    breakpoints       : {
        'mobile'    : 768,
        'tablet'    : 1055,
        'desktop2k' : 2000
    },
    resizeHandlers    : [] as any,
    is2k              : function () {
        sdDevice.init();
        return sdDevice.currentWidth > sdDevice.breakpoints.desktop2k;
    },
    isDesktop         : function () {
        sdDevice.init();
        return sdDevice.currentWidth > sdDevice.breakpoints.tablet;
    },
    isTablet          : function () {
        sdDevice.init();
        return sdDevice.currentWidth <= sdDevice.breakpoints.tablet && sdDevice.currentWidth >= sdDevice.breakpoints.mobile;
    },
    isMobile          : function () {
        sdDevice.init();
        return sdDevice.currentWidth < sdDevice.breakpoints.mobile;
    },
    init              : function () {
        if ( sdDevice.currentWidth === null ) {
            sdDevice.currentWidth = window.screen.width;
        }
    }
};