



















import Vue, {PropType} from "vue";
import {DistanceFilterItem, MappedDistanceFilterItem, SortListItem} from "../../types/FilterTypes";
import SDFakeSelectBox from "../SDFakeSelectBox.vue";
import SDSelectBox from "../SDSelectBox.vue";

interface IData {
  parentBottomPosition:number|null;
}

interface IMethods {
  decodeEntities: (encodedString: string) => string
  openDistanceOptions: () => void;
  closeDistanceOptions: () => void;
  setDistance: (distance: any) => void;
  clickedOnCurrentLocation: (event: MouseEvent) => void;
}

interface IProps {
  isDistanceOptionsOpen: boolean;
  selectedDistanceKey: string;
  distanceOptions: MappedDistanceFilterItem[];
  currentLocationText: string;
}
export default Vue.extend<IData, IMethods, {}, IProps>( {
  name  : 'DistanceFilter',
  components: {
    SDFakeSelectBox,
    SDSelectBox
  },
  props : {
    selectedDistanceKey : {
      type: String,
      required : true
    },
    distanceOptions : {
      type: Array as PropType<IProps['distanceOptions']>,
      required : true
    },
    currentLocationText : {
      type: String,
      required : false
    },
    isDistanceOptionsOpen : {
      type: Boolean,
      required : true,
      default: false
    }
  },
  data() {
    return {
      selectedDistance:this.$props.selectedValue,
      isDistanceOptionsActive: false,
      parentBottomPosition: 0
    }
  },
  methods: {
    decodeEntities(encodedString: string): string {
      const textArea = document.createElement('textarea');
      textArea.innerHTML = encodedString;
      return textArea.value;
    },
    openDistanceOptions() {
      this.$emit('toggle-distance-options', true)
    },
    closeDistanceOptions() {
      this.$emit('toggle-distance-options', false)
    },
    setDistance(distance: any ) {
      this.$emit('on-distance-changed', distance);
    },
    clickedOnCurrentLocation(clickEvent: MouseEvent) {
      this.$emit('on-current-location-clicked', clickEvent);
    },
  },
  mounted() {
    this.parentBottomPosition = document.getElementsByClassName('drawer-content')[0].getBoundingClientRect().bottom;
  }
} );
