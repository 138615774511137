var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tweakwise-searchbar",
      attrs: { id: "tweakwise-searchbar" },
    },
    [
      _c("SearchInput", {
        ref: "searchbar",
        attrs: {
          invalid: _vm.isInputInvalid,
          placeholder: _vm.placeholder,
          value: _vm.value,
          autocomplete: "tweakwise-searchbar",
        },
        on: {
          submit: _vm.handleSubmit,
          onClear: _vm.handleSearchBarClean,
          onChange: _vm.handleSearchBarChange,
          focus: _vm.handleFocus,
          blur: _vm.handleBlur,
        },
        nativeOn: {
          click: function ($event) {
            return _vm.handleSearchBarClick.apply(null, arguments)
          },
        },
      }),
      _vm._v(" "),
      _vm.isInputFocussed && _vm.isValidQuery && _vm.searchDealsConfiguration
        ? _c(
            "div",
            [
              _c("AutocompleteSuggestionList", {
                attrs: {
                  "search-query": _vm.query,
                  suggestions: _vm.autoComplete,
                  "tag-cloud-category": _vm.tagCloudCategory,
                  "max-display-count":
                    _vm.searchDealsConfiguration.numSuggestionsMatches,
                },
                on: { click: _vm.handleAutocompleteItemClick },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isInputFocussed && !_vm.isValidQuery
        ? _c(
            "div",
            { staticClass: "tweakwise-suggestions-list" },
            [
              _vm.searchHistory
                ? _c("SearchHistoryList", {
                    style: { order: _vm.searchHistory.index },
                    attrs: {
                      id: "tweakwise-desktop-historylist",
                      "search-history": _vm.searchHistory,
                    },
                    on: { click: _vm.handleSearchHistoryItemClick },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.popularPhrase
                ? _c("PopularPhraseList", {
                    style: { order: _vm.popularPhrase.index },
                    attrs: { "popular-phrase": _vm.popularPhrase },
                    on: { itemClick: _vm.handlePopularPhraseItemClick },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.experiences
                ? _c("ExperienceList", {
                    class: {
                      "no-history": !_vm.hasSearchHistory,
                    },
                    style: { order: _vm.experiences.index },
                    attrs: { experiences: _vm.experiences },
                    on: { click: _vm.handleExperienceItemClick },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isEmptyState
        ? _c(
            "div",
            [
              _c("Message", {
                attrs: {
                  "image-src": "/img/no-deals-found.svg",
                  label: _vm
                    .$t("17307.App_SearchTweakwiseEmptyStateWithInput")
                    .replace(":search", _vm.query),
                },
              }),
              _vm._v(" "),
              _vm.experiences
                ? _c("ExperienceList", {
                    class: {
                      "no-history": !_vm.hasSearchHistory,
                    },
                    attrs: { experiences: _vm.experiences },
                    on: { click: _vm.handleExperienceItemClick },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      (_vm.isValidQuery && _vm.isSearchStateResults) ||
      _vm.isSearchStateSearching
        ? _c("DealList", {
            attrs: { deals: _vm.deals, "is-deal-detail": _vm.isDealDetail },
            on: { fetchMore: _vm.handleFetchMore },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isValidQuery && _vm.isAutocompleteSearchStateResults
        ? _c("DealList", {
            attrs: {
              deals: _vm.autoCompleteDeals,
              "is-deal-detail": _vm.isDealDetail,
            },
            on: { fetchMore: _vm.handleFetchMoreAutoCompleteDeals },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }