import ValueObject from "../../../../../frontend/ValueObject";
import { IPopularPhrase } from "./interfaces/IPopularPhrase";
import { PopularPhraseItem } from "./PopularPhraseItem";

export class PopularPhrase extends ValueObject<IPopularPhrase> implements  IPopularPhrase {

    public get index(): number {
        return this._props.index;
    }
    public get items(): Array<PopularPhraseItem> {
        return this._props.items;
    }
    public get title(): string {
        return this._props.title;
    }

    public static createFromApi( response: any ): PopularPhrase {
        return new PopularPhrase({
            index: response.index,
            items: PopularPhraseItem.createFromApi( response.items ),
            title: response.title
        });
    }
}
