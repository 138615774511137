















import Vue from "vue";
import {triggerEvent} from "../../../../../frontend/helper/customEvents";
import {chunk} from "@s-libs/micro-dash";
import DealArrangement from "./DealArrangement.vue";

export default Vue.extend({
    name: 'DealArrangementsGrid',
    components: {
        DealArrangement
    },
    props: {
        alerts: {
            type: Object,
            required: true
        },
        arrangements: {
            type: Array,
            required: true
        },
        current: {
            type: Object,
            default: (): any => null
        },
        dealType: {
            type: String,
            required: true
        },
        displayType: {
            type: String,
            default: 'normal'
        }
    },
    methods: {
        setArrangement(arrangement: any) {
            if (!arrangement.isPurchasable) {
                triggerEvent('dealDetail-toggleAlert', this.alerts.sold_out);
                return;
            }
            this.$emit('select', arrangement)
        }
    },
    computed: {
        itemsToGrid(): any {
            return chunk(this.arrangements, Math.round(this.arrangements.length / 2))
        },
        isDisplayTypeTitle(): boolean {
            return this.displayType === 'title';
        }
    }
});
