














import Vue, { PropType } from 'vue';
import { PopularPhrase } from "../../domain/PopularPhrase";
import PopularPhraseItem from "../items/PopularPhraseItem.vue";
import { PopularPhraseItem as PopularPhraseItemModel } from "../../domain/PopularPhraseItem";

export default Vue.extend({
  name: 'PopularPhraseList',
  components : { PopularPhraseItem },
  props : {
    popularPhrase: {
      type: Object as PropType<PopularPhrase>
    }
  },
  methods: {
    handleItemClick( item : PopularPhraseItemModel ) {
      this.$emit( 'itemClick', item );
    }
  }
})
