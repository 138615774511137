

















import Vue, {PropType} from "vue";
import { scrollIntoView } from "seamless-scroll-polyfill";
import DateShortcut from "../../module/Availability/submodule/Calendar/view/vue/domain/DateShortcut";

export default Vue.extend( {
  name     : 'ButtonTabs',
  props    : {
    activeUnique : {
      type    : String,
      default : null
    },
    buttons      : {
      type     : Array as PropType<DateShortcut[]>,
      required : true
    }
  },
  mounted() {
    if ( typeof window === 'undefined' ) {
      return;
    }

    const activeButton = this.buttons.find( obj => obj.unique === this.activeUnique );
    if( activeButton == null ) {
      return;
    }
    const activeButtonHtmlElement = this.$refs['dateShortcutButtons-' + activeButton.unique ] as HTMLElement[];

    if( activeButtonHtmlElement && activeButtonHtmlElement.length > 0) {
      scrollIntoView(activeButtonHtmlElement[0], {inline: "center"});
    }
  },
  computed : {
    isMobile(): boolean {
      return sdViewport.isMobile()
    },
  },
  methods  : {
    clickButton( e: any, button: DateShortcut ) {
      this.$emit( 'buttonClick', button.params )

      if ( this.isMobile ) {
        this.horizontalScrollAnimation( e )
      }
    },
    horizontalScrollAnimation( e: any ): any {
      if ( typeof window !== 'undefined' ) {
        scrollIntoView( (e.target as HTMLElement), { behavior : "smooth", inline : "center" } );
      }
    }
  },
} )
