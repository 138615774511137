



























import Vue from "vue";

export default Vue.extend( {
  props   : {
    items : {
      type     : Array,
      required : true
    }
  },
  methods : {
    select( item: any ) {
      this.$emit( 'select', item );
    }
  }
} )
