import ValueObject from "../../../../../../frontend/ValueObject";
import { EmitDTO } from "./EmitDTO";

interface AmountOption {
    amountOptionKey: number
}

export class AmountDTO extends ValueObject<EmitDTO<AmountOption>> {
    get option() : AmountOption {
        return this._props.data;
    }
    get callback() : any {
        return this._props.callback;
    }
    public static create( option: AmountOption, callback : any ) {
        return new AmountDTO( {
            data   : option,
            callback : callback
        } );
    }
}