var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "dealcard-search-result",
      attrs: { href: _vm.deal.link },
      on: { click: _vm.handleDealClick },
    },
    [
      _c("div", {
        staticClass: "image",
        style: {
          backgroundImage: "url(" + _vm.deal.imageUrl + ")",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "details" }, [
        _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.deal.title))]),
        _vm._v(" "),
        _c("div", { staticClass: "companyName" }, [
          _vm._v(_vm._s(_vm.deal.company)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cityName" }, [_vm._v(_vm._s(_vm.deal.city))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "prices" }, [
        _vm.deal.prices.hasFromPrice()
          ? _c("span", {
              staticClass: "oldprice",
              domProps: {
                innerHTML: _vm._s(_vm.deal.prices.fromPrice.toHtml(true)),
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.deal.prices.showLabelInsteadOfPrice()
          ? _c("span", {
              staticClass: "price",
              domProps: { innerHTML: _vm._s(_vm.deal.prices.priceLabel) },
            })
          : _vm.deal.prices.hasPrice()
          ? _c("span", {
              staticClass: "price",
              domProps: {
                innerHTML: _vm._s(_vm.deal.prices.price.toHtml(true)),
              },
            })
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }