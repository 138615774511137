var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "deal-card",
      class: {
        "sold-out": this.isSoldOut,
      },
      attrs: { href: _vm.deal.link, target: _vm.target },
      on: { click: _vm.handleDealClick },
    },
    [
      _c("div", { staticClass: "image-container" }, [
        _vm.deal.discount
          ? _c("div", { staticClass: "card-ribbon" }, [
              _vm._v("\n      " + _vm._s(_vm.deal.discount) + "\n    "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "stickers" }, [
          _vm.isNewToday
            ? _c("div", { staticClass: "sticker new-today" }, [
                _vm._v("New Today"),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isSoldOut
            ? _c("div", { staticClass: "sticker sold-out" }, [
                _vm._v("Sold Out"),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "image" }, [
          _c("img", {
            staticClass: "sd-image-full img-responsive",
            attrs: {
              width: "630",
              height: "378",
              loading: _vm.loadStrategy,
              title: _vm.deal.title,
              alt: _vm.deal.title,
              src: _vm.deal.imageUrl,
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "fav-icon",
            class: {
              active: _vm.isFavorite,
              loading: _vm.isFavoriteLoading,
            },
            on: { click: _vm.handleFavouriteButtonClick },
          },
          [
            _c("i", { staticClass: "material-icons" }, [
              _vm._v(_vm._s(_vm.isFavorite ? "favorite" : "favorite_border")),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm.deal.hexagon
          ? _c("div", { staticClass: "hexagon" }, [
              _c(
                "i",
                {
                  staticClass: "sd-icons hexagon-background notranslate",
                  style: { color: _vm.deal.hexagon.color },
                },
                [_vm._v("hexagon")]
              ),
              _vm._v(" "),
              _c(
                "i",
                {
                  staticClass: "sd-icons hexagon-icon notranslate",
                  style: { color: _vm.deal.hexagon.color },
                },
                [_vm._v(_vm._s(_vm.deal.hexagon.name))]
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "information-container" }, [
        _c("div", { staticClass: "title-container" }, [
          _c("h4", [_vm._v(_vm._s(_vm.deal.title))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "company" }, [
          _c("div", [
            _c("span", [_vm._v(_vm._s(_vm.deal.company))]),
            _vm._v(" "),
            _vm.deal.review && _vm.deal.review !== "0"
              ? _c("div", { staticClass: "text-right review-color" }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.deal.review) + "\n          "
                  ),
                  _c("i", { staticClass: "material-icons-round notranslate" }, [
                    _vm._v("star"),
                  ]),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("span", [_vm._v(_vm._s(_vm.deal.location))]),
            _vm._v(" "),
            _vm.deal.travelDistance
              ? _c("div", { staticClass: "text-right travel-color" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.deal.travelDistance.duration) +
                      "\n          "
                  ),
                  _c("i", { staticClass: "material-icons-round notranslate" }, [
                    _vm._v(_vm._s(_vm.deal.travelDistance.icon)),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "stats-container" }, [
        _c("div", { staticClass: "price-col pull-right" }, [
          _vm.deal.prices.hasDiscountPrice()
            ? _c("span", {
                staticClass: "old-price pr-1 pl-2",
                domProps: {
                  innerHTML: _vm._s(_vm.deal.prices.fromPrice.toHtml()),
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.deal.prices.showLabelInsteadOfPrice()
            ? _c("span", {
                staticClass: "price",
                domProps: { innerHTML: _vm._s(_vm.deal.prices.priceLabel) },
              })
            : _vm.deal.prices.hasPrice()
            ? _c("span", {
                staticClass: "price",
                domProps: { innerHTML: _vm._s(_vm.deal.prices.price.toHtml()) },
              })
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "sold-text text-color-stats" }, [
          _vm._v("\n      " + _vm._s(_vm.deal.soldLabel) + "\n    "),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }