











import Vue from "vue";

export default Vue.extend( {
  name : 'ModalSubmitButton',
  props: {
    type: {
      type: String,
      default: undefined
    },
    previewText : {
      type: String
    }
  }
} );
