import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

export interface WrappedResponse<T> {
    data: T;
    status: number;
    statusText: string;
    headers : Object
}

export interface APIServiceRequestConfig<P> extends Omit<AxiosRequestConfig, 'params'> {
    params: Partial<P>;
}

export class APIService {
    protected static client: AxiosInstance = axios.create( {
        baseURL : ''
    } );

    protected static getHeaders( headers: any = {} ) {
        const baseHeaders = {} as any;
        const newRelicTraceTag = document.querySelector( 'meta[name="custom_newrelic_trace_id"]' ) as HTMLMetaElement

        if ( newRelicTraceTag ) {
            baseHeaders[ 'Custom-Newrelic-Parent-Trace-Id' ] = newRelicTraceTag.content;
        }

        return Object.assign( baseHeaders, headers );
    }

    public static get<T>( route: string, config: AxiosRequestConfig = {} ): Promise<T> {
        return new Promise( ( resolve, reject ) => {
            this.client.get<T>( route, config )
                .then( ( response: any ) => {
                    resolve( response.data )
                } )
                .catch( ( err: any ) => {
                    if (!axios.isCancel(err)) {
                        reject( err );
                    }
                } );
        } );
    };

    public static post<D, T>( route: string, data?: D, config: AxiosRequestConfig = {} ): Promise<T> {
        return new Promise( ( resolve, reject ) => {
            this.client.post<D, AxiosResponse<T>>( route, data, config )
                .then( ( response: any ) => {
                    resolve( response.data );
                } )
                .catch( ( err: any ) => {
                    reject( err );
                } )
        } )
    };

    public static delete<T>( route: string, config: AxiosRequestConfig = {} ): Promise<T> {
        return new Promise( ( resolve, reject ) => {
            this.client.delete<T>( route, config )
                .then( ( response: any ) => {
                    resolve( response.data )
                } )
                .catch( ( err: any ) => {
                    reject( err );
                } );
        } );
    };
}
