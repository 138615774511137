












import Vue, { PropType } from "vue";
import AutocompleteSuggestionItem from "../items/AutocompleteSuggestionItem.vue";
import { TagCloudCategory } from "../../domain/TagCloudCategory";
import { SearchPredictionSuggestion } from "../../../../../../frontend/services/SearchService";

const SUPPORTED_ACTIONS = ['search', 'navigate'];

export default Vue.extend( {
  name       : 'AutocompleteSuggestionList',
  components : {
    AutocompleteSuggestionItem
  },
  props      : {
    suggestions      : {
      type     : Array as PropType<Array<SearchPredictionSuggestion>>,
      required : true
    },
    searchQuery      : {
      type     : String,
      required : true
    }
  },
  computed: {
    suggestionsToRender(): Array<SearchPredictionSuggestion> {
      return this.suggestions.filter( suggestion => {
        return  SUPPORTED_ACTIONS.includes( suggestion.action );
      })
    }
  },
  methods    : {
    handleAutocompleteItemClick( suggestion: SearchPredictionSuggestion ): void {
      this.$emit( 'click', suggestion );
    },
  },
} )
