import { shallowEqual } from "shallow-equal-object";

interface IValueObjectProps {
    [ index: string ]: any;
}

export default abstract class ValueObject<T extends IValueObjectProps> {
    readonly _props: T;

    equals( vo?: ValueObject<T> ): boolean {
        if ( vo === null || vo === undefined ) {
            return false;
        }

        if ( vo._props === undefined ) {
            return false;
        }

        return shallowEqual( this._props, vo._props );
    }

    protected constructor( props: T ) {
        this._props = props;
    }
}
