var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sd-fake-select" }, [
    _c("div", { staticClass: "box-layer" }, [
      _c(
        "div",
        { staticClass: "select-trigger", on: { click: _vm.triggerSelf } },
        [
          _c("div", { staticClass: "input-label" }, [
            _vm._v(_vm._s(_vm.label)),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "input-placeholder", class: { empty: !_vm.value } },
            [_vm._v("\n        " + _vm._s(_vm.valueOrPlaceholder) + "\n      ")]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }