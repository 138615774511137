import ValueObject from "../../../../../../../frontend/ValueObject";
import { Price } from "../../../../../../Deals/view/vue/domain/Price";

export interface ICalendarDay {
    colors: any,
    label: string,
    params: any,
    template: string,
    properties: any,
    hover_active: boolean,
    tooltip_visible: boolean,
    hover_type: boolean
}

export default class Day extends ValueObject<ICalendarDay> {

    set hoverActive( state: boolean ) {
        this._props.hover_active = state;
    }

    set tooltipVisible( state: boolean ) {
        this._props.tooltip_visible = state;
    }

    get colors() {
        return this._props.colors;
    }

    get template() {
        return this._props.template;
    }

    get label() {
        return this._props.label;
    }

    get params() {
        return this._props.params;
    }

    get properties() {
        return this._props.properties;
    }

    get dayPrice() {
        return this._props.properties.price;
    }

    get toolTip(): any {
        return this._props.properties.tooltip;
    }

    get hoverToolTip(): any {
        return this._props.properties.hover_tooltip;
    }

    get hoverColors(): any {
        return this._props.properties.hover_colors;
    }

    get isHoverActive(): boolean {
        return this._props.hover_active;
    }

    get tooltipVisible(): boolean {
        return this._props.tooltip_visible;
    }

    get someToolTip(): any {
        return this.toolTip ?? this.hoverToolTip
    }

    get additionalClassList(): string {
        let classList = '';

        if ( this.tooltipVisible ) {
            classList += 'bg-brand-500'
        } else if ( this.colors.background ) {
            classList += 'bg-' + this.colors.background;
        }

        if ( this.colors.triangle_right ) {
            classList += ' triangle triangle-' + this.colors.triangle_right
        }

        if ( this.colors.border ) {
            classList += ' border border-' + this.colors.border
        }

        return classList;
    }

    public static create( props: any ) {
        return new Day( Object.assign( props, {
                properties      : Object.assign( props.properties, {
                    price         : props.properties.price ? Price.create( {
                        amount   : props.properties.price.amount / 100,
                        currency : props.properties.price.currency,
                    } ) : null,
                    tooltip       : props.properties.tooltip ?? null,
                    hover_tooltip : props.properties.hover_tooltip ?? null,
                    hover_colors  : props.properties.hover_colors ?? null,
                    hover_type    : props.properties.hover_type ?? null
                } ),
                hover_active    : false,
                tooltip_visible : !!props.properties.tooltip?.check_in
            } )
        )
    }
}
