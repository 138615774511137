var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "message-wrap",
      class:
        "message-" + (_vm.sent_by.is_current_user ? "consumer" : "company"),
    },
    [
      _c(
        "div",
        {
          staticClass: "image-wrap",
          class: { "hide-image": !_vm.show_avatar },
        },
        [
          _vm.sent_by.avatar
            ? [
                _c("img", {
                  staticClass: "account-avatar",
                  attrs: { src: _vm.sent_by.avatar, alt: "Social Deal" },
                }),
              ]
            : _vm.sent_by.initial
            ? [
                _c("div", { staticClass: "account-initial" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.sent_by.initial) + "\n      "
                  ),
                ]),
              ]
            : [_vm._m(0)],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "message" },
        [
          _vm.type === _vm.MessageType.PLAINTEXT
            ? [
                _c("div", {
                  staticClass: "text",
                  domProps: { innerHTML: _vm._s(_vm.formattedText) },
                }),
              ]
            : [
                _c(
                  "a",
                  {
                    staticClass: "preview",
                    attrs: { href: _vm.content.href, target: "_blank" },
                  },
                  [
                    _c("img", {
                      staticClass: "icon",
                      attrs: {
                        src: _vm.content.preview.icon,
                        alt: _vm.content.preview.header,
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "preview-text" }, [
                      _c("div", { staticClass: "header" }, [
                        _vm._v(_vm._s(_vm.content.preview.header)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "description" }, [
                        _vm._v(_vm._s(_vm.content.preview.description)),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", {
                  staticClass: "text",
                  domProps: { innerHTML: _vm._s(_vm.formattedText) },
                }),
              ],
          _vm._v(" "),
          _c("span", { staticClass: "text-balloon" }),
          _vm._v(" "),
          _c("div", { staticClass: "message-time" }, [
            _vm._v("\n      " + _vm._s(_vm.presentation_time) + "\n    "),
          ]),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "account-icon" }, [
      _c("i", { staticClass: "material-icons" }, [_vm._v("account_circle")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }