import {APIService} from "./APIService";
import ValueObject from "../ValueObject";


interface IDeeplinkResponse {
    link: string;
    params: {
        [key: string]: string
    };
    raw_link: string;
    verbose: unknown;
    view: string;
}

class DeeplinkResponse extends ValueObject<IDeeplinkResponse> implements IDeeplinkResponse{

    public get link(): string {
        return this._props.link;
    }

    public get params(): { [p: string]: any } {
        return this._props.params;
    }

    public get raw_link(): string {
        return this._props.raw_link;
    }

    public get verbose(): unknown {
        return this._props.verbose;
    }

    public get view(): string {
        return this._props.view;
    }

    public static createFromApi( deeplinkResponse: IDeeplinkResponse ): DeeplinkResponse {
        return new DeeplinkResponse( deeplinkResponse );
    }
}

export default class DeeplinkService extends APIService {

    public static async getDeeplink( link: string, initialResponse?: any ): Promise<DeeplinkResponse> {
        const formData = new FormData();
        formData.append( 'link', link );

        if(initialResponse) {
            return DeeplinkResponse.createFromApi( initialResponse );
        }

        const response = await this.post<FormData, IDeeplinkResponse>( `/proxy/deeplink/`, formData, {
            headers : {
                "Content-Type" : "application/json",
            }
        } );
        return DeeplinkResponse.createFromApi( response );
    }
}
