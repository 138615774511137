const getUrl = (): URL => {
    return new URL( window.location.href );
}

export const getUrlQueryParam = ( key: string ): string => {
    const url = getUrl();
    return url.searchParams.get( key );
}

export const setUrlQueryParam = ( key: string, value: string, replace: boolean = false ): void => {
    const url = getUrl();
    url.searchParams.set(key, value);

    if ( replace === true ) {
        window.history.replaceState(null, document.title, url.toString() );
        return;
    }

    window.history.pushState( null, document.title, url.toString() );
}

export const hasUrlQueryParam = ( key: string ): boolean => {
    const url = getUrl();
    return url.searchParams.has( key );
}

export const removeUrlQueryParam = ( key: string, replace : boolean = false ): void => {
    const url = getUrl();
    url.searchParams.delete( key );

    if ( replace === true ) {
        window.history.replaceState( null,document.title, url.toString() );
        return;
    }

    window.history.pushState( null, document.title, url.toString() );
}

export const removeUrlQueryParams = ( ...keys: Array<string> ) : void => {
    const url = getUrl();
    keys.forEach( ( key ) => {
        url.searchParams.delete( key );
    });

    window.history.pushState( null, document.title, url.toString() );
}
