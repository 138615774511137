



















import Vue, {PropType} from "vue";
import { Deal } from "../../domain/Deal";

export default Vue.extend({
  name: 'AutocompleteDealItem',
  props: {
    deal: {
      type: Object as PropType<Deal>,
      required: true
    }
  },
  methods: {
    handleDealClick() {
      this.$emit('loading');
    }
  }
})
