var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "time-slots", class: "justify-content-" + _vm.justify },
    _vm._l(_vm.items, function (slot, slotKey) {
      return _c(
        "div",
        {
          key: slotKey,
          staticClass: "slot",
          on: {
            click: function ($event) {
              return _vm.$emit("select", slot.value)
            },
          },
        },
        [_vm._v("\n    " + _vm._s(slot.label) + "\n  ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }