











import Vue from "vue";
import {flows} from "../../../Availability/view/vue/components/HotelsAvailabilityWrapper.vue";

export default Vue.extend({
    name: 'DealArrangements',
    components: {
        DealArrangementsList: () => import('./components/DealArrangementsList.vue'),
        DealArrangementsGrid: () => import('./components/DealArrangementsGrid.vue'),
    },
    props: {
        alerts: {
            type: Object,
            required: true
        },
        arrangements: {
            type: Array,
            required: true
        },
        current: {
            type: Object,
            default: (): any => null
        },
        dealType: {
            type: String,
            required: true
        },
        displayType: {
            type: String,
            default: 'normal',
        }
    },
    computed: {
        isFlexFlow(): boolean {
            return this.dealType === flows.FLEX;
        },
        getArrangementView() : string {
            if( this.arrangements.length > 3 && this.isFlexFlow ) {
               return 'DealArrangementsGrid';
            }
            return 'DealArrangementsList';
        }
    }
});
