var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-bar-modal", attrs: { id: "search-bar-modal" } },
    [
      _c("div", { staticClass: "modal-heading" }, [
        _c("h3", [_vm._v(_vm._s(_vm.$t("12010.App_SearchDataScreenTitle")))]),
        _vm._v(" "),
        _c(
          "i",
          {
            staticClass: "material-icons close-icon",
            on: { click: _vm.closeSelf },
          },
          [_vm._v(" close ")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal-padding" },
        [
          _c("CitySelect", {
            attrs: { labels: _vm.labels, value: _vm.searchBar.location },
            on: { select: _vm.setCity },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "amount-wrapper" },
            [
              _c("AmountSelect", {
                attrs: {
                  options: _vm.searchBar.peopleOptions,
                  value: _vm.searchBar.numPeople,
                },
                on: { change: _vm.setAmount },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.calendar
        ? _c("CalendarSingleDay", {
            attrs: {
              calendar: _vm.calendar,
              type: _vm.deprecatedCalendar
                ? "lmd-nearby"
                : "search-bar-restaurants",
            },
            on: {
              update: _vm.updateCalendar,
              updateAndSubmit: _vm.updateCalendarAndSubmit,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.calendarLoading
        ? _c("Loader", { attrs: { type: "fixed" } })
        : _vm._e(),
      _vm._v(" "),
      _c("ModalSubmitButton", { on: { submit: _vm.submit } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }