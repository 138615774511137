







































import Vue, {PropType} from "vue";
import {MessageLink, MessageText, MessageType, User} from "../../../../../frontend/types/InboxTypes";

export default Vue.extend({
  name: 'Message',
  props: {
    id: {
      type: String,
      required: true
    },
    type: {
      type: String as PropType<MessageType>,
      required: true
    },
    sent_by: {
      type: Object as PropType<User>,
      required: true
    },
    sent_on: {
      type: String,
      required: true
    },
    content: {
      type: Object as PropType<MessageText | MessageLink>,
      required: true
    },
    show_avatar: {
      type: Boolean,
      required: true
    },
    presentation_time: {
      type: String,
      required: true
    }
  },
  computed: {
    MessageType(): any {
      return MessageType;
    },
    formattedText():string {
      let text = this.content.text;

      //replaces for HTML sanitization
      text = text
          .replace(/&/g, "&amp;")
          .replace(/</g, "&lt;")
          .replace(/>/g, "&gt;")
          .replace(/"/g, "&quot;")
          .replace(/'/g, "&#039;")
      // prepends if necessary the link with https://
      text = text.replace( /(((https?:\/\/)|(www\.))[^\s]+)/g, function(url, x, startsWith) {
          const href = (startsWith === 'www.') ? 'https://' + url : url;
          return '<a href="'+ href +'" target="_blank">'+ url +'</a>';
      } );

      //replace newline char with break tags
      text = text.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />');

      return text;
    }
  }
})
