


































import Vue from "vue";
import CalendarService from "../../../../frontend/services/CalendarService";
import { triggerEvent } from "../../../../frontend/helper/customEvents";
import ButtonTabs from "../../../../frontend/components/ButtonTabs.vue";

export default Vue.extend( {
  components : {
    ButtonTabs,
    DateRangePlaceholder : () => import('../../../../frontend/components/DateRangePlaceholder.vue'),
    SDCalendar           : () => import('../../../Availability/submodule/Calendar/view/vue/components/SDCalendar.vue')
  },
  data() {
    return {
      calendar : null
    }
  },
  props : {
    flow       : {
      type     : String,
      required : true
    },
    cityUnique : {
      type     : String,
      required : true
    },
    amount     : {
      type     : Number,
      required : true
    },
    params     : {
      type     : Object,
      required : true
    }
  },
  mounted() {
    delete this.params[ 'amount' ];
    this.getSearchBarCalendar( this.params );
  },
  methods : {
    async getSearchBarCalendar( params: any ) {
      this.calendar = await CalendarService.getSearchBarCalendar(
          this.flow,
          this.amount,
          this.cityUnique,
          params
      );
    },
    async updateCalendar( params: any, forceUpdate: boolean = false ) {
      await this.getSearchBarCalendar( Object.assign( {}, this.calendar.params, params ) );

      if (
          ( this.calendar.isRangeCalendar && this.calendar.isRangeComplete
          )
          || forceUpdate
          || this.calendar.isSingleDateCalendar
      ) {
        triggerEvent( 'searchBar-submitSearchBarFromView', {
          params : this.calendar.toSearchBar,
          element: this
        } );
      }

    },
    handleNoPreferenceClick() {
      if( this.calendar.unselect && this.calendar.unselect.params ) {
        this.updateCalendar(this.calendar.unselect.params, true);
        return;
      }

      this.closeSelf();
    },
    handleShortcutClick( params: any ) {
      this.updateCalendar( params, true )
    },
    handleDateRangePlaceholderClick() {
      triggerEvent( 'availability-scrollToElement', '#calendar-wrapper' );
    },
    closeSelf() {
      triggerEvent( 'searchBar-closeSearchBarCalendar', {
        element: this
      } );
    },

    async handleLoadMoreEvent( params: any ) {
      await this.getSearchBarCalendar( Object.assign( this.calendar.params, params ) );
    },
  }
} );
