




































import Vue from "vue";

export default Vue.extend( {
  components : {
    Loader : () => import('./Loader.vue'),
  },
  props      : {
    drawerTitle : {
      type     : String,
      required : true
    },
    headerActionTitle : {
      type     : String,
      required : false
    },
    visible     : {
      type     : Boolean,
      required : true
    },
    isInModal   : {
      type    : Boolean,
      default : false
    },
    isLoading   : {
      type    : Boolean,
      default : false
    },
    isLarge   : {
      type    : Boolean,
      default : false
    }
  },
  methods    : {
    select() {
      this.$emit( 'select' );
    },
    closeSelf(): any {
      return this.$emit( 'closeSelf' );
    },
    handleHeaderActionClick(): any {
      return this.$emit( 'header-action-click' );
    },
    scrollOrCloseSelf( event: any ): any {
      if ( !this.isDesktop ) {
        return this.$emit( 'closeSelf' );
      }

      (this.$refs.contentScroll as HTMLElement).scrollTop += event.deltaY;
    },
  },
  mounted() {
    if ( this.isDesktop && this.visible && this.isInModal ) {
      document.body.classList.add( 'modal-open' );
    }
  },
  beforeDestroy() {
    const isAnyModalOpen = document.getElementsByClassName( 'modal-show' ).length > 0;
    if ( this.isDesktop && this.visible && this.isInModal && !isAnyModalOpen ) {
      document.body.classList.remove( 'modal-open' );
    }
  },
  computed : {
    isDesktop(): any {
      return sdViewport.isDesktop()
    },
    hasFooter(): boolean {
      return !!this.$slots.footer;
    }
  }
} )
