var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "search-bar" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("input", {
      ref: "input",
      staticClass: "bar",
      class: { invalid: _vm.invalid },
      attrs: {
        autocomplete: _vm.autocomplete ? "new-" + _vm.autocomplete : null,
        placeholder: _vm.placeholder,
        autocapitalize: "on",
        type: "search",
        enterkeyhint: "search",
      },
      domProps: { value: _vm.value },
      on: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.handleEnterPress.apply(null, arguments)
        },
        input: _vm.handleInput,
        focus: _vm.handleFocus,
        blur: _vm.handleBlur,
      },
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.disableClearButton === false && _vm.value.length > 0,
            expression: "disableClearButton === false && value.length > 0",
          },
        ],
        staticClass: "search-close",
        on: { click: _vm.handleClearClick },
      },
      [_c("div", { staticClass: "sd2-icons cross" })]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "search-icon" }, [
      _c("div", { staticClass: "sd2-icons search" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }