import { ISearchDealsConfiguration } from "./interfaces/ISearchDealsConfiguration";
import ValueObject from "../../../../../frontend/ValueObject";
import { ISearchDealsConfigurationData } from "./interfaces/ISearchDealsConfigurationData";
import { ITagCloudCategoryData } from "./interfaces/ITagCloudCategoryData";

export class SearchDealsConfiguration extends ValueObject<ISearchDealsConfigurationData> implements ISearchDealsConfiguration {

    public get active(): boolean {
        return this._props.active;
    }

    public get input(): { placeholder: string } {
        return this._props.input;
    }

    public get numSuggestionsMatches(): number {
        return this._props.num_suggestions_matches;
    }

    public get numSuggestionsProducts(): number {
        return this._props.num_suggestions_products;
    }

    public get tweakwise(): { cid: string; instanceId: string, popular_cid: string } {
        return {
            cid         : this._props.tweakwise.cid,
            instanceId  : this._props.tweakwise.instance_id,
            popular_cid : this._props.tweakwise.popular_cid
        };
    }

    public get ga(): any {
        return this._props.ga;
    }

    public get minCompanyMatches(): number {
        return this._props.min_company_matches;
    }

    public static create( props: ISearchDealsConfigurationData ): SearchDealsConfiguration {
        return new SearchDealsConfiguration( props );
    }

    public static createFromTagCloudCategoryData( tagCloudCategory: ITagCloudCategoryData ): SearchDealsConfiguration {
        return new SearchDealsConfiguration( tagCloudCategory.search_deals_configuration );
    }
}
