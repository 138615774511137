var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "distance-options" },
    [
      _c("SDSelectBox", {
        attrs: {
          label: _vm.$t("36107.App_FilterMaximumDistanceDrawer"),
          placeholder: _vm.$t("36585.App_FilterDistanceNoPreference"),
          options: _vm.distanceOptions,
          active: _vm.isDistanceOptionsOpen,
          value: _vm.selectedDistanceKey,
          "parent-bottom-position": _vm.parentBottomPosition,
        },
        on: {
          open: _vm.openDistanceOptions,
          close: _vm.closeDistanceOptions,
          setOption: _vm.setDistance,
        },
      }),
      _vm._v(" "),
      _vm.currentLocationText
        ? _c("div", { staticClass: "current-location-container" }, [
            _vm._v(
              "\n    " + _vm._s(_vm.$t("36169.App_FilterRelativeToDistance"))
            ),
            _c(
              "span",
              {
                staticClass: "current-location",
                on: {
                  click: function ($event) {
                    return _vm.clickedOnCurrentLocation($event)
                  },
                },
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.decodeEntities(_vm.currentLocationText))
                ),
              ]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }