var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "search-bar-modal",
      class: [_vm.active ? "active" : ""],
      attrs: { id: "search-bar-modal" },
    },
    [
      _c("div", { staticClass: "modal-heading" }, [
        _c("h3", [_vm._v(_vm._s(_vm.$t("12010.App_SearchDataScreenTitle")))]),
        _vm._v(" "),
        _c(
          "i",
          {
            staticClass: "material-icons close-icon",
            on: { click: _vm.closeSelf },
          },
          [_vm._v(" close ")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal-padding" },
        [
          _c("CitySelect", {
            attrs: { labels: _vm.labels, value: _vm.searchBar.location },
            on: { select: _vm.setCity },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "amount-wrapper" },
            [
              _c("AmountSelect", {
                attrs: {
                  options: _vm.searchBar.roomOptions,
                  value: _vm.searchBar.numRooms,
                },
                on: { change: _vm.setAmount },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "no-preference",
          on: { click: _vm.handleNoPreferenceClick },
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("12011.App_SearchDataButtonFlexDate")) +
              "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _vm.calendar && !!_vm.calendar.dateShortcuts
        ? _c("ButtonTabs", {
            attrs: {
              buttons: _vm.calendar.dateShortcuts,
              "active-unique": _vm.calendar.activeShortcut,
            },
            on: { buttonClick: _vm.handleShortcutClick },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.calendar && _vm.calendar.boxCheckinCheckout
        ? _c(
            "div",
            { attrs: { id: "range-placeholder-wrapper" } },
            [
              _c("DateRangePlaceholder", {
                attrs: { box: _vm.calendar.boxCheckinCheckout },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.calendar
        ? _c("SDCalendar", {
            attrs: { calendar: _vm.calendar, type: "hotels" },
            on: {
              update: _vm.updateCalendar,
              loadMore: _vm.updateCalendar,
              updateAndSubmit: _vm.updateCalendarAndSubmit,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.calendarLoading
        ? _c("Loader", { attrs: { type: "fixed" } })
        : _vm._e(),
      _vm._v(" "),
      _vm.calendar
        ? _c("ModalSubmitButton", {
            attrs: { "preview-text": _vm.calendar.summary },
            on: { submit: _vm.submit },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }