














































































import Vue, {PropType} from "vue";
import {Deal} from "../domain/Deal";
import FavoriteService from "../../../../../frontend/services/FavoriteService";
import { sdDevice } from "../../../../../frontend/helper/device";

type Data = {
  isFavoriteLoading: boolean;
}

export default Vue.extend({
  name: 'DealCard',
  props: {
    deal: {
      type: Object as PropType<Deal>,
      required: true
    },
    isDealDetail: {
      type: Boolean,
      default: false
    },
    loadStrategy: {
      type: String,
      default: 'lazy'
    }
  },
  data: (): Data => ({
    isFavoriteLoading: false
  }),
  computed: {
    isFavorite(): boolean {
      const favoriteUniques: Array<string> = this.$store.getters['favoriteUniques'];
      return favoriteUniques.find( ( unique: string ) => unique === this.deal.campaignUnique ) !== undefined;
    },
    isNewToday(): boolean {
      return this.deal.tags.includes("new_today");
    },
    isSoldOut(): boolean {
      return this.deal.tags.includes("sold_out");
    },
    target(): string {
      if ( sdDevice.isDesktop() && this.isDealDetail ) {
        return '_blank';
      }

      return '_self';
    }
  },
  methods: {
    async handleFavouriteButtonClick( e: Event ) {
      this.isFavoriteLoading = true;
      e.preventDefault();
      e.stopPropagation();

      if( this.isFavorite ) {
        await FavoriteService.deleteFavorite(this.deal.campaignUnique);
        this.$store.commit('removeFavorite', this.deal.campaignUnique);
      } else {
        await FavoriteService.addFavorite(this.deal.campaignUnique);
        this.$store.commit('addFavorite', this.deal.campaignUnique);
      }

      this.isFavoriteLoading = false;
      this.fetchTagCloudFavorites();
    },
    fetchTagCloudFavorites(): void {
      document.getElementById( 'favorite-results' ).innerHTML = '';

      const favorites = new sdFavorites();
      favorites.fetchFavorites( false );
    },
    handleDealClick(): void {
      this.$emit('loadDeal');
    }
  }
})
