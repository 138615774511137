var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "bottom-drawer-list",
      class: { forceDrawer: !_vm.isInModal },
    },
    [
      _c(
        "div",
        { staticClass: "bottom-drawer", class: { "full-screen": _vm.isLarge } },
        [
          _vm.visible
            ? _c("div", {
                staticClass: "info-background-layer",
                on: {
                  click: _vm.closeSelf,
                  touchmove: _vm.closeSelf,
                  wheel: _vm.scrollOrCloseSelf,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "bottom-drawer-inside",
              class: { active: _vm.visible, large: _vm.isLarge },
            },
            [
              _c("div", { staticClass: "drawer-head" }, [
                _vm.headerActionTitle
                  ? _c(
                      "div",
                      {
                        staticClass: "header-action",
                        on: { click: _vm.handleHeaderActionClick },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.headerActionTitle) +
                            "\n        "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("h6", { class: { "pr-2": _vm.headerActionTitle } }, [
                  _vm._v(_vm._s(_vm.drawerTitle)),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "close-wrap", on: { click: _vm.closeSelf } },
                  [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("close"),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  ref: "contentScroll",
                  staticClass: "drawer-content",
                  class: { "has-footer": _vm.hasFooter },
                },
                [_vm._t("default")],
                2
              ),
              _vm._v(" "),
              _vm.hasFooter
                ? _c(
                    "div",
                    {
                      staticClass: "drawer-footer",
                      class: { large: _vm.isLarge },
                    },
                    [_vm._t("footer")],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isLoading
                ? _c("Loader", { attrs: { type: "fixed" } })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }