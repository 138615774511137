










import Vue from "vue";

export default Vue.extend({
  name: 'Message',
  props: {
    imageSrc: {
      type: String,
      required: false
    },
    icon: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: true
    }
  }
});
