






import { PropType } from "vue";

export default {
  name: 'Divider',
  props: {
    text: {
      type: String as PropType<string>,
      required: true
    }
  }
}
