


































import Vue from "vue";

export default Vue.extend( {
  name  : 'Alert',
  props : {
    alert : {
      type     : Object,
      required : true
    }
  },
  methods: {
    action(action : any) {
      if(action && action === 'call-customer-service') {
        window.loadModal('customer-service')
        return;
      }

      if(action && action === 'decline') {
        this.$emit('onDecline');
        return;
      }

      this.$emit('onConfirm');
    }
  }
} );
