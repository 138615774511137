










































import Vue, { PropType } from "vue";
import CitySelect from './components/CitySelect.vue';
import CalendarService, { CalendarFlows } from "../../../../frontend/services/CalendarService";
import AmountSelect from "../../../../frontend/components/AmountSelect.vue";
import Loader from "../../../../frontend/components/Loader.vue";
import LmdSearchbar from "./domain/LmdSearchbar";
import SingleDayCalenderParams from "../../../Availability/submodule/Calendar/view/vue/domain/SingleDayCalenderParams";
import SingleDayCalendar from "../../../Availability/submodule/Calendar/view/vue/domain/SingleDayCalendar";
import CalendarSingleDay from "../../../Availability/submodule/Calendar/view/vue/components/CalendarSingleDay.vue";
import { ICity } from "./domain/City";
import ModalSubmitButton from "./ModalSubmitButton.vue";
import ButtonTabs from "../../../../frontend/components/ButtonTabs.vue";

export default Vue.extend(
    {
      name  : 'LmdModal',
      props : {
        defaultSearchBar   : {
          type     : Object as PropType<LmdSearchbar>,
          required : true
        },
        defaultLabels      : {
          type     : Object,
          required : true
        },
        gpsLabel           : {
          type     : String,
          required : true
        },
        deprecatedCalendar : {
          type    : Boolean,
          default : false
        }
      },
      data () {
        return {
          labels          : this.defaultLabels,
          calendarLoading : false,
          searchBar       : this.defaultSearchBar,
          calendar        : null as any
        }
      },
      components : {
        CitySelect,
        AmountSelect,
        CalendarSingleDay,
        Loader,
        ModalSubmitButton
      },
      async mounted (): Promise<any> {
        await this.updateCalendar( SingleDayCalenderParams.fromSearchBar( this.defaultSearchBar.toSingleDayCalendarParams() ) );
      },
      methods : {
        async updateCalendar ( params: SingleDayCalenderParams ): Promise<any> {
          this.calendarLoading = true;
          const calendar = await CalendarService.getCalendarByFlow(
              this.deprecatedCalendar ? CalendarFlows.lmd_nearby : CalendarFlows.restaurants,
              this.searchBar.numPeople,
              this.searchBar.cityUnique,
              params.toJSON(),
          );

          if ( calendar ) {
            const singleDayCalendar = SingleDayCalendar.fromApi( calendar );
            this.searchBar = LmdSearchbar.fromCalendarParams( this.searchBar, singleDayCalendar );
            this.calendar = singleDayCalendar;
          }

          this.calendarLoading = false;
        },
        async updateCalendarAndSubmit ( params: SingleDayCalenderParams ): Promise<any> {
          await this.updateCalendar( params );
          this.submit();
        },
        setCity ( city: ICity ) {
          city.unique = city.unique ?? null;
          city.location = city.location ?? city.locationLabel;

          this.labels.title = city.location ?? this.gpsLabel;
          this.searchBar.setLocation( city );
        },
        setAmount ( amountOptionKey: any ): void {
          this.searchBar.setAmount( amountOptionKey )
          this.updateCalendar( SingleDayCalenderParams.fromSearchBar( this.searchBar.toSingleDayCalendarParams() ) );
        },
        closeSelf (): void {
          this.$emit( 'close' );
        },
        submit (): void {
          this.$emit( 'submit', this.searchBar );
        }
      }
    } );
