
































































































import Vue, { PropType } from "vue";
import SDCalendar from "../../../submodule/Calendar/view/vue/components/SDCalendar.vue";
import TimeSlots from "../../../submodule/Calendar/view/vue/components/TimeSlots.vue";
import { DealsAvailability } from "../domain/DealsAvailability";
import SDCalendarInstance from "../../../submodule/Calendar/view/vue/domain/SDCalendarInstance";
import { triggerEvent } from "../../../../../frontend/helper/customEvents";
import { AmountDTO } from "../domain/DTO/AmountDTO";
import { ArrangementDTO } from "../domain/DTO/ArrangementDTO";

export default Vue.extend( {
  name       : 'DealsAvailability',
  components : {
    SDSelectBox          : () => import('../../../../../frontend/components/SDSelectBox.vue'),
    SDFakeSelectBox      : () => import('../../../../../frontend/components/SDFakeSelectBox.vue'),
    BottomDrawer         : () => import('../../../../../frontend/components/BottomDrawer.vue'),
    ListView             : () => import('../../../../../frontend/components/ListView.vue'),
    ItemCollapseView     : () => import('../../../../../frontend/components/ItemCollapseView.vue'),
    DateRangePlaceholder : () => import('./../../../../../frontend/components/DateRangePlaceholder.vue'),
    Alert                : () => import('../../../../../frontend/components/Alert.vue'),
    TimeSlots,
    SDCalendar
  },
  props      : {
    calendar         : {
      type : Object as PropType<SDCalendarInstance>
    },
    availability     : {
      type     : Object as PropType<DealsAvailability>,
      required : true
    },
    options          : {
      type     : Array as PropType<Array<DealsAvailability>>,
      required : true
    },
    timeSlots        : {
      type    : Array,
      default : Array as PropType<any[]>
    },
    arrangement      : {
      type     : Object as PropType<DealsAvailability>,
      required : false,
      default  : null
    },
    amount           : {
      type     : Number,
      required : true
    },
    additionalAmount : {
      type : Number || null
    },
    forceFlowActive  : {
      type     : Boolean,
      required : true
    }
  },
  data() {
    return {
      optionsVisible           : false,
      additionalOptionsVisible : false,
      drawerOrModalActive      : false,
      drawerOrModalVisible     : false,
      temporaryCalendarPayload : null,
      activeAlert : null
    }
  },
  watch    : {
    timeSlots() {
      if ( this.timeSlots && this.timeSlots.length > 0 ) {
        this.openDrawerOrModal();

        if ( !this.isMobile ) {
          window.scrollTo( '#timeslot-anchor', 150, 250 );
        }
      }
    }
  },
  methods  : {
    updateCalendar( payload: any ) {
      this.temporaryCalendarPayload = payload;

      if ( !this.arrangement ) {
        this.selectArrangementFirstAlert();
        this.$emit( 'setForceFlow', true );
        return
      }

      if ( this.availability.isAdditionalRequired && !this.additionalAmount ) {
        const alert = this.availability.alerts.additional_people;
        alert.callback = this.openAdditionalOptions;
        triggerEvent( 'dealDetail-toggleAlert', alert );
        return;
      }

      this.$emit( 'updateCalendar', payload, this.amount );
      this.temporaryCalendarPayload = null;
    },
    loadMoreCalendar( payload: any ) {
      this.$emit( 'updateCalendar', payload, this.amount );
    },
    setAmount( option: any ) {
      const payload = AmountDTO.create( { amountOptionKey : option.key }, {
        success : () => {
          this.closeOptions();

          if ( this.availability.isAdditionalRequired && this.forceFlowActive ) {
            return this.openAdditionalOptions();
          }

          return this.processTemporaryCalendarPayload();
        },
        failure : () => {
          this.closeOptions();
        }
      } )

      this.$emit( 'setAmount', payload );
    },
    setAdditionalAmount( option: any ) {
      this.$emit( 'setAdditionalAmount', option.key );
      this.closeAdditional();
      this.processTemporaryCalendarPayload();
      this.$emit( 'setForceFlow', false );
    },
    setTimeSlot( timeslot: any ) {
      this.$emit( 'setTimeSlot', timeslot );
    },
    selectFromDrawerOrModal( option: any ) {
      if ( this.view === 'ListView' || this.view === 'ItemCollapseView' ) {
        if ( !option.isPurchasable ) {

          triggerEvent( 'dealDetail-toggleAlert', this.availability.alerts.sold_out );
          return;
        }
      }

      if ( this.view === 'TimeSlots' ) {
        this.$emit( 'setTimeSlot', option );
        this.closeAll();
        return;
      }

      const payload = ArrangementDTO.create( {
        data     : option,
        callback : ( !this.amount || this.forceFlowActive
                   ) ? this.openOptions : null
      } );

      this.$emit( 'setArrangement', payload );
      this.closeAll();

    },
    scrollToRangeSelect() {
      this.scrollToElement( '#range-placeholder-wrapper' );
      return;
    },
    processTemporaryCalendarPayload() {
      if ( !this.temporaryCalendarPayload ) {
        return;
      }
      this.$emit( 'updateCalendar', this.temporaryCalendarPayload, this.amount );
      this.temporaryCalendarPayload = null;
    },
    openArrangements() {
      this.activeAlert = null;
      this.drawerOrModalActive = true;

      /* Add delay for mobile transition */
      if ( this.isMobile ) {
        setTimeout( () => {
          this.openDrawerOrModal()
        }, 100 );
      } else {
        this.openDrawerOrModal()
      }

      this.scrollToElement( '#deals-availability' );
    },
    openOptions() {
      this.closeAll();
      if ( !this.arrangement ) {
        this.selectArrangementFirstAlert();
        this.$emit( 'setForceFlow', true );
        return
      }

      this.optionsVisible = true;
      this.scrollToElement( '#deals-availability' );
    },
    openAdditionalOptions() {
      this.closeAll();
      if ( !this.arrangement ) {
        this.selectArrangementFirstAlert();
        this.$emit( 'setForceFlow', true );
        return
      }
      this.additionalOptionsVisible = true;
      this.scrollToElement( '#deals-availability' );
    },
    closeOptions() {
      this.optionsVisible = false;
    },
    closeAdditional() {
      this.additionalOptionsVisible = false;
    },
    openDrawerOrModal() {
      this.drawerOrModalVisible = true;
      this.moveWhatsApp();
    },
    closeAll() {
      this.optionsVisible = false;
      this.additionalOptionsVisible = false;
      this.drawerOrModalActive = false;
      this.drawerOrModalVisible = false;
      this.activeAlert = false;
      this.moveWhatsApp();
    },
    closeSelf() {
      this.closeAll();
      if ( this.isMobile ) {
        this.$emit( 'resetCalendar' );
      }
    },
    toggleScrollLock() {
      triggerEvent( 'availability-toggleLockScroll', {} )
    },
    selectArrangementFirstAlert() {
      const alert = this.availability.alerts.deals;
      this.activeAlert = {
        title: alert.title,
        message: alert.message,
        decline: alert.decline,
        button: alert.button
      }
      return;
    },
    moveWhatsApp() {
      setTimeout( () => {
        $whatsappBox.move();
      }, 250 );
    },
    scrollToElement( elementSelector: string ) {
      window.scrollTo( elementSelector, 150, 56 );
    },
  },
  computed : {
    view(): any {
      if ( this.timeSlots?.length > 0 && this.isMobile ) {
        return 'TimeSlots';
      }
      if ( this.hasMultiDealTabs ) {
        return 'ItemCollapseView';
      }
      return 'ListView';
    },
    drawerTitle(): any {
      return this.view === 'TimeSlots' ? ( this as any
      ).$t( '3968.App_availableTimesLabel' ) : ( this as any
             ).$t( '7533.App_reservationLabelMultiDealSelect' );
    },
    hasMultiDealTabs(): boolean {
      return this.arrangements.some( ( multiDeal: DealsAvailability ) => multiDeal.title !== '' )
    },
    shouldShowArrangementSelect(): any {
      return this.options?.length > 1;
    },
    isMobile(): boolean {
      return sdViewport.isMobile()
    },
    arrangementsOrTimeSlots(): any {
      return this.view === 'TimeSlots' ? this.timeSlots : this.arrangements;
    },
    arrangements(): any {
      return this.options.map( ( option ) => ( {
            unique        : option.unique,
            title         : option.title,
            description   : option.description,
            stats         : option.stats,
            fromPrice     : option.fromPrice,
            price         : option.price,
            isPurchasable : option.isPurchasable,
          }
      ) );
    },
  }
} );
