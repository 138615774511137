import CalendarService from "../../../../../frontend/services/CalendarService";
import SDCalendarInstance from "../../../submodule/Calendar/view/vue/domain/SDCalendarInstance";
import AvailabilityService from "../../../../../frontend/services/AvailabilityService";
import { DealsAvailability } from "../domain/DealsAvailability";

interface AvailabilityOptions {
    arrangements: {},
    additionalOptions: {}
}

export const fetchAvailability = async ( unique: string, params: any ) => {
    const payload = {
        type      : 'deal',
        requester : 'availability',
        places    : params.amount
    }

    const availabilityResponse = await AvailabilityService.getAvailability( unique, { ...payload, ...params } );
    return DealsAvailability.fromApi( availabilityResponse );
}

export const fetchOptions = async ( unique: string, params: any ) => {
    const payload = {
        type      : 'deal',
        requester : 'availability',
        places    : params.amount
    }
    const optionsResponse = await AvailabilityService.getOptions( unique, { ...payload, ...params } );
    return optionsResponse.map( ( option: any ) => DealsAvailability.fromApi( option ) )
}

export const fetchCalendar = async ( flow: string, unique: string, amount: number, params: any ) => {
    const calendarResponse = await CalendarService.getCalendarByFlowAndUnique(
        flow,
        unique,
        params,
        amount
    );
    return SDCalendarInstance.fromApi( calendarResponse );
}

export interface TimeSlotsParams {
    flow: string;
    unique: string;
    date: string;
}

export const fetchTimeSlots = async ( availabilityPayload: TimeSlotsParams, params: any ) => {
    const { flow, unique, date } = availabilityPayload;
    return await AvailabilityService.getTimeSlots(
        flow,
        unique,
        date,
        params
    );
}
