









import Vue from 'vue';

export default Vue.extend( {
  name : 'DealPrice',
  props : {
    price : {
      type : String,
      required : true,
    },
    fromPrice : {
      type : String,
      required : true,
    },
  },
} );

