var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.suggestionsToRender, function (suggestion, i) {
      return _c(
        "div",
        { key: i },
        [
          _c("AutocompleteSuggestionItem", {
            attrs: { suggestion: suggestion, "search-query": _vm.searchQuery },
            on: { click: _vm.handleAutocompleteItemClick },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }