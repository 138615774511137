

































































import Message from "./components/message.vue";
import Vue from "vue";
import Divider from "./components/divider.vue";
import DateTimeLabel from "./components/DateTimeLabel.vue";
import InboxService from "../../../../frontend/services/InboxService";
import {
    MessageType,
    PostPresentationMessagePayload, PostPresentationMessageResponse,
    PresentationMessagesResponse
} from "../../../../frontend/types/InboxTypes";
import Loader from "../../../../frontend/components/Loader.vue";
import {unsetCookie} from "../../../Component/view/ts/cookies";

interface Data {
    $target: any;
    focus: boolean
    subMessageVisible: boolean
    presentationMessageResponse: PresentationMessagesResponse,
    postPresentationMessageResponse: PostPresentationMessageResponse
    hasMessages: boolean;
    isLoading: boolean;
    isSending: boolean;
    isFetching: boolean;
    showSendMessageButton: boolean;
    currentFixedSectionHeight: number;
}

interface IMethods {
    submitMessage: () => void
    scrollTo: (scrollTo: number, duration?: number) => void,
    onInput: () => void,
    onScroll: (event: Event) => void,
    onKeyDown: (event: KeyboardEvent) => void,
    onPaste:( event: any ) => void // ClipboardEvent is not good supported
}

interface IComputed {
    isMobile: boolean
}

interface IProps {
}

export default Vue.extend<Data, IMethods, IComputed, IProps>({
    name: 'Inbox',
    components: {
        Message,
        Divider,
        DateTimeLabel,
        Loader
    },
    data() {
        return {
            $target: null,
            focus: false,
            subMessageVisible: false,
            presentationMessageResponse: {
                next: null,
                groups: [],
                presentation: {
                    show_new_message_label: false,
                    new_message_label: '',
                }
            },
            hasMessages: false,
            isLoading: true,
            isSending: false,
            isFetching: false,
            postPresentationMessageResponse: {
                presentation: {
                    confirmation_label: ''
                }
            },
            showSendMessageButton: false,
            currentFixedSectionHeight: 80
        }
    },
    async mounted(): Promise<void> {
        this.$target = $('.box-content.inbox').parents((this.isMobile) ? '.box-modal' : '.box-modal-scroll.inbox-top');

        pushOutTabs(true);
        try {
            this.presentationMessageResponse = await InboxService.getPresentationMessages();
        } catch (e) {
            this.isLoading = false;
            (window as any).sdAlerts.somethingWentWrong();
            return;
        }

        this.hasMessages = this.presentationMessageResponse?.groups[0]?.messages?.length > 0;
        this.isLoading = false;
        await this.$nextTick();

        const newMessageDivider = document.getElementById('new-message-divider');
        if(newMessageDivider) {
          newMessageDivider.scrollIntoView();
          if(this.isMobile) {
            //56 is the height of the top navbar and 64 is the offset needed to vertically center align the new message divider
            const topOffset = newMessageDivider.getBoundingClientRect().top <= 56 ? 64 : 0
            const scrollTop = Math.ceil(this.$target.scrollTop() - topOffset);
            this.scrollTo(scrollTop, 0);
          }
        }
        else {
          this.scrollTo(this.$el.scrollHeight, 0);
        }

        setTimeout(() => {
            this.$target[0].addEventListener('scroll', this.onScroll);

            $('.icon-badge').remove();
            unsetCookie('hideInboxBadge');

            $('.whatsapp-box').removeClass('visible');
        }, 600);
    },
    beforeDestroy(): void {
        this.$target[0].removeEventListener('scroll', this.onScroll);
        $('.whatsapp-box').addClass('visible');
    },
    methods: {
        onInput() {
            //handles scrolling
            const fixedSectionHeight = $('.fixed-section')[0]?.clientHeight;
            if (fixedSectionHeight && fixedSectionHeight !== this.currentFixedSectionHeight) {
                if (this.isMobile) {
                    this.$target.find('#inbox').css('padding-bottom', fixedSectionHeight);
                } else {
                    this.$target.css('bottom', fixedSectionHeight);
                }

                const scrollTop = Math.ceil(this.$target.scrollTop() + (fixedSectionHeight - this.currentFixedSectionHeight));
                this.scrollTo(scrollTop);
                this.currentFixedSectionHeight = fixedSectionHeight;
            }

            //handles button visibility
            this.showSendMessageButton = (this.$refs.contentEditable as HTMLElement)?.innerText.trim().length > 0;
        },
        async onKeyDown(event: KeyboardEvent) {
          if(!this.isMobile) {
            //Prevent enter going to next line if showSendMessageButton is false.
            if (event.key === 'Enter'  && !event.ctrlKey && !event.shiftKey && !event.altKey && !event.metaKey) {
              event.preventDefault();
              if (this.showSendMessageButton) {
                await this.submitMessage();
              }
            } else if (event.key === 'Enter' && event.ctrlKey) {
              document.execCommand('insertLineBreak')
            }
          }

        },
        async submitMessage() {
          if( this.isSending ) {
            return;
          }
          this.isSending = true;
          const payload: PostPresentationMessagePayload = {
              content: {text: (this.$refs.contentEditable as HTMLElement).innerText.trim()},
              type: MessageType.PLAINTEXT
          }

          try {
              this.postPresentationMessageResponse = await InboxService.postPresentationMessages(payload);
              const newPresentationMessageResponse = await InboxService.getPresentationMessages();

              const isMessageOnNewDay = newPresentationMessageResponse.groups[0].messages.length === 1;
              const currentGroups = this.presentationMessageResponse.groups;
              const newGroup = newPresentationMessageResponse.groups;

              if( isMessageOnNewDay ) {
                  currentGroups.unshift(newGroup[0]);
              } else {
                  if( currentGroups[0].messages[0].message.sent_by.email === newGroup[0].messages[0].message.sent_by.email ) {
                      currentGroups[0].messages[0].presentation.show_avatar = false;
                  }
                  currentGroups[0].messages.unshift(newGroup[0].messages[0]);
              }
              this.presentationMessageResponse = {
                  next: this.presentationMessageResponse.next,
                  groups: currentGroups,
                  presentation: this.presentationMessageResponse.presentation
              }

              this.isSending = false;
              this.subMessageVisible = true;
              this.showSendMessageButton = false;
              (this.$refs.contentEditable as HTMLElement).innerText = '';
              this.onInput();

              await this.$nextTick()
              this.scrollTo(this.$el.scrollHeight);
          } catch (e) {
              this.isSending = false;
              (window as any).sdAlerts.somethingWentWrong();
          }
        },
        async onScroll(event: Event) {
            const target = event.target as HTMLElement;

            if (
                this.isFetching ||
                !this.presentationMessageResponse ||
                !this.presentationMessageResponse.next ||
                target.scrollTop > 480
            ) {
                return;
            }

            this.isFetching = true;
            const nextUrl = new URL(this.presentationMessageResponse.next);

            // Create paramsObject from nextUrl
            const paramPairs = nextUrl.search.substring(1).split('&');
            const paramsObject: { [key: string]: string } = {};
            for (const paramPair of paramPairs) {
                const [key, value] = paramPair.split('=');
                if (key && value) {
                    paramsObject[key] = value;
                }
            }

            let olderPresentationMessageResponse = null;
            try {
              olderPresentationMessageResponse = await InboxService.getPresentationMessages(paramsObject);
            }
            catch (e) {
              this.isFetching = false;
              (window as any).sdAlerts.somethingWentWrong();
              return;
            }

            // Disable sticky to top when loading new messages
            let resetScrollTopZero = false;
            if (target.scrollTop < 1) {
                target.scrollTop = 1;
                resetScrollTopZero = true;
            }

            //add the messages to top
            this.presentationMessageResponse = {
                next: olderPresentationMessageResponse.next,
                groups: this.presentationMessageResponse.groups.concat(olderPresentationMessageResponse.groups)
            }

            // Reset scroll back to original position after sticky to top is no problem anymore
            if (resetScrollTopZero) {
                await this.$nextTick();
                target.scrollTop -= 1;
            }

            this.isFetching = false;
        },
        onPaste( event: any ) : void {
            // Paste => Paste as clean text
            event.preventDefault();

            const text = event.clipboardData ? (event.originalEvent || event).clipboardData.getData('text/plain') : (window as any).clipboardData ? (window as any).clipboardData.getData('Text') : '';
            if (document.queryCommandSupported('insertText')) {
                document.execCommand('insertText', false, text);
            }
            else {
                const range = document.getSelection().getRangeAt(0);
                range.deleteContents();

                const textNode = document.createTextNode(text);
                range.insertNode(textNode);
                range.selectNodeContents(textNode);
                range.collapse(false);

                const selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);
            }
        },
        scrollTo(scrollTop: number, duration: number = 200): void {
            if (!this.$target) {
                return;
            }

            this.$target.animate({
                scrollTop: scrollTop
            }, duration);
        }
    },
    computed: {
        isMobile(): boolean {
            return sdViewport.isMobile()
        }
    }
});
