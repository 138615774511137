





































import Vue, {PropType} from "vue";
import {CategoryItem} from "../../types/FilterTypes";

export type OnCategoryClicked = {
  categoryName: string,
  scrollCategoryIntoViewIfNeeded: Function,
}

interface IData {
  hoveredCategory?: string;
  activeCategories: string[];
  categoryOffsetWidth: number;
  showLeftNavigationButton: boolean;
  showRightNavigationButton: boolean;
}

interface IMethods {
  setHoveredCategory: (categoryName: string) => void,
  toggleActiveCategory: (categoryName: string) => void,
  removeHoveredCategory: () => void,
  onNavigateRightClick: () => void,
  onNavigateLeftClick: () => void,
  handleScroll: (event: Event) => void,
  getCategoryElementByIndex: (index:number) => HTMLElement,
  scrollCategoryIntoViewIfNeeded: (categoryIndex:number) => void,
  isLastCategoryRow: (index: number) => boolean,
  isCategoryActive: (categoryKey:string, isSelected:boolean) => boolean
}

interface IProps {
  categoryItems: CategoryItem[];
  selectedCategories: string[];
  selectAllKey: string
}
interface IComputed {
  isDesktop: boolean;
  firstSelectedItemIndex: number;
  lastCategoryRowHasTwoItems: boolean;
  lastCategoryIndex: number;
  secondLastCategoryIndex: number;
}
export default Vue.extend<IData, IMethods, IComputed, IProps>( {
  name  : 'CategoryFilter',
  props : {
    categoryItems : {
      type     : Array as PropType<IProps['categoryItems']>,
      required : true
    },
    selectedCategories : {
      type :  Array as PropType<IProps['selectedCategories']>,
      required : false
    },
    selectAllKey : {
      type :  String as PropType<IProps['selectAllKey']>,
      required : true
    }
  },
  data() {
    return {
     hoveredCategory: null,
     activeCategories: this.selectedCategories,
     categoryOffsetWidth:  94,
     showLeftNavigationButton: false,
     showRightNavigationButton: false
    }
  },
  methods: {
    onNavigateRightClick() {
      ($ as any)('.category-filter-container').animate( { scrollLeft: '+=' + this.categoryOffsetWidth}, 300 );
    },
    onNavigateLeftClick() {
      ($ as any)('.category-filter-container').animate( { scrollLeft: '-=' + this.categoryOffsetWidth}, 300 );
    },
    handleScroll(event: Event) {
      const scrollContainer = (event.target as HTMLElement);
      const scrollLeft = scrollContainer.scrollLeft;
      const scrollWidth = scrollContainer.scrollWidth;
      const clientWidth = scrollContainer.clientWidth;
      const reachedRightEdge = clientWidth + Math.ceil(scrollLeft) === scrollWidth ||  clientWidth + Math.floor(scrollLeft) === scrollWidth;
      if (scrollLeft > 0  && !reachedRightEdge) {
        this.showLeftNavigationButton = true;
        this.showRightNavigationButton = true;
      } else if (scrollLeft === 0) {
        this.showLeftNavigationButton = false
      } else if (reachedRightEdge) {
        this.showRightNavigationButton = false
      }
    },
    setHoveredCategory(categoryName: string) {
      this.hoveredCategory = categoryName;
    },
    removeHoveredCategory () {
      this.hoveredCategory = null;
    },
    toggleActiveCategory(categoryName: string) {
      const payload: OnCategoryClicked = {
        categoryName: categoryName,
        scrollCategoryIntoViewIfNeeded: (categoryIndex:number) => {
          this.scrollCategoryIntoViewIfNeeded(categoryIndex)
        }
      }
      this.$emit('on-category-clicked', payload);
    },
    getCategoryElementByIndex(index:number) {
      return  document.getElementsByClassName(`category-container-${index}`)[0] as HTMLElement;
    },
    scrollCategoryIntoViewIfNeeded(categoryIndex:number) {
      const container = this.$refs.categoryContainer as HTMLElement;
      const selectedCategoryRect = this.getCategoryElementByIndex(categoryIndex).getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();
      const isLeftVisible = selectedCategoryRect.left >= containerRect.left && selectedCategoryRect.left <= containerRect.right;
      const isRightVisible = selectedCategoryRect.right >= containerRect.left && selectedCategoryRect.right <= containerRect.right;

      if(!isLeftVisible || !isRightVisible) {
        const scrollTo = selectedCategoryRect.left - containerRect.left;
        ($ as any)(container).animate({ scrollLeft: scrollTo}, 300)
      }
    },
    isLastCategoryRow(index: number): boolean {
      const itemIsLastItem = index === this.lastCategoryIndex;

      if(this.lastCategoryRowHasTwoItems) {
       return itemIsLastItem || index === this.secondLastCategoryIndex;
      } else {
        return itemIsLastItem;
      }
    },
    isCategoryActive(categoryKey:string, isSelected:boolean): boolean {
      return this.hoveredCategory === categoryKey || isSelected;
    }
  },
  computed: {
    isDesktop() {
      return sdViewport.isDesktop()
    },
    firstSelectedItemIndex() {
      return this.categoryItems.findIndex(category => category.isSelected === true);
    },
    lastCategoryRowHasTwoItems(): boolean {
      return this.categoryItems.length % 2 === 0;
    },
    lastCategoryIndex():number {
      return this.categoryItems.length - 1;
    },
    secondLastCategoryIndex():number {
      return this.categoryItems.length - 2;
    },
  },
  mounted: function() {
    const categoryContainer = this.$refs.categoryContainer as HTMLElement;
    if(categoryContainer.scrollWidth > categoryContainer.clientWidth) {
      this.showRightNavigationButton = true;
      categoryContainer.addEventListener("scroll", this.handleScroll);
    }
    this.scrollCategoryIntoViewIfNeeded(this.firstSelectedItemIndex);
  }
} );
