type Coords = {
    latitude: number;
    longitude: number;
}

type UseGps = {
    requestPermissions: () => Promise<void>;
    getCurrentPosition: ( options?: PositionOptions ) => Promise<Coords>
}

export const useGps = (): UseGps => {

    const requestPermissions = async (): Promise<void> => {
        await getCurrentPosition();
    };

    const getCurrentPosition = ( options?: PositionOptions ): Promise<Coords> => {
        return new Promise<Coords>( async ( resolve, reject ) => {
            navigator.geolocation.getCurrentPosition(
                ( position ) => {
                    const { latitude, longitude } = position.coords;
                    resolve( {
                        latitude,
                        longitude
                    } );
                },
                ( err ) => {
                    // eslint-disable-next-line no-console
                    console.error( err );
                    reject( err );
                },
                {
                    timeout: 5000
                }
            );
        } );
    };

    return {
        requestPermissions,
        getCurrentPosition
    };
};
