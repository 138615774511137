var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { "min-height": _vm.isFlexFlow },
      attrs: { id: "hotels-availability-wrapper" },
    },
    [
      _vm.availability
        ? _c("HotelsAvailability", {
            attrs: {
              "is-for-sale": _vm.isForSale,
              availability: _vm.availability,
              amount: _vm.amount,
              "additional-amount": _vm.additionalAmount,
              calendar: _vm.calendar,
              type: _vm.type,
              "is-loading": _vm.loading.visible,
            },
            on: {
              updateAmount: _vm.updateAmount,
              updateAdditional: _vm.updateAdditional,
              updateAvailability: _vm.updateAvailability,
              updateCalendar: _vm.updateCalendar,
              submit: _vm.submit,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loading.visible
        ? _c("Loader", { attrs: { type: _vm.loading.type } })
        : _vm._e(),
      _vm._v(" "),
      _vm.currentAlert
        ? _c("Alert", {
            attrs: { alert: _vm.currentAlert },
            on: { onConfirm: _vm.closeAlerts, onDecline: _vm.closeAlerts },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }