import ValueObject from "../../../../../../frontend/ValueObject";

type SearchHistorySource = 'manual' | 'experience' | 'deeplink' | 'navigate';

interface ISearchHistoryFirstDeal {
    unique: string;
    distance: string;
}

export interface ISearchHistoryDTO {
    cityUnique: string;
    query?: string;
    experienceId?: number;
    tag?: string;
    firstDeal?: ISearchHistoryFirstDeal;
    source?: SearchHistorySource;
    inputValue?: string;
    navigateId?: number;
}

interface ISearchHistoryManualDTO {
    cityUnique: string;
    tag: string;
    query: string;
    firstDeal?: ISearchHistoryFirstDeal;
}

export interface ISearchHistoryDeeplinkDTO {
    cityUnique: string;
    tag: string;
    experienceId?: number;
    query?: string;
    firstDeal?: ISearchHistoryFirstDeal;
}

interface ISearchHistoryExperienceDTO {
    cityUnique: string;
    tag: string;
    experienceId: number;
    query?: string;
    firstDeal?: ISearchHistoryFirstDeal;
}

interface ISearchHistoryNavigateDTO {
    cityUnique: string;
    inputValue: string;
    navigateId: number;
}

interface ISearchHistoryJson {
    city_unique: string;
    source: string;
    experience_id?: number;
    tag?: string;
    query?: string;
    first_deal?: {
        unique: string;
        distance: string;
    };
    input_value?: string;
    navigate_id?: number;
}

export class SearchHistoryFirstDeal extends ValueObject<ISearchHistoryFirstDeal> implements ISearchHistoryFirstDeal {
    public get distance(): string {
        return this._props.distance;
    }

    public get unique(): string {
        return this._props.unique
    }
}

export class SearchHistoryDTO extends ValueObject<ISearchHistoryDTO> implements ISearchHistoryDTO {

    public get cityUnique(): string {
        return this._props.cityUnique;
    }

    public get query(): string | undefined {
        return this._props.query;
    }

    public get experienceId(): number | undefined {
        return this._props.experienceId;
    }

    public get tag(): string | undefined {
        return this._props.tag;
    }

    public get firstDeal(): SearchHistoryFirstDeal | undefined {
        if ( this._props.firstDeal ) {
            return new SearchHistoryFirstDeal( this._props.firstDeal );
        }

        return undefined;
    }

    public get source(): SearchHistorySource {
        return this._props.source ?? "manual";
    }

    public get inputValue(): string {
        return this._props.inputValue;
    }

    public get navigateId(): number {
        return this._props.navigateId;
    }

    public toJson(): any {
        const json: ISearchHistoryJson = {
            source      : this.source,
            city_unique : this.cityUnique
        };

        if ( this.query ) {
            json.query = this.query;
        }

        if ( this.tag ) {
            json.tag = this.tag;
        }

        if ( this.firstDeal ) {
            json.first_deal = {
                unique   : this.firstDeal.unique,
                distance : this.firstDeal.distance
            };
        }

        if ( this.experienceId ) {
            json.experience_id = this.experienceId;
        }

        if ( this.inputValue ) {
            json.input_value = this.inputValue;
        }

        if ( this.navigateId ) {
            json.navigate_id = this.navigateId;
        }

        return json;
    }

    public setFirstDeal( props: ISearchHistoryFirstDeal ): void {
        this._props.firstDeal = props;
    }

    public static create( props: ISearchHistoryDTO ): SearchHistoryDTO {
        return new SearchHistoryDTO( props );
    }

    public static createForNavigateSearch( props: ISearchHistoryNavigateDTO ): SearchHistoryDTO {
        return new SearchHistoryDTO( {
            source : "navigate",
            ...props
        } );
    }

    public static createForManualSearch( props: ISearchHistoryManualDTO ): SearchHistoryDTO {
        return new SearchHistoryDTO( {
            source : "manual",
            ...props
        } );
    };

    public static createForExperience( props: ISearchHistoryExperienceDTO ): SearchHistoryDTO {
        return new SearchHistoryDTO( {
            source : "experience",
            ...props
        } );
    };

    public static createForDeeplink( props: ISearchHistoryDeeplinkDTO ): SearchHistoryDTO {
        return new SearchHistoryDTO( {
            source : "deeplink",
            ...props
        } );
    }
}
