








import Vue from 'vue';
import Loader from "../../../../../frontend/components/Loader.vue";

export default Vue.extend( {
  name       : 'TweakwiseLoader',
  components : {
    Loader
  }
} )
