import {APIService} from "./APIService";
import ValueObject from "../ValueObject";
import {PricesDTO} from "../../module/Deals/view/vue/domain/dto/PricesDTO";

export interface IPlanningDealResponse {
    campaign_unique: string;
    city: {
        name: string;
        slug: string;
    };
    company: {
        name: string;
        slug: string;
    };
    diamond: {
        name: string;
        color: string;
    };
    image: string;
    pills: Array<any>;
    prices: PricesDTO,
    purchase_info: {
        sold: number;
        max: number;
    },
    review_label: string;
    sold_label: string;
    sort_by_attribute: {
        default: number;
        distance: number;
        revenue: number;
    };
    tags: string;
    title: {
        label: string;
        slug: string;
    },
    unique: string;
}

export interface IPlanningResponse {
    deals: Array<IPlanningDealResponse>;
}

class PlanningResponse extends ValueObject<IPlanningResponse> implements IPlanningResponse{
    public get deals() : Array<IPlanningDealResponse> {
        return this._props.deals;
    }

    public static createFromApi( response: IPlanningResponse )  {
        return new PlanningResponse( response );
    }
}

export default class PlanningService extends APIService {
    public static async getPlanning( cityUnique: string ): Promise<IPlanningResponse> {
        const response = await this.get<IPlanningResponse>(`/proxy/city/planning/${cityUnique}/`);
        return response;
    }
}
