import {APIService, APIServiceRequestConfig} from "./APIService";
import {
    GetPresentationMessagesPayload,
    PostPresentationMessagePayload,
    PostPresentationMessageResponse, PostReadReceiptPayload,
    PresentationMessagesResponse,
    UnreadMessageCountResponse,
} from "../types/InboxTypes";

export default class InboxService extends APIService {

    private static endpointPrefix: string = '/proxy/api';

    public static getPresentationUnreadMessageCount(): Promise<UnreadMessageCountResponse> {
        return this.get<UnreadMessageCountResponse>( `${this.endpointPrefix}/presentation/unread-message-count/`, {
            headers : this.getHeaders({
                'X-Provider' : 'inbox',
            }),
        } );
    }
    public static getPresentationMessages(params?: GetPresentationMessagesPayload): Promise<PresentationMessagesResponse> {
        return this.get<PresentationMessagesResponse>( `${this.endpointPrefix}/presentation/messages/`, {
            params  : params,
            headers : this.getHeaders({
                'X-Provider' : 'inbox',
            }),
        } );
    }

    public static postPresentationMessages(params: PostPresentationMessagePayload): Promise<PostPresentationMessageResponse>  {
        return this.post<PostPresentationMessagePayload, PostPresentationMessageResponse>( `${this.endpointPrefix}/presentation/messages/`, params, {
            headers : this.getHeaders({
                'X-Provider' : 'inbox',
            }),
        } );
    }
}
