
















import Vue from "vue";

export default Vue.extend( {
  props : {
    dayKey  : {
      type     : Number,
      required : true
    },
    toolTip : {
      type     : Object,
      required : true
    }
  },
  data() {
    return {
      stickyBottom : false,
    }
  },
  mounted() {
    document.addEventListener( 'scroll', this.checkToolTipPosition );
    this.checkToolTipPosition();
  },
  beforeDestroy() {
    window.removeEventListener( 'scroll', this.checkToolTipPosition );
  },
  methods : {
    checkToolTipPosition() {
      if ( !this.$refs[ "current-tooltip" ] ) {
        return;
      }
      const domRect = (this.$refs[ "current-tooltip" ] as HTMLElement).getBoundingClientRect();
      const offset = sdViewport.isMobile() ? 128 : 44;

      this.stickyBottom = domRect.top < offset;
    }
  }
} );
