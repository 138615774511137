





























import Vue from "vue";

export default Vue.extend({
  name: 'SearchInput',
  props: {
    placeholder: {
      type: String,
      required: true
    },
    disableClearButton: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    },
    invalid: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: null
    }
  },
  computed: {
    inputRef(): HTMLInputElement {
      return this.$refs.input as HTMLInputElement;
    }
  },
  methods: {
    handleEnterPress( e: any ): void {
      if ( this.value?.length > 2 ) {
        this.blur();
      }
      this.$emit('submit', this.value );
    },
    handleClearClick( event: any ): void {
      event.stopPropagation();

      this.$emit('onChange', event );
      this.$emit('onClear');
      this.triggerFilterCategory();
    },
    handleInput( event: any ): void {
      this.$emit('onChange', event );
    },
    handleFocus( e : Event ): void {
      this.$emit('focus', e);
    },
    handleBlur(): void {
      this.$emit('blur');

      if ( this.value?.length === 0 ) {
        this.triggerFilterCategory();
      }
    },
    focus(): void {
      this.inputRef.focus();
    },
    blur(): void {
      this.inputRef.blur();
    },
    triggerFilterCategory(): void {
      var $activeTagCloudItem = $('#lastMinuteBox').find('.tagcloud-inner:visible .tagcloud-item.active');
      if( $activeTagCloudItem.length === 1 ) {
        var evt = document.createEvent('CustomEvent');
        evt.initCustomEvent('categorychange', false, false, {
          category: $activeTagCloudItem.data('category')
        });
        window.dispatchEvent(evt);
      }
    }
  }
})
