var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isInitNearbyInspirations
        ? _c("FilterModal", {
            attrs: {
              "city-unique": _vm.cityUnique,
              badge: _vm.badge,
              "show-badge": _vm.showFilterBadge,
              "filter-modal-type": _vm.filterModalType,
              "nearby-searchbar": _vm.nearbySearchBarData,
            },
            on: {
              "on-filter-button-click": _vm.onFilterButtonClick,
              "on-close-filter": _vm.onCloseFilter,
              "get-filter-search-results": _vm.getFilterSearchResults,
              "show-favorites": _vm.showFavorites,
              "hide-favorites": _vm.hideFavorites,
              "on-badge-update": _vm.badgeUpdated,
              "on-nearby-location-updated": _vm.nearbyLocationUpdated,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isFilterModalTypeHome
        ? [
            _c("Tweakwise", {
              attrs: {
                "city-unique": _vm.cityUnique,
                "city-slug": _vm.citySlug,
                "tag-cloud-data": _vm.tagCloudData,
                "initial-deeplink-response": _vm.initialDeeplinkResponse,
              },
              on: {
                clear: _vm.handleClear,
                "hide-filter-button": function ($event) {
                  return _vm.handleFilterButtonVisibility(true)
                },
                "show-filter-button": function ($event) {
                  return _vm.handleFilterButtonVisibility(false)
                },
                "show-planning": _vm.showPlanning,
                "hide-planning": _vm.hidePlanningAndFilter,
                "on-focus": _vm.onSearchbarFocus,
                "on-blur": _vm.onSearchbarBlur,
              },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.filteredDeals &&
                _vm.filteredDeals.length > 0 &&
                !_vm.isEmptyFilter &&
                _vm.showFilterDeals &&
                !_vm.hideDealsWhenSearchBarIsActive,
              expression:
                "filteredDeals && filteredDeals.length > 0 && !isEmptyFilter && showFilterDeals && !hideDealsWhenSearchBarIsActive",
            },
          ],
          staticClass: "filtered-deal-list",
        },
        [
          _c("DealList", {
            attrs: {
              deals: _vm.filteredDeals,
              firstPairDealsAsFeatured:
                !_vm.hideFilterButton && _vm.isFilterModalTypeHome,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isEmptyFilter && _vm.showFilterDeals
        ? _c(
            "div",
            {
              staticClass: "empty-filter",
              class: {
                "nearby-filter-empty-filter-padding":
                  _vm.isFilterModalTypeNearby,
              },
            },
            [
              _c("Message", {
                attrs: {
                  "image-src": "/img/no-deals-found.svg",
                  label: _vm.$t("36079.App_FilterHomeEmptyStateCaption"),
                },
              }),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "favorite-empty-state-button",
                  on: { click: _vm.resetFilterOutsideModal },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("36080.App_FilterHomeEmptyStateButton"))
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.experiences && _vm.isFilterModalTypeHome
                ? _c("ExperienceList", {
                    staticClass: "filter-experiences",
                    attrs: { experiences: _vm.experiences },
                    on: { click: _vm.handleExperienceItemClick },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }