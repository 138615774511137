

















import Vue from "vue";

export default Vue.extend( {
  name  : 'CalendarNoAvailabilityAlert',
  props : {
    alert : {
      type     : Object,
      required : true
    }
  }
} );
