import { APIService, WrappedResponse } from "./APIService";

export type Prediction = {
    description: string,
    place_id: string
}

type AutoCompleteResponse = {
    predictions: Prediction[],
    query: string
}

export default class CityService extends APIService {

    public static getCities() {
        return this.get<any[]>( `/city-list/`, {
            headers: { 'X-Requested-With': 'XMLHttpRequest' } as any
        } );
    }

    public static autocomplete( query: string ) {
        var bodyFormData = new FormData();
        bodyFormData.append( 'query', query );
        return this.post<FormData, WrappedResponse<AutoCompleteResponse>>( `/cities/rest/autocomplete/`, bodyFormData );
    }

    public static getLocation( payload: any ) {
        var bodyFormData = new FormData();
        bodyFormData.append( 'placeID', payload.place_id );
        bodyFormData.append( 'query', payload.query );
        return this.post<FormData, any>( `/cities/rest/location/`, bodyFormData );
    }

    public static addHistory( payload: any ) {
        if( payload.name === undefined || payload.latitude === undefined || payload.longitude === undefined ) {
            return null;
        }
        var bodyFormData = new FormData();
        bodyFormData.append( 'name', payload.name );
        bodyFormData.append( 'latitude', payload.latitude );
        bodyFormData.append( 'longitude', payload.longitude );
        return this.post<FormData, any>( `/cities/rest/add-history/`, bodyFormData );
    }
}
