var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: "calendar " + _vm.type }, [
    _c(
      "div",
      { staticClass: "calendar-months" },
      _vm._l(_vm.calendar.months, function (month, monthKey) {
        return _c(
          "div",
          { key: monthKey, staticClass: "month" },
          [
            _c("div", { staticClass: "month-wrapper" }, [
              _vm._v(_vm._s(month.title)),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "month-header" },
              _vm._l(month.header, function (monthHead, montHeadKey) {
                return _c("div", { key: montHeadKey }, [
                  _vm._v("\n          " + _vm._s(monthHead) + "\n        "),
                ])
              }),
              0
            ),
            _vm._v(" "),
            _vm._l(month.weeks, function (week, weekKey) {
              return _c(
                "div",
                { key: weekKey, staticClass: "week flex-between" },
                _vm._l(week.days, function (day, dayKey) {
                  return _c("CalendarDay", {
                    attrs: { day: day, "day-key": dayKey },
                    on: {
                      click: function ($event) {
                        return _vm.clickDay(day)
                      },
                      mouseover: function ($event) {
                        return _vm.startHoverFrom(day)
                      },
                      mouseleave: function ($event) {
                        return _vm.endHoverFrom(day)
                      },
                    },
                  })
                }),
                1
              )
            }),
          ],
          2
        )
      }),
      0
    ),
    _vm._v(" "),
    _vm.calendar.more
      ? _c(
          "div",
          { staticClass: "calendar-load-more", on: { click: _vm.loadMore } },
          [_vm._v("\n    " + _vm._s(_vm.calendar.more.label) + "\n  ")]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }