import { ISearchBar, SearchBar } from "./SearchBar";
import SingleDayCalendar from "../../../../Availability/submodule/Calendar/view/vue/domain/SingleDayCalendar";
import { IHotelSearchbar } from "./HotelsSearchbar";

export interface ILmdSearchbar extends ISearchBar {
    num_people: number,
    people_options: any[],
    date: string | null,
    date_shortcut?: string,
}

export default class LmdSearchbar extends SearchBar<ILmdSearchbar> {

    get date() {
        return this._props.date;
    }

    get numPeople() {
        return this._props.num_people < this.peopleOptions.length ? this._props.num_people : this.peopleOptions.length;
    }

    get peopleOptions(): any[] {
        return this._props.people_options
    }

    get dateShortcut(): string {
        return this._props.date_shortcut;
    }

    public setAmount( amountOptionKey: any ) {
        this._props.num_people = amountOptionKey;
    }

    private static create( props: any ): LmdSearchbar {
        return new LmdSearchbar( props );
    }

    static fromApi( props: any ) {
        return new LmdSearchbar( {
            ...props,
            ...props.search_bar
        } );
    }

    static fromCalendarParams( searchBar: LmdSearchbar, calendar: SingleDayCalendar ): LmdSearchbar {
        return LmdSearchbar.create( Object.assign( searchBar.toJson(), calendar.params.toSearchbar() ) );
    }

    public toJson(): ILmdSearchbar {
        return {
            title          : this._props.title,
            num_people     : this._props.num_people,
            people_options : this._props.people_options,
            description    : this._props.description,
            date           : this._props.date,
            location       : this._props.location,
            latitude       : this._props.latitude,
            longitude      : this._props.longitude,
            city_unique    : this._props.city_unique,
            date_shortcut  : this._props.date_shortcut,
        }
    }

    public toSingleDayCalendarParams() {
        return {
            amount   : this.numPeople,
            selected : this.date,
            date_shortcut : this.dateShortcut
        }
    }

    public toSearchBarDTO(): any {
        return {
            latitude   : this.latLng.lat,
            longitude  : this.latLng.lng,
            location   : this.location,
            date       : this.date,
            num_people : this.numPeople,
            date_shortcut : this.dateShortcut,
        }
    }
}
