/* This function allows communication between vue and existing JQuery codebase */
const triggerEvent = ( globalEventName: string, payload: any ) => {
    var evt = document.createEvent( "CustomEvent" );
    evt.initCustomEvent( globalEventName, false, false, payload );
    window.dispatchEvent( evt );
}

const addEventListener = ( el: any, eventName: string, handler: any ) => {
    if ( el.addEventListener ) {
        el.addEventListener( eventName, handler );
    } else {
        el.attachEvent( 'on' + eventName, function () {
            handler.call( el );
        } );
    }
}

export {
    triggerEvent,
    addEventListener
}
