









import Vue, {PropType} from "vue";
import {SortListEmit, SortListItem} from "../../types/FilterTypes";

interface IMethods {
  handleSortListItemClick: (sortListItem: SortListItem) => void;
}

interface IProps {
  sortListItems:SortListItem[];
  selectedValue: string
}
export default Vue.extend<{}, IMethods, {}, IProps>( {
  name  : 'SortList',
  props : {
    sortListItems : {
      type     : Array as PropType<IProps['sortListItems']>,
      required : true
    },
    selectedValue : {
      type: String,
      required : false
    }
  },
  methods: {
    handleSortListItemClick (sortListItem: SortListItem) {
      const currentSelectedKey = this.sortListItems.find(s => s.isSelected).key;
      const sortListEmit: SortListEmit = {toSelectedKey: sortListItem.key, fromSelectedKey: currentSelectedKey};
      this.$emit('sort-list-item-click', sortListEmit);
    }
  }
} );
