var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("TweakwiseSearchBar", {
        ref: "searchBar",
        class: _vm.isDealDetail ? "hidden-xs" : "",
        attrs: {
          "is-deal-detail": _vm.isDealDetail,
          "city-unique": _vm.cityUnique,
          "auto-complete": _vm.autoComplete,
          "auto-complete-deals": _vm.autoCompleteDeals,
          "auto-complete-load-more": true,
          "auto-complete-search-state": _vm.autoCompleteState,
          deals: _vm.deals,
          experiences: _vm.experiences,
          "input-value": _vm.inputValue,
          placeholder: _vm.inputPlaceholder,
          query: _vm.query,
          "search-history": _vm.searchHistory,
          "search-state": _vm.searchState,
          "autocomplete-search-state": _vm.autoCompleteState,
          "submit-query": _vm.submitQuery,
          "tag-cloud-category": _vm.tagCloudCategory,
          "search-deals-configuration": _vm.searchDealsConfiguration,
          "popular-phrase": _vm.popularPhrase,
        },
        on: {
          setQueryParams: _vm.setQueryParam,
          clear: _vm.handleClear,
          clickHistoryItem: _vm.handleSearchHistoryItemClick,
          clickSuggestionItem: _vm.handleExperienceButtonClick,
          clickAutocompleteItem: _vm.handleAutocompleteClick,
          clickPopularPhraseItem: _vm.handlePopularPhraseItemClick,
          fetchMore: _vm.handleFetchMore,
          fetchMoreAutocomplete: _vm.fetchMoreAutocomplete,
          onChangeQuery: _vm.handleQueryChange,
          onFocus: _vm.handleSearchBarFocus,
          openModal: _vm.openModal,
          submit: _vm.submit,
          blur: function ($event) {
            return _vm.handleBlur()
          },
          onFetchAutocomplete: _vm.fetchAutocomplete,
        },
      }),
      _vm._v(" "),
      _vm.isActive && _vm.showModal
        ? _c("TweakwiseSearchModal", {
            ref: "searchModal",
            attrs: {
              "auto-complete": _vm.autoComplete,
              "auto-complete-deals": _vm.autoCompleteDeals,
              "city-unique": _vm.cityUnique,
              experiences: _vm.experiences,
              "initial-query": _vm.query,
              "is-loading": _vm.isLoading,
              placeholder: _vm.inputPlaceholder,
              "search-history": _vm.searchHistory,
              "search-state": _vm.autoCompleteState,
              "tag-cloud-category": _vm.tagCloudCategory,
              "popular-phrase": _vm.popularPhrase,
              "initial-deeplink-response": _vm.initialDeeplinkResponse,
            },
            on: {
              setQueryParams: _vm.setQueryParam,
              clickExperienceItem: _vm.handleExperienceButtonClick,
              clickHistoryItem: _vm.handleSearchHistoryItemClick,
              clickAutocompleteItem: _vm.handleAutocompleteClick,
              clickPopularPhraseItem: _vm.handlePopularPhraseItemClick,
              close: _vm.closeModal,
              onChangeQuery: _vm.handleQueryChange,
              onFetchAutocomplete: _vm.fetchAutocomplete,
              submit: _vm.submit,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }