













































import Vue from "vue";
import { fetchAvailability, fetchOptions, fetchTimeSlots } from "../controllers/DealAvailabilityController";
import { DealsAvailability } from "../domain/DealsAvailability";
import CalendarService from "../../../../../frontend/services/CalendarService";
import SDCalendarInstance from "../../../submodule/Calendar/view/vue/domain/SDCalendarInstance";
import { triggerEvent } from "../../../../../frontend/helper/customEvents";
import SDCalendar from "../../../submodule/Calendar/view/vue/domain/SDCalendarInstance";
import { ArrangementDTO } from "../domain/DTO/ArrangementDTO";
import { AmountDTO } from "../domain/DTO/AmountDTO";
import { sdDevice } from "../../../../../frontend/helper/device";

interface CalendarDTO {
  flow: string,
  unique: string,
  amount: number
}

interface Callback {
  success: Function,
  failure: Function
}

export default Vue.extend( {
  components : {
    Loader                      : () => import('../../../../../frontend/components/Loader.vue'),
    DealsAvailability           : () => import('./DealsAvailability.vue'),
    PreviewAvailability         : () => import('./PreviewAvailability.vue'),
    CalendarNoAvailabilityAlert : () => import('./CalendarNoAvailabilityAlert.vue'),
    SoldOutAlert                : () => import('./SoldOutAlert.vue'),
  },
  props      : {
    defaultUnique         : {
      type     : String,
      required : true
    },
    event                 : {
      type     : String,
      required : true
    },
    isForSale             : {
      type     : Boolean,
      required : true
    },
    isPreviewAvailability : {
      type    : Boolean,
      default : false
    },
  },
  data() {
    return {
      loading              : {
        visible : false,
        type    : 'absolute'
      },
      initialized          : false as boolean,
      forceFlowActive      : false as boolean,
      unique               : this.defaultUnique,
      amount               : 1 as number,
      additionalAmount     : null as number,
      availability         : null as DealsAvailability,
      arrangement          : null as DealsAvailability,
      options              : [] as DealsAvailability[],
      calendar             : null as SDCalendar,
      timeSlots            : [] as string[],
      timeSlot             : null as string,
      calendarAlertVisible : false as boolean,
      soldOutAlertVisible  : false as boolean,
    }
  },
  async mounted() {
    await this.fetchAvailability( this.defaultUnique, {} );
    await this.fetchCalendar( this.availabilityPayload, {} );
    if ( this.isMobile ) {
      this.loading.type = 'fixed';
    } else {
      this.loading.type = 'absolute';
    }
    this.initialized = true;
  },
  methods  : {
    async fetchAvailability( unique: any, params: any ): Promise<void> {
      this.availability = await fetchAvailability( unique, params );
      this.options = await fetchOptions( unique, params );
      this.amount = this.availability.amount.selectedOptionKey ?? 1;
      this.additionalAmount = null;

      if ( this.options?.length === 1 ) {
        this.unique = this.availability.unique;
        this.availability = await fetchAvailability( this.options[0].unique, this.availabilityPayload );
        this.arrangement = this.availability;
      }
    },
    async fetchCalendar( calendarDTO: CalendarDTO, params: any = {}, callback: Callback = null ) {
      await this.updateCalendar( params, calendarDTO.amount, callback )
    },
    async updateCalendar( params: any, amount: any, callback: Callback = null ): Promise<any> {
      this.loading.visible = true;

      if ( !this.isForSale && this.initialized ) {
        this.soldOutAlertVisible = true;
        this.loading.visible = false;
        return;
      }

      const calendar = await CalendarService.getCalendarByFlowAndUnique(
          this.availability.calendarFlow,
          this.availability.unique,
          params,
          amount
      );

      this.calendar = SDCalendarInstance.fromApi( calendar );
      if ( this.calendar.noAvailabilityAlert ) {
        this.calendarAlertVisible = true;
        this.loading.visible = false;

        if ( callback && callback.failure ) {
          return callback.failure();
        }
      }

      if ( this.availability.isTimeSensitive && this.calendar.selectedValue ) {
        this.setForceFlow( false );
        await this.fetchTimeSlots();
        return;
      }

      if ( callback && callback.success ) {
        this.loading.visible = false;
        return callback.success();
      }

      if (
          ( this.calendar.isRangeCalendar && this.calendar.isRangeComplete
          ) ||
          ( this.calendar.isSingleDateCalendar && this.calendar.selectedValue
          )
      ) {
        this.submit();
      }

      this.loading.visible = false;
    },
    async resetCalendar() {
      if ( !this.calendar.selectedValue ) {
        return;
      }

      this.loading.visible = true;
      this.timeSlots = [];

      const calendar = await CalendarService.getCalendarByFlowAndUnique(
          this.availability.calendarFlow,
          this.availability.unique,
          this.calendar.unselect.params,
          this.amount
      );

      this.calendar = SDCalendarInstance.fromApi( calendar );
      this.setForceFlow( false );
      this.loading.visible = false;
    },
    setForceFlow( state: boolean ) {
      this.forceFlowActive = state;
    },
    async fetchTimeSlots() {
      const date = this.calendar.selectedValue;
      this.timeSlots = await fetchTimeSlots(
          { ...this.availabilityPayload, date },
          { amount : this.availabilityPayload.amount }
      );

      this.loading.visible = false;
    },
    async setArrangement( payload: ArrangementDTO ) {
      this.loading.visible = true;
      this.availability = await fetchAvailability( payload.data.unique, this.availabilityPayload );
      this.arrangement = this.availability;
      this.unique = this.availability.unique;
      this.timeSlots = [];
      await this.fetchCalendar( this.availabilityPayload );

      if ( typeof payload.callback === "function" ) {
        payload.callback();
        this.loading.visible = false;
      }
    },
    async setAmount( payload: AmountDTO ) {
      this.loading.visible = true;
      this.amount = payload.option.amountOptionKey ?? 1;
      this.additionalAmount = null;

      this.availability = await fetchAvailability( this.availability.unique, this.availabilityPayload );
      this.arrangement = this.availability;
      this.timeSlots = [];
      await this.fetchCalendar( this.availabilityPayload, this.calendar.params, payload.callback );

      if ( payload.callback && payload.callback.success ) {
        this.loading.visible = false;
        return payload.callback.success();
      }
    },
    async setAdditionalAmount( amountOptionKey: number ) {
      this.additionalAmount = amountOptionKey; // possible bug due not mapping key for actual value
    },
    async setTimeSlot( timeSlot: string ) {
      this.timeSlot = timeSlot;
      this.submit();
    },
    submit() {
      const cartData = {
        time             : this.timeSlot,
        unique           : this.availability.unique,
        via              : 'availability',
        timeSensitive    : this.availability.isTimeSensitive,
        people           : this.amount,
        additionalPeople : this.additionalAmount,
      } as any;

      const property = this.calendar.isRangeCalendar ? 'range' : 'date';
      cartData[ property ] = this.calendar.selectedValue;

      if ( this.calendar.isRangeCalendar && !this.calendar.isTillDateSelected ) {
        delete cartData.range.till
      }

      ($ as any).sdGA( 'availability' );

      var link = '/cart/add/' + cartData.unique + '/' + '?' + encodeURIComponent( ( jQuery as any
      ).param( cartData ) );

      triggerEvent( 'availability-closeAvailabilityModal', {} )
      showCart( null, link );
      this.resetCalendar();
    },
    closeAvailabilityAlert() {
      this.calendarAlertVisible = false;
    },
    closeSoldOutAlert() {
      this.soldOutAlertVisible = false;
    }
  },
  computed : {
    isMobile() {
      return sdDevice.isMobile()
    },
    availabilityPayload(): any {
      return {
        flow   : this.availability.calendarFlow,
        unique : this.unique,
        amount : this.amount,
      }
    }
  }
} );

