var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.labels.title
        ? [
            _c(
              "div",
              {
                staticClass: "sb-title",
                class: [_vm.labels.title === "gps" ? "is-gps" : ""],
              },
              [_vm._v("\n    " + _vm._s(_vm.labels.title) + "\n  ")]
            ),
            _vm._v(" "),
            _c("div", {
              staticClass: "sb-subtitle",
              domProps: { innerHTML: _vm._s(_vm.labels.description) },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }