var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { "force-overlay-modal": _vm.drawerOrModalVisible },
      attrs: { id: "deals-availability" },
    },
    [
      _c("h2", [_vm._v(_vm._s(_vm.$t("2906.Deals_hashAvailability")))]),
      _vm._v(" "),
      _c("div", { staticClass: "mobile-spacing" }, [
        _vm.shouldShowArrangementSelect
          ? _c(
              "div",
              { staticClass: "availability-options z-30" },
              [
                _c("SDFakeSelectBox", {
                  attrs: {
                    label: _vm.$t("4198.App_arrangementTitle"),
                    placeholder: _vm.$t("6976.App_selectReservationDeal"),
                    value: _vm.arrangement,
                  },
                  on: { trigger: _vm.openArrangements },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.availability &&
        _vm.availability.amount.options &&
        _vm.availability.amount.options.length
          ? _c(
              "div",
              { staticClass: "availability-options z-20" },
              [
                _c("SDSelectBox", {
                  attrs: {
                    label: _vm.availability.amount.label,
                    placeholder: _vm.availability.amount.placeholder,
                    options: _vm.availability.amount.options,
                    value: _vm.amount,
                    active: _vm.optionsVisible,
                  },
                  on: {
                    close: _vm.closeOptions,
                    open: _vm.openOptions,
                    setOption: _vm.setAmount,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.arrangement &&
        _vm.arrangement.additionalAmount.options &&
        _vm.arrangement.additionalAmount.options.length
          ? _c(
              "div",
              { staticClass: "availability-options z-10" },
              [
                _c("SDSelectBox", {
                  attrs: {
                    label: _vm.arrangement.additionalAmount.label,
                    placeholder: _vm.arrangement.additionalAmount.placeholder,
                    options: _vm.arrangement.additionalAmount.options,
                    value: _vm.additionalAmount,
                    active: _vm.additionalOptionsVisible,
                  },
                  on: {
                    close: _vm.closeAdditional,
                    open: _vm.openAdditionalOptions,
                    setOption: _vm.setAdditionalAmount,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.calendar && _vm.calendar.boxCheckinCheckout
          ? _c(
              "div",
              {
                attrs: { id: "range-placeholder-wrapper" },
                on: { click: _vm.scrollToRangeSelect },
              },
              [
                _c("DateRangePlaceholder", {
                  attrs: { box: _vm.calendar.boxCheckinCheckout },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "deals-availability-calendar",
          attrs: { id: "deals-availability-calendar" },
        },
        [
          _vm.calendar
            ? _c("SDCalendar", {
                attrs: { calendar: _vm.calendar, type: "hotels" },
                on: {
                  update: _vm.updateCalendar,
                  loadMore: _vm.loadMoreCalendar,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { attrs: { id: "timeslot-anchor" } }),
      _vm._v(" "),
      _vm.drawerOrModalActive || _vm.isMobile
        ? _c(
            "BottomDrawer",
            {
              attrs: {
                visible: _vm.drawerOrModalVisible,
                "drawer-title": _vm.drawerTitle,
                "is-in-modal": !_vm.isMobile,
              },
              on: { closeSelf: _vm.closeSelf },
            },
            [
              _c(_vm.view, {
                tag: "component",
                attrs: {
                  alerts: _vm.availability.alerts,
                  items: _vm.arrangementsOrTimeSlots,
                  justify: _vm.isMobile ? "start" : "between",
                },
                on: { select: _vm.selectFromDrawerOrModal },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _vm.timeSlots && _vm.timeSlots.length > 0 && !this.isMobile
            ? _c("TimeSlots", {
                attrs: { items: _vm.timeSlots },
                on: { select: _vm.setTimeSlot },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.activeAlert
        ? _c("Alert", {
            attrs: { alert: _vm.activeAlert },
            on: {
              onConfirm: _vm.openArrangements,
              onDecline: function ($event) {
                _vm.activeAlert = null
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }