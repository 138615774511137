





































import Vue, { PropType } from "vue";
import SingleDayCalenderParams from "../domain/SingleDayCalenderParams";
import SingleDayCalendar from "../domain/SingleDayCalendar";
import CalendarDay from './days/index.vue';
import ButtonTabs from "../../../../../../../frontend/components/ButtonTabs.vue";

export default Vue.extend( {
  name       : 'CalendarSingleDay',
  components : {
    CalendarDay,
    ButtonTabs
  },
  props      : {
    calendar : {
      type     : Object as PropType<SingleDayCalendar>,
      required : true
    },
    type     : {
      type     : String,
      required : true
    }
  },
  data() {
    return {}
  },
  methods : {
    noPreference() {
      const params = SingleDayCalenderParams.fromDay( this.calendar.params.toJSON(), {
        selected : null,
        date_shortcut : null
      } );

      this.$emit( 'updateAndSubmit', params )
    },
    clickDay( day: any ) {
      const params = SingleDayCalenderParams.fromDay( this.calendar.params.toJSON(), day.params );
      this.$emit( 'update', params );
    },
    handleShortcutClick( shortCutParams: any ) {
      const params = SingleDayCalenderParams.fromShortCutParams( this.calendar.params.toJSON(), shortCutParams );
      this.$emit( 'updateAndSubmit', params );
    }
  }
} );
