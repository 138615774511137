import { APIService } from "./APIService";

export const SearchBarTypes = {
    hotels : 'hotels'
}

export default class SearchBarService extends APIService {
    public static getSearchBarByType( type: string, cityUnique: string ): any {
        return this.get<any>( `/proxy/search-bar/${type}/${cityUnique}/`, {
            headers : {
                'x-supports' : 'lmd-optional-date',
                'Accept'     : 'application/vnd.search-bar-api.v3+json'
            }
        } );
    }

    public static updateSearchBar( type: string, cityUnique: string, data: any ): any {
        return this.post<any, any>( `/search-bar/${type}/${cityUnique}/`, data, {
            headers : {
                'Content-Type' : 'application/json',
                'x-supports'   : 'lmd-optional-date'
            }
        } );
    }
}
