import ValueObject from "../../../../../frontend/ValueObject";
import { Price } from "./Price";

export interface IAvailabilityPrices {
    average: Price,
    lowest: Price,
}

export class AvailabilityPrices extends ValueObject<IAvailabilityPrices> {

    get average(): Price {
        return this._props.average;
    }

    get lowest(): Price {
        return this._props.lowest;
    }

    static create( priceObject: any ): AvailabilityPrices {
        const pricePayload : any = {
            lowest : Price.create( {
                amount   : priceObject.lowest.amount / 100,
                currency : priceObject.lowest.currency
            } ),
        }

        if ( priceObject.average ) {
            pricePayload.average = Price.create( {
                amount   : priceObject.average.amount / 100,
                currency : priceObject.average.currency
            } );
        }

        return new AvailabilityPrices( pricePayload );
    }
}
