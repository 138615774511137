






import Vue from "vue";

export default Vue.extend( {
  name  : 'Loader',
  props : {
    type : {
      type    : String,
      default : 'default'
    }
  },
} );
