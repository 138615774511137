import ValueObject from "../../../../../frontend/ValueObject";
import { ISearchHistory } from "./interfaces/ISearchHistory";
import { SearchHistoryItem } from "./SearchHistoryItem";

export class SearchHistory extends ValueObject<ISearchHistory> implements ISearchHistory {
    public get index(): number {
        return this._props.index;
    }
    public get searchHistory(): Array<SearchHistoryItem> {
        return this._props.searchHistory;
    }
    public get title(): string | null {
        return this._props.title;
    }

    public static createFromApi( response : any ) : SearchHistory {
        return new SearchHistory({
            searchHistory: SearchHistoryItem.createFromApi( response.search_history ),
            title: response.title,
            index: response.index
        })
    }
}
