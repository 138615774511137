export const isElementVisible = ( el : HTMLElement ) => {
    var rect    = el.getBoundingClientRect(),
        vWidth  = window.innerWidth || document.documentElement.clientWidth,
        vHeight = window.innerHeight || document.documentElement.clientHeight,
        efp     = function ( x: number, y: number ) {
            return document.elementFromPoint( x, y )
        };

    if ( rect.right < 0 || rect.bottom < 0
         || rect.left > vWidth || rect.top > vHeight ) {
        return false;
    }

    return (
        el.contains( efp( rect.left, rect.top ) )
        || el.contains( efp( rect.right, rect.top ) )
        || el.contains( efp( rect.right, rect.bottom ) )
        || el.contains( efp( rect.left, rect.bottom ) )
    );
}