import ValueObject from "../../../../../frontend/ValueObject";

export interface ITagCloudFilter {
    identifier: string,
    view: string,
    label: string,
    colors: IColorsCollection,
    icon: IIcon,
    state: 'active' | 'default';
}

export interface IIcon {
    image: {
        active: string,
        default: string,
    } | null,
    key: string | null,
    unicode: string | null,
    altText: string | null
}

export interface IColorsCollection {
    active: IColorCollection,
    default: IColorCollection
}

export interface IColorCollection {
    icon: IColor,
    text: IColor,
}

export interface IColor {
    alpha: number,
    red: number,
    green: number,
    blue: number,
    hex: string,
}

export default class TagCloudFilter<T extends ITagCloudFilter> extends ValueObject<T> {
    static fromApi( props: any ) {
        return new TagCloudFilter( props );
    }

    public get identifier(): string {
        return this._props.identifier;
    }

    public get state(): 'active' | 'default' {
        return this._props.state;
    }
    public set state(value: 'active' | 'default') {
        this._props.state = value;
    }
    public get view(): string {
        return this._props.view;
    }

    public get label(): string {
        return this._props.label;
    }

    public get colors(): IColorsCollection {
        return ColorsCollection.fromApi( this._props.colors );
    }

    public icon(): any {
        return Icon.fromApi( {
            ...this._props.icon,
            altText : this._props.label
        } );
    }
}

export class ColorsCollection<T extends IColorsCollection> extends ValueObject<T> {
    static fromApi( props: any ) {
        return new ColorsCollection( props );
    }

    public get active(): IColorCollection {
        return ColorCollection.fromApi( this._props.active );
    }

    public get default(): IColorCollection {
        return ColorCollection.fromApi( this._props.default );
    }
}

export class ColorCollection<T extends IColorCollection> extends ValueObject<T> {
    static fromApi( props: any ) {
        return new ColorCollection( props );
    }

    public get icon(): IColor {
        return this._props.icon;
    }

    public get text(): IColor {
        return this._props.text;
    }
}

export class Color<T extends IColor> extends ValueObject<T> {
    static fromApi( props: any ) {
        return new Color( props );
    }

    public get hex(): string {
        return this._props.hex;
    }
}

export class Icon<T extends IIcon> extends ValueObject<T> {
    static fromApi( props: any ) {
        return new Icon( props );
    }

    public get html(): string {
        const availableKeys = [
            'popular',
            'food',
            'favorite',
            'hotel',
            'events',
            'wellness',
            'sport',
            'store',
            'course',
            'donation',
            'take-away',
            'to-use-now',
        ];
        if ( this._props.key && availableKeys.includes( this._props.key ) ) {
            return this.icon;
        }

        if ( this._props.unicode && this._props.unicode.split( 'U+' ).length - 1 > 0 ) {
            return this.emoji;
        }

        if ( this._props.image.default && this._props.image.active ) {
            return this.images;
        }

        return this.fallback;
    }

    private get emoji(): string {
        const emojis = this._props.unicode.split( ' ' );
        for ( let i = 0; i < emojis.length; i++ ) {
            emojis[ i ] = emojis[ i ].replace( 'U+', '&#x' );
        }

        return '<div class="emoji">' + emojis.join( '' ) + '</div>';
    }

    private get icon(): string {
        return '<div class="sd2-icons tagcloud-' + this._props.key + '"></div>';
    }

    private get images(): string {
        return `
            <img src="${this._props.image.default}" alt="${this._props.altText ?? ''}" width="24" height="24" class="default" />
            <img src="${this._props.image.active}" alt="${this._props.altText ?? ''}" width="24" height="24" class="active hidden" />`;
    }

    private get fallback(): string {
        return '<div class="sd2-icons category-popular"></div>';
    }
}
