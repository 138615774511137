/**
 * Select a cookie by his name
 */
export function getCookie( name: string ): string {
    name = `${name}=`;
    const decodedCookie = decodeURIComponent( document.cookie );
    const ca = decodedCookie.split( ';' );
    for ( let i = 0; i < ca.length; i++ ) {
        let c = ca[ i ];
        while ( c.charAt( 0 ) == ' ' ) {
            c = c.substring( 1 );
        }
        if ( c.indexOf( name ) == 0 ) {
            return c.substring( name.length, c.length );
        }
    }
    return '';
}

/**
 * Simplified document.cookie
 */
export function setCookie( name: string, value: string | number, expireDate?: Date ): void {
    const expires = expireDate !== undefined ? `;expires=${expireDate.toUTCString()}` : '';
    document.cookie = name + '=' + value + expires + ';path=/';
}

/**
 * For GPS cookie only
 */
export function setGpsCookie(): void {
    document.cookie = 'gpsActivated=true;samesite=none;secure;max-age=21600;path=/';
}

/**
 * Remove cookie
 */
export function unsetCookie( name: string ): void {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/';
}

/**
 * Encode text value because of Safari bug: https://stackoverflow.com/questions/45985970/safari-cookie-value-strips-space-after-the-commas
 */
export function setTextCookie( name: string, value: string, expireDate?: Date ): void {
    value = encodeURI( value );
    setCookie( name, value, expireDate );
}

export function getTextCookie( name: string ): string {
    let value = getCookie( name );
    return decodeURI( value );
}