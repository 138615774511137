import ValueObject from "../../../../../../../frontend/ValueObject";
import { Emoji } from "../../../../../../Deals/view/vue/domain/Emoji";

export interface IDayShortcut {
    id: string,
    icon: Emoji,
    label: string,
    params: any,
    type: string,
    unique: string,
}

export default class DateShortcut extends ValueObject<IDayShortcut> {

    public get id(): string {
        return this._props.id;
    }

    public get label(): string {
        return this._props.label;
    }

    public get params(): any {
        return this._props.params;
    }

    public get icon(): Emoji {
        return this._props.icon;
    }

    public get unique(): string {
        return this._props.unique;
    }

    public static create( props: any ) {
        return new DateShortcut( Object.assign( props, {
            icon : new Emoji( props.icon )
        } ) );
    }
}
