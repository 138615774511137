










import Vue, {PropType} from "vue";
import {Experience} from "../../domain/Experience";

export default Vue.extend({
  name: 'ExperienceItem',
  props: {
    experience: {
      type: Object as PropType<Experience>
    }
  },
  methods: {
    handleClick( e: any ) {
      e.preventDefault();

      this.$emit('click', this.experience );
    }
  }
})
