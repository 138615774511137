import ValueObject from "../../../../../frontend/ValueObject";

interface ISelectOption {
    label: string,
    placeholder: string,
    options: any[],
    selected_option?: number,
}

export class AmountSelect extends ValueObject<ISelectOption> {

    get label() : string {
        return this._props.label;
    }

    get placeholder() : string {
        return this._props.placeholder;
    }

    get options() {
        return this._props.options;
    }

    get selectedOptionKey() {
        return this._props.selected_option ?? null;
    }

    get value() {
        return this.options.find((option) => option.key === this.selectedOptionKey )
    }

    public static create( props : any ) {
        return new AmountSelect( props );
    }
}