var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "amount-select" }, [
    _c("div", { staticClass: "select-container" }, [
      _c("div", { staticClass: "select-info" }, [
        _c("div", { staticClass: "amount" }, [
          _vm._v(_vm._s(_vm.valueOption.value_amount)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "as-label" }, [
          _vm._v(_vm._s(_vm.valueOption.value_label)),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "select",
        {
          staticClass: "select",
          attrs: { disabled: _vm.disabled },
          on: { change: _vm.handleSelectChange },
        },
        _vm._l(_vm.options, function (option, optionKey) {
          return _c(
            "option",
            {
              key: optionKey,
              domProps: {
                value: option.key,
                selected: optionKey === _vm.optionIndex,
              },
            },
            [_vm._v("\n        " + _vm._s(option.value) + "\n      ")]
          )
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "buttons" }, [
      _c(
        "div",
        {
          staticClass: "button decrease material-icons",
          class: { blocked: _vm.disabled || _vm.minReached },
          on: { click: _vm.decreaseAmount },
        },
        [_vm._v("remove_circle_outline")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "button increase material-icons",
          class: { blocked: _vm.disabled || _vm.maxReached },
          on: { click: _vm.increaseAmount },
        },
        [_vm._v("add_circle_outline")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }