var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "experience-button",
      attrs: { href: _vm.experience.url },
      on: { click: _vm.handleClick },
    },
    [
      _c("div", {
        staticClass: "experience-emoji",
        domProps: { innerHTML: _vm._s(_vm.experience.unicode_icon) },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "experience-label" }, [
        _vm._v(_vm._s(_vm.experience.label)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }