import ValueObject from "../../../../../frontend/ValueObject";
import { ITagCloudCategoryData } from "./interfaces/ITagCloudCategoryData";
import { ITagCloudCategory } from "./interfaces/ITagCloudCategory";
import { SearchDealsConfiguration } from "./SearchDealsConfiguration";

export class TagCloudCategory extends ValueObject<ITagCloudCategoryData> implements ITagCloudCategory {
    public get name(): string {
        return this._props.name;
    }

    public get searchDealsConfiguration(): SearchDealsConfiguration {
        return SearchDealsConfiguration.createFromTagCloudCategoryData( this._props );
    }

    public static createFromCategoryData( props: ITagCloudCategoryData  ): TagCloudCategory {
        return new TagCloudCategory( props );
    }
}
