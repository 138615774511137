import {APIService} from "./APIService";
import {
    GetFilterCriteriaResponse,
    GetFilterResponse,
    GetHomeFilterSearchResultByCityUniqueResponse, GetNearbyFilterSearchResultByPointResponse
} from "../types/FilterTypes";
import {PricesDTO} from "../../module/Deals/view/vue/domain/dto/PricesDTO";
import { CancelTokenSource } from "axios";

export type DealTravelDistance = {
    origin: null,
    destination: null,
    distance: number,
    duration: string,
    label: null,
    icon: string
}

export interface INearbyFilterResponse {
    unique: string,
    campaign_unique: string,
    marker_key: string,
    is_new_today: boolean,
    is_for_sale: boolean,
    discount: number,
    image: string,
    title: string,
    company_name: string,
    location: string,
    sold: string,
    prices: PricesDTO,
    start_date: string,
    category_id: number,
    sub_category_id: number,
    category_key: string,
    category_color: string,
    latitude: number,
    longitude: number,
    travel_distance: DealTravelDistance,
    review_stats: {
        average: number,
        label: string,
        amount_of_reviews: null,
        stars: number,
        show_total: number,
        total: number,
        total_text: string,
        star1: number,
        star2: number,
        star3: number,
        star4: number,
        star5: number
    },
    city_slug: string,
    company_slug: string,
    deal_slug: string,
    template_urls: {
        absolute: string,
        relative: string
    },
    is_favorite: false,
    is_favorite_button_visible: true,
    pills: [],
    tags: [
        {
            name: string
        }
    ],
    _links: {
        self: {
            href: string
        },
        deal_link: {
            href: string
        }
    }
}

export interface INearbyFilterResponse {
    deals: Array<Array<INearbyFilterResponse>>;
}

export default class FilterService extends APIService {
    public static getHomeFilterCriteriaByCityUnique( cityUnique: string, resetFilters: boolean = false, filterQueryString: string = ''): Promise<GetFilterCriteriaResponse> {
        let resetQueryParam = resetFilters ? 'reset=true' : '';
        return this.get<GetFilterCriteriaResponse>( `/proxy/deal-filter/home/${cityUnique}/filter-criteria/?${filterQueryString}${resetQueryParam}`, {
            headers : this.getHeaders(
                {
                    'Accept': 'application/vnd.deal-filter-api.v1+json'
                }
            ),
        } );
    }

    public static getHomeFilterByCityUnique( cityUnique: string, filterQueryString: string, cancelTokenSource?: CancelTokenSource): Promise<GetFilterResponse> {
        return this.get<GetFilterResponse>( `/proxy/deal-filter/home/${cityUnique}/filters/?${filterQueryString}`, {
            headers : this.getHeaders(
                {
                    'Accept': 'application/vnd.deal-filter-api.v1+json'
                }
            ),
            cancelToken: cancelTokenSource?.token
        } );
    }

    public static getHomeFilterSearchResultByCityUnique( cityUnique: string, filterQueryString: string): Promise<GetHomeFilterSearchResultByCityUniqueResponse> {
        return this.get<any>( `/proxy/deal-filter/home/${cityUnique}/search-result/?${filterQueryString}`, {
            headers : this.getHeaders(
                {
                    'Accept': 'application/vnd.deal-filter-api.v1+json'
                }
            ),
        } );
    }

    public static getNearbyFilterCriteriaByPoint( point: string, resetFilters: boolean = false, filterQueryString: string = ''): Promise<GetFilterCriteriaResponse> {
        let resetQueryParam = resetFilters ? 'reset=true' : '';
        return this.get<GetFilterCriteriaResponse>( `/proxy/deal-filter/nearby/filter-criteria/?point=${point}&${filterQueryString}${resetQueryParam}`, {
            headers : this.getHeaders(
                {
                    'Accept': 'application/vnd.deal-filter-api.v1+json'
                }
            ),
        } );
    }

    public static getNearbyFilterByPoint( point: string, filterQueryString: string, cancelTokenSource?: CancelTokenSource): Promise<GetFilterResponse> {
        return this.get<GetFilterResponse>( `/proxy/deal-filter/nearby/filters/?point=${point}&${filterQueryString}`, {
            headers : this.getHeaders(
                {
                    'Accept': 'application/vnd.deal-filter-api.v1+json'
                }
            ),
            cancelToken: cancelTokenSource?.token
        } );
    }

    public static getNearbyFilterSearchResultByPoint( point: string, filterQueryString: string, pageNumber:number): Promise<GetNearbyFilterSearchResultByPointResponse> {
        return this.get<any>( `/proxy/deal-filter/nearby/search-result/?point=${point}&${filterQueryString}&page=${pageNumber}`, {
            headers : this.getHeaders(
                {
                    'Accept': 'application/vnd.deal-filter-api.v1+json'
                }
            ),
        } );
    }
    public static removeDefaultValuesFromURL(filterQueryParameters: string):string {
        const filterQueryParams = filterQueryParameters.split( '&' );
        let queryParams = [] as any;
        for (let q = 0, qArrLength = filterQueryParams.length; q < qArrLength; q++) {
            let qArr = filterQueryParams[q].split('=');
            if( qArr[0] === '' || qArr[0] === 'flow'  || qArr[1] === 'null' || qArr[1] === '0' || qArr[1] === 'default' || qArr[1] === 'all' ) {
                continue;
            }

            let qKeyName = qArr[0].replace('filter[', '');
            let charPosition = qKeyName.indexOf( ']');
            let qPart1 = qKeyName.substring(0, charPosition);
            let qPart2 = qKeyName.substring(charPosition + 1, qKeyName.length);
            qKeyName = qPart1 + qPart2;

            queryParams[ qKeyName] = qArr[1];
        }
        let url = Object.entries(queryParams)
            .map(a => a.join('='))
            .join('&');
        return url;
    }
}
