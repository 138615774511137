





























































import Vue, {PropType} from "vue";
import SDSelectBox from "../SDSelectBox.vue";
import { PriceRange } from "../../types/FilterTypes";
import Inbox from "../../../module/Account/view/vue/inbox.vue";
interface IData {
  isMinimalSelectionActive: boolean,
  isMaximumSelectionActive: boolean,
  parentBottomPosition:number,
  initialX: number,
  touchDownOnCustomThumb: boolean,
  isMinHandler: boolean,
  leftHandleDragValue: number,
  rightHandleDragValue: number
}
interface IMethods {
  onInput ( event : any, getNewFilters: boolean): void,
  setIsMinimalSelectionActive(isActive: boolean): void ,
  setIsMaximumSelectionActive(isActive: boolean): void,
  handleSelectionOptionClick(isMinimal:boolean, selection:any, getNewFilters:boolean): void,
  handleTouchEnd(): void,
  handleCustomThumbTouchDown(event:TouchEvent, isMinHandler:boolean): void,
  moveHandler(clientX:number): void,
  mouseMoveHandler(event:MouseEvent):void,
  touchMoveHandler(event:TouchEvent):void,
  syncTouchOverlayWithHandle():void
}
interface IComputed {
  visualMinValue: number,
  visualMaxValue: number,
  minimalSliderSelectionOptions: Array<any>,
  maximumSliderSelectionOptions: Array<any>,
  isMobile: boolean,
  isDesktop: boolean
  getMobilePixelPercentage:number,
  computedSliderStyles: {progressLeft:number, progressRight:number } ,
  computedSliderStylesAsPercentages: any,
  getPixelDifferenceToJump: number
}
interface IProps {
 priceRange: PriceRange
}

export type PriceSliderEmit = {
  selectedPrices: { min: number, max: number },
  getNewFilters: boolean
}

export type SliderValues = {
  selectedMinValue: number,
  selectedMaxValue: number
}
export default Vue.extend<IData,IMethods,IComputed,IProps>({
  name: 'PriceSlider',
  components: {Inbox, SDSelectBox },
  props: {
    priceRange: {
      type: Object as PropType<IProps['priceRange']>,
      required: true
    }
  },
  data() {
    return {
      isMinimalSelectionActive: false,
      isMaximumSelectionActive: false,
      parentBottomPosition:0,
      initialX: 0,
      touchDownOnCustomThumb:false,
      isMinHandler: false,
      leftHandleDragValue:0,
      rightHandleDragValue:0
    }
  },
  methods: {
    onInput ( event : any, getNewFilters: boolean) {
      const target = event.target;
      let selectedPrices = Object.assign({}, this.priceRange.selected);

      // check for preventing handles to cross
      if (target.name === 'min' || target.name === 'min-number') {
        if( target.value < this.priceRange.selected.max ) {
          selectedPrices.min = parseFloat(target.value);
        }
        else {
          selectedPrices.min = this.visualMaxValue;
        }
      }
      if (target.name === 'max' || target.name === 'max-number') {
        if( target.value > this.priceRange.selected.min ) {
          selectedPrices.max = parseFloat(target.value);
        }
        else {
          selectedPrices.max = this.visualMinValue;
        }
      }
      const payload = {
        selectedPrices: selectedPrices,
        getNewFilters: getNewFilters
      }
      this.$emit('price-slider-values-changed', payload);
    },
    setIsMinimalSelectionActive(isActive: boolean) {
      this.isMinimalSelectionActive = isActive;
    },
    setIsMaximumSelectionActive(isActive: boolean) {
      this.isMaximumSelectionActive = isActive;
    },
    handleSelectionOptionClick(isMinimal:boolean, selection:any, getNewFilters: boolean = true) {
      //check if selected options is not crossing the other slide, if so set it back to the max value (lowest or highest possible).
      let selectedPrices = Object.assign({}, this.priceRange.selected);
      if(isMinimal) {
        selectedPrices.min = selection.key > this.priceRange.selected.max ? this.visualMaxValue : selection.key;
      } else {
        selectedPrices.max = selection.key < this.priceRange.selected.min ? this.visualMinValue : selection.key;
      }
      const payload = {
        selectedPrices: selectedPrices,
        getNewFilters: getNewFilters
      }
      this.$emit('price-slider-values-changed', payload);
    },
    handleTouchEnd() {
      if(this.touchDownOnCustomThumb) {
        let selectedPrices = Object.assign({}, this.priceRange.selected);
        const payload = {
          selectedPrices: selectedPrices,
          getNewFilters: true
        }
        this.$emit('price-slider-values-changed', payload);
        this.touchDownOnCustomThumb = false;
      }
    },
    handleCustomThumbTouchDown(event:TouchEvent, isMinHandler:boolean) {
      this.initialX = Math.floor(event.touches[0].clientX);
      this.isMinHandler = isMinHandler;
      this.touchDownOnCustomThumb = true;
    },
    moveHandler(clientX:number) {
      if(this.touchDownOnCustomThumb) {
        const isRight = clientX - this.initialX >= this.getPixelDifferenceToJump;
        const isLeft = this.initialX - clientX >= this.getPixelDifferenceToJump;
        if(this.isMinHandler) {
          if(isRight) {
            if(this.priceRange.selected.min + this.priceRange.step_size !== this.priceRange.selected.max) {
              const newValue = this.priceRange.selected.min + (this.priceRange.step_size * Math.floor((clientX - this.initialX) / this.getPixelDifferenceToJump));
              this.handleSelectionOptionClick(true, {key: newValue},false)
            }
          } else if(isLeft) {
            const newValue = this.priceRange.selected.min - (this.priceRange.step_size * Math.floor((this.initialX - clientX) / this.getPixelDifferenceToJump));
            this.handleSelectionOptionClick(true, {key: newValue >= this.priceRange.min_value ? newValue : this.priceRange.min_value},false)
          }

          if(isRight || isLeft) {
            this.initialX = clientX;
          }
        } else {
          if(isRight) {
            const newValue = this.priceRange.selected.max + (this.priceRange.step_size * Math.floor(( clientX - this.initialX) / this.getPixelDifferenceToJump));
            this.handleSelectionOptionClick(false, {key: newValue <= this.priceRange.max_value ? newValue : this.priceRange.max_value},false)
          } else if(isLeft) {
            const newValue = this.priceRange.selected.max - (this.priceRange.step_size * Math.floor(( this.initialX - clientX) / this.getPixelDifferenceToJump));
            if(this.priceRange.selected.max - this.priceRange.step_size !== this.priceRange.selected.min) {
              this.handleSelectionOptionClick(false, {key: newValue},false)
            }
          }

          if(isRight || isLeft) {
            this.initialX = clientX;
          }
        }
      }
    },
    syncTouchOverlayWithHandle() {
      if(!this.isDesktop && this.$refs.minThumbOverlay && this.$refs.maxThumbOverlay) {
        (this.$refs.minThumbOverlay as HTMLElement).style.left = `calc(${this.getMobilePixelPercentage}px * ${this.computedSliderStyles.progressLeft})`;
        (this.$refs.maxThumbOverlay as HTMLElement).style.right = `calc(${this.getMobilePixelPercentage}px * ${this.computedSliderStyles.progressRight})`;
      }
    },
    mouseMoveHandler(event) {
      this.moveHandler(event.clientX);
    },
    touchMoveHandler(event) {
      this.moveHandler(Math.floor(event.touches[0].clientX));
    }
  },
  computed: {
    visualMinValue(): number {
      return this.priceRange.selected.min + this.priceRange.step_size
    },
    visualMaxValue(): number {
      return this.priceRange.selected.max - this.priceRange.step_size
    },
    minimalSliderSelectionOptions(): Array<any> {
      let numberArray = [];
      const endNumber = this.visualMaxValue;
      const locale = document.body.getAttribute('data-locale')?.toUpperCase() ?? 'NL';
      const priceSymbolFirst = locale !== 'DE' && locale !== 'FR';
      for(let number = this.priceRange.min_value; number <= endNumber; number += this.priceRange.step_size) {
        let price = priceSymbolFirst ? this.priceRange.currency.symbol + ' ' + number : number + ' ' + this.priceRange.currency.symbol;
        numberArray.push({key: number, value: price});
      }
      return numberArray;
    },
    maximumSliderSelectionOptions(): Array<any> {
      let numberArray = [];
      const startNumber = this.visualMinValue;
      const locale = document.body.getAttribute('data-locale')?.toUpperCase() ?? 'NL';
      const priceSymbolFirst = locale !== 'DE' && locale !== 'FR';
      for(let number = startNumber; number <= this.priceRange.max_value; number += this.priceRange.step_size) {
        let price = priceSymbolFirst ? this.priceRange.currency.symbol + ' ' + number : number + ' ' + this.priceRange.currency.symbol;
        numberArray.push({key: number, value: price});
      }
      return numberArray;
    },
    getMobilePixelPercentage():number {
      //40 because the inputs are 20px smaller and you want to keep half the width of the overlay as distance from the end to the border. so it becomes 40px
      return ((this.$refs.slider as HTMLElement).clientWidth - 40) / 100;
    },
    isMobile(): boolean {
      return sdViewport.isMobile();
    },
    isDesktop(): boolean {
      return sdViewport.isDesktop();
    },
    computedSliderStyles() {
      return {
        progressLeft: Math.round(((this.priceRange.selected.min - this.priceRange.min_value) / (this.priceRange.max_value - this.priceRange.min_value)) * 100),
        progressRight: 100 - (Math.round(((this.priceRange.selected.max - this.priceRange.min_value) / (this.priceRange.max_value - this.priceRange.min_value)) * 100))
      }
    },
    getPixelDifferenceToJump(): number {
      return (this.priceRange.step_size / this.priceRange.max_value) * ((this.$refs.slider as HTMLElement).clientWidth - (this.isMobile ? 100 : 80));
    },
    computedSliderStylesAsPercentages() {
      if(!this.isDesktop) {
        this.syncTouchOverlayWithHandle();
      }
      return {
        '--progressLeft': this.computedSliderStyles.progressLeft + '%',
        '--progressRight': this.computedSliderStyles.progressRight + '%'
      }
    }
  },
  async mounted() {
    document.addEventListener('mouseup', this.handleTouchEnd);
    document.addEventListener('mousemove', this.mouseMoveHandler);
    document.addEventListener('touchmove', this.touchMoveHandler);
    this.parentBottomPosition = document.getElementsByClassName('drawer-content')[0].getBoundingClientRect().bottom;
  },
  beforeDestroy() {
    document.removeEventListener('mouseup', this.handleTouchEnd);
    document.removeEventListener('mousemove', this.mouseMoveHandler);
    document.removeEventListener('touchmove', this.touchMoveHandler);
  }
});
