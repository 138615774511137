














import Vue from "vue";

export default Vue.extend( {
  name  : 'TimeSlots',
  props : {
    justify : {
      type    : String,
      default : 'center'
    },
    items   : {
      type     : Array,
      required : true
    }
  }
} );

