











import Vue from "vue";

export default Vue.extend( {
  name  : 'SearchBarInfo',
  props : {
    labels : {
      type     : Object,
      required : true
    },
  }
} );
