var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isForSale
      ? _c(
          "div",
          {
            staticClass: "cart-button",
            on: {
              click: function ($event) {
                return _vm.$emit("submit")
              },
            },
          },
          [
            _vm.summary
              ? _c("div", { staticClass: "summary" }, [
                  _vm._v("\n      " + _vm._s(_vm.summary) + "\n    "),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "link-text" }, [
              _c("span", { staticClass: "sd-icons nav-icon notranslate" }, [
                _vm._v("hotel"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "link-text--center" }, [
                _c("div", { staticClass: "link-text--top" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("12833.App_hotelDetailsBookButtonTitle")) +
                      " "
                  ),
                  _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.priceString) },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "link-text--bottom" }, [
                  _vm._v("\n          " + _vm._s(_vm.caption) + "\n        "),
                ]),
              ]),
              _vm._v(" "),
              _c("i", {
                staticClass: "material-icons arrow-icon",
                attrs: { "data-ligature": "chevron_right" },
              }),
            ]),
          ]
        )
      : _c(
          "div",
          {
            staticClass: "cart-button sold-out",
            on: {
              click: function ($event) {
                return _vm.$emit("submit")
              },
            },
          },
          [
            _c("div", { staticClass: "link-text" }, [
              _c("span", { staticClass: "sd-icons nav-icon notranslate" }, [
                _vm._v("\n        " + _vm._s(_vm.icon) + "\n      "),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "link-text--top" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("12835.App_hotelDetailsBookButtonSoldOut")) +
                    "\n      "
                ),
              ]),
            ]),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }