var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "popular-phrase-item-container",
      style: { borderColor: _vm.item.highlightedBorderColor.hex },
    },
    [
      _c(
        "div",
        {
          staticClass: "popular-phrase-item-button",
          style: {
            backgroundColor: _vm.item.backgroundColor.hex,
            borderColor: _vm.item.backgroundColor.hex,
            color: _vm.item.textColor.hex,
          },
          on: { click: _vm.handleClick },
        },
        [_vm._v("\n    " + _vm._s(_vm.item.label) + "\n  ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }