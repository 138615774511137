import {Price} from "./Price";
import {PricesDTO} from "./dto/PricesDTO";

export type PriceResponse =  {
    price?: Price
    fromPrice?: Price | null
    priceLabel?: string
    discountLabel?:string
}

export class Prices
{
    price: Price
    fromPrice?: Price
    priceLabel?: string
    discountLabel?:string

    constructor(prices: PricesDTO) {
        if(prices) {
            this.price = prices.price ? Price.createPrice(prices.price) : null;
            this.fromPrice = prices.from_price ? Price.createPrice(prices.from_price): null;
            this.priceLabel = prices.price_label;
            this.discountLabel = prices.discount_label;
        }
    }

    hasDiscountPrice(): boolean {
        return this.price?.amount < this.fromPrice?.amount
    }

    showLabelInsteadOfPrice(): boolean {
        return this.price == null && this.priceLabel != null;
    }
    hasFromPrice(): boolean {
        return this.fromPrice != null;
    }
    hasPrice(): boolean {
        return this.price != null;
    }
}
