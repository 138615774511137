import ValueObject from "../../../../../../../frontend/ValueObject";
import Day, { ICalendarDay } from "./Day";

export interface ICalendarWeek {
    days: Day[],
    week: Week
}

export default class Week extends ValueObject<ICalendarWeek> {

    get days() {
        return this._props.days;
    }

    get week() : Week {
        return this._props.week;
    }

    public static create( props: any ) {
        return new Week( {
            days : props.days.map( ( day: any ) => Day.create( day ) ),
            week : props.week
        } );
    }
}