













































import Vue from "vue";
import { isElementVisible } from "../helper/isElementVisible";

export default Vue.extend( {
  name  : 'SDSelectBox',
  props : {
    label       : {
      type     : String,
      required : true
    },
    placeholder : {
      type     : String,
      required : true
    },
    options     : {
      type     : Array,
      required : true
    },
    active      : {
      type     : Boolean,
      required : true
    },
    value       : {
      type : [ String, Number ] || null
    },
    // It determines when the option list extends to the top or to the bottom, Default is bottom
    parentBottomPosition : {
      type     : Number,
      required : false,
    }
  },
  data() {
    return {
      forceActive : false,
      optionsOffsetClass: 'top-14',
      setZIndexTo0: true
    }
  },
  mounted() {
    window.addEventListener( 'scroll', this.checkBoxVisibility );
  },
  beforeDestroy() {
    window.removeEventListener( 'scroll', this.checkBoxVisibility );
  },
  methods  : {
    setOptionViaEventValueKey( event : any ) {
      this.setOption(this.options[event.target.value])
    },
    checkBoxVisibility() {
      if ( ( this.active || this.forceActive
           ) && !isElementVisible( ( this.$refs.sdSelectBox as HTMLElement
      ) ) ) {
        this.closeOptions();
      }
    },
    handleClickOutsideElement(event: MouseEvent) {
      const selectBox = this.$refs.sdSelectBox as HTMLElement;
      const target = event.target as HTMLElement;
      if (!selectBox?.contains(target)) {
        // The user clicked outside the element.
        this.closeOptions();
      }
    },
    forceActiveUI( e: any ) {
      this.forceActive = !this.forceActive;
    },
    setOption( option: any ) {
      this.$emit( 'setOption', option );
      this.closeOptions();
    },
    toggleOptions() {
      this.active ? this.closeOptions() : this.openOptions();
    },
    setOptionsOffsetClass(): void {
      const maxHeightOptionsList = 320;
      const selectedBoxBottomPosition = (this.$refs.sdSelectBox as HTMLElement)?.getBoundingClientRect().bottom;

      if(this.parentBottomPosition) {
        if(this.parentBottomPosition - selectedBoxBottomPosition >= maxHeightOptionsList) {
          this.optionsOffsetClass = 'top-14';
        } else {
          this.optionsOffsetClass = 'bottom-14';
        }
      }
    },
    openOptions() {
      document.addEventListener( 'mousedown', this.handleClickOutsideElement );
      // deze twee regels moet ik aftrappen zodra die active changed,
      // maar watcher mag niet enzo, dus weer props event shit doen om het naar boven te halen?
      this.setOptionsOffsetClass();
      this.setZIndexTo0 = false;

      this.$emit( 'open' );
    },
    closeOptions() {
      document.removeEventListener( 'mousedown', this.handleClickOutsideElement );
      this.setZIndexTo0 = true;
      this.$emit( 'close' );
    }
  },
  computed : {
    isOptionsActive():boolean {
      this.active ? this.openOptions() : this.closeOptions();
      return this.active;
    },
    currentPlaceholder(): any {
      const valueByKeyProperty: any = this.options.find( ( option: any ) => option.key === this.value );
      let valueByIndex:any = undefined;
      if(typeof this.value === 'number') {
        valueByIndex = this.options[ this.value ];
      }

      if ( valueByKeyProperty ) {
        return valueByKeyProperty.value;
      }

      if ( valueByIndex ) {
        return valueByIndex.value;
      }

      return this.placeholder;
    }
  }
} );

