




export default {
  name: 'DateTimeLabel',
  props: {
    label: {
      type: String
    }
  }
}
