


















import Vue from "vue";

export default Vue.extend( {
  name     : 'SDFakeSelectBox',
  props    : {
    placeholder : {
      type     : String,
      required : true
    },
    label       : {
      type     : String,
      required : true
    },
    value       : {
      type    : Object,
      default : null
    }
  },
  methods  : {
    triggerSelf() {
      this.$emit( 'trigger' )
    }
  },
  computed : {
    valueOrPlaceholder(): string {
      return this.value?.description ?? this.placeholder
    }
  }
} );

