











import Vue from "vue";
import InboxService from "../../../../../frontend/services/InboxService";
import {getCookie, setCookie} from "../../../../Component/view/ts/cookies";
import {UnreadMessageCountResponse} from "../../../../../frontend/types/InboxTypes";

interface Data {
    unreadMessageCount: UnreadMessageCountResponse,
    isBadgeVisible: boolean,
    isDotVisible: boolean
}

interface IMethods {
    openInbox: () => void
    setBadgeShownCookie: () => void;
}

interface IComputed {
    isInline: boolean,
}

interface IProps {
    direction: string
}

export default Vue.extend<Data, IMethods, IComputed, IProps>({
    props: {
        direction: {
            type: String,
            default: 'inline'
        }
    },
    data() {
        return {
            unreadMessageCount: {
                count: undefined,
                has_unread_messages: undefined,
                popup_duration_seconds: undefined
            },
            isBadgeVisible: false,
            isDotVisible: false,
        }
    },
    async mounted() {
        this.unreadMessageCount = await InboxService.getPresentationUnreadMessageCount();
        const hasUnreadMessages = this.unreadMessageCount.has_unread_messages;
        let setBadgeVisible, setDotVisible = false;

        setBadgeVisible = hasUnreadMessages;
        if (!this.isInline && getCookie('hideInboxBadge')) {
            setBadgeVisible = false;
            if (hasUnreadMessages) {
                setDotVisible = true;
            }
        }

        this.isBadgeVisible = setBadgeVisible;
        this.isDotVisible = setDotVisible;

        if (!this.isInline && this.isBadgeVisible) {
           setTimeout(() => {
                this.isBadgeVisible = false;
                this.isDotVisible = true;

                this.setBadgeShownCookie();
            }, this.unreadMessageCount.popup_duration_seconds * 1000)
        }
    },
    computed: {
        isInline(): boolean {
            return this.direction === 'inline';
        }
    },
    methods: {
        openInbox() {
            window.loadModal('inbox');
        },
        setBadgeShownCookie() {
            const expireDate = new Date();
            expireDate.setUTCHours(23, 59, 59, 999);
            setCookie('hideInboxBadge', 'true', expireDate);
        }
    }
});
