var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { ref: "slider", staticClass: "custom-slider minmax" }, [
      _c("div", {
        staticClass: "minmax-indicator",
        style: _vm.computedSliderStylesAsPercentages,
      }),
      _vm._v(" "),
      _c("input", {
        attrs: {
          type: "range",
          name: "min",
          id: "min",
          min: _vm.priceRange.min_value,
          max: _vm.priceRange.max_value,
          step: _vm.priceRange.step_size,
        },
        domProps: { value: _vm.priceRange.selected.min },
        on: {
          input: function ($event) {
            return _vm.onInput($event, false)
          },
          mouseup: function ($event) {
            return _vm.onInput($event, true)
          },
          touchend: function ($event) {
            return _vm.onInput($event, true)
          },
        },
      }),
      _vm._v(" "),
      _c("input", {
        attrs: {
          type: "range",
          name: "max",
          id: "max",
          min: _vm.priceRange.min_value,
          max: _vm.priceRange.max_value,
          step: _vm.priceRange.step_size,
        },
        domProps: { value: _vm.priceRange.selected.max },
        on: {
          input: function ($event) {
            return _vm.onInput($event, false)
          },
          mouseup: function ($event) {
            return _vm.onInput($event, true)
          },
          touchend: function ($event) {
            return _vm.onInput($event, true)
          },
        },
      }),
      _vm._v(" "),
      _c("div", {
        ref: "minThumbOverlay",
        staticClass: "thumb-overlay",
        attrs: { id: "min-thumb-overlay" },
        on: {
          touchstart: function ($event) {
            return _vm.handleCustomThumbTouchDown($event, true)
          },
          touchend: _vm.handleTouchEnd,
        },
      }),
      _vm._v(" "),
      _c("div", {
        ref: "maxThumbOverlay",
        staticClass: "thumb-overlay",
        attrs: { id: "max-thumb-overlay" },
        on: {
          touchstart: function ($event) {
            return _vm.handleCustomThumbTouchDown($event, false)
          },
          touchend: _vm.handleTouchEnd,
        },
      }),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "minmax-inputs" },
      [
        _c("SDSelectBox", {
          attrs: {
            label: _vm.$t("36103.App_FilterMinimumPriceInput"),
            placeholder: "",
            options: _vm.minimalSliderSelectionOptions,
            active: _vm.isMinimalSelectionActive,
            value: _vm.priceRange.selected.min,
            "parent-bottom-position": _vm.parentBottomPosition,
          },
          on: {
            open: function ($event) {
              return _vm.setIsMinimalSelectionActive(true)
            },
            close: function ($event) {
              return _vm.setIsMinimalSelectionActive(false)
            },
            setOption: function ($event) {
              return _vm.handleSelectionOptionClick(true, $event)
            },
          },
        }),
        _vm._v(" "),
        _c("SDSelectBox", {
          attrs: {
            label: _vm.$t("36104.App_FilterMaximumPriceInput"),
            placeholder: "",
            options: _vm.maximumSliderSelectionOptions,
            active: _vm.isMaximumSelectionActive,
            value: _vm.priceRange.selected.max,
            "parent-bottom-position": _vm.parentBottomPosition,
          },
          on: {
            open: function ($event) {
              return _vm.setIsMaximumSelectionActive(true)
            },
            close: function ($event) {
              return _vm.setIsMaximumSelectionActive(false)
            },
            setOption: function ($event) {
              return _vm.handleSelectionOptionClick(false, $event)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }