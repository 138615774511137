var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-location", on: { click: _vm.onClick } },
    [
      _c("div", { staticClass: "input-label" }, [
        _vm._v(_vm._s(_vm.$t("37424.App_FilterNearbyLocationInputTitle"))),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "input-placeholder" }, [
        _vm._v(
          "\n    " + _vm._s(_vm.decodeEntities(_vm.locationOrNearby)) + "\n  "
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }