


























































import Vue from "vue";
import CityService, { Prediction } from "../../../../../frontend/services/CityService";
import { debounce } from "@s-libs/micro-dash";
import Loader from "../../../../../frontend/components/Loader.vue";
import { ICity } from "../domain/City";
import { useGps } from "../../js/useGps";

interface ICitySelect {
  isLoading: boolean,
  query: string | null;
  isOpen: boolean;
  predictions: Prediction[];
  cityGroups: Array<{
    cities: ICity[],
    label: string | null
  }>
}

export default Vue.extend( {
  name       : "CitySelect",
  components : {
    Loader
  },
  props      : {
    labels : {
      type     : Object,
      required : true
    },
    value  : {
      type     : String,
      required : false
    },
    isOpened : {
      type     : Boolean,
      required : false
    },
    isInFilter : {
      type     : Boolean,
      required : false
    }
  },
  data() {
    return {
      isLoading   : false,
      query       : '',
      isOpen      : this.isOpened,
      isFocussed  : false,
      predictions : [] as any[],
      emptyStateActive: false,
      cityGroups  : [
        {
          cities : [],
          label  : null
        }
      ]
    }
  },
  async mounted() {
    this.isLoading = true;
    this.cityGroups = await CityService.getCities();
    this.isLoading = false;
  },
  methods : {
    decodeEntities(encodedString: string) : string {
      const textArea = document.createElement('textarea');
      textArea.innerHTML = encodedString;
      return textArea.value;
    },
    preQuery( e: KeyboardEvent ) {
      const target = (e.target as HTMLInputElement);
      this.query = target.value;

      this.debounce( async () => {
        if ( this.query && this.query.length > 2 ) {
          const autoCompleteResponse = await CityService.autocomplete( target.value );

          let newPredictions = [] as any[];
          autoCompleteResponse.data.predictions.forEach( ( prediction: Prediction ) => newPredictions.push( prediction ) );
          this.predictions = newPredictions;
          this.emptyStateActive = newPredictions.length === 0;
        } else {
          this.emptyStateActive = false;
        }
      } );
    },
    debounce : debounce( ( callback: Function ): any => {
          return callback();
        }, 300
    ),
    async selectPrediction( prediction: any ) {
      let locationResponse = await CityService.getLocation( {
        query    : prediction.description,
        place_id : prediction.place_id
      } );
      locationResponse.query = locationResponse.query?.replace( new RegExp( "\\+", "g" ), ' ' );
      const city: ICity = {
        location  : locationResponse.query,
        latitude  : locationResponse.latitude,
        longitude : locationResponse.longitude,
        place_id  : locationResponse.place_id,
        locationLabel: prediction.description
      }
      this.$emit( 'select', city );

      this.predictions = [];
      this.query = '';
      if(!this.isInFilter) {
        this.isOpen = false;
      }
      await this.addHistoryAndReloadCities( city );
    },
    async addHistoryAndReloadCities( city: any ) {
      await CityService.addHistory( {
        name    : this.decodeEntities( city.locationLabel ?? city.location ),
        latitude : city.latitude,
        longitude : city.longitude,
      } );
      setTimeout( async () => {
        this.cityGroups = await CityService.getCities();
      }, 500);
    },
    async doGps() {
      this.isLoading = true;
      const { getCurrentPosition } = useGps();

      try {
        const gpsLocation = await getCurrentPosition();
        this.selectCity( null, {
          location  : null,
          latitude  : gpsLocation.latitude,
          longitude : gpsLocation.longitude
        } )
        if(!this.isInFilter) {
          this.isOpen = false;
        }
      } catch ( e ) {
        if(this.isInFilter) {
          const title = (this as any).$t( 'gpsIsNotAllowedTitle' );
          const message = (this as any).$t( 'gpsIsNotAllowed' );
          const buttonOk = (this as any).$t( 'gpsIsNotAllowedButton' );
          const buttonBack = (this as any).$t( 'Back' );
          filterAlertWithCallback( title,message, buttonOk,buttonBack);
        } else {
          simpleAlert( (this as any).$t( 'gpsIsNotAllowedTitle' ), (this as any).$t( 'gpsIsNotAllowed' ) );
        }
      } finally {
        this.isLoading = false;
      }
    },
    selectCity( e: any, city: any ) {
      this.$emit( 'select', city );
      if(!this.isInFilter) {
        this.isOpen = false;
      }
      this.$parent.$el.scrollTo( {
        top      : 0,
        behavior : 'smooth'
      } )

      this.addHistoryAndReloadCities( city );
    },
    openCitySelect() {
      this.isOpen = true;
      if ( this.$refs.cityInput ) {
        this.$nextTick( () => {
          (this.$refs.cityInput as HTMLFormElement).focus();
        } )
      }
    },
  },
} );
