
































































import Vue, { PropType } from "vue";
import CitySelect from './components/CitySelect.vue';
import CalendarService, { CalendarFlows } from "../../../../frontend/services/CalendarService";
import AmountSelect from "./../../../../frontend/components/AmountSelect.vue";
import HotelsSearchbar from "./domain/HotelsSearchbar";
import SDCalendarInstance from "../../../Availability/submodule/Calendar/view/vue/domain/SDCalendarInstance";
import SDCalendar from "../../../Availability/submodule/Calendar/view/vue/components/SDCalendar.vue";
import Loader from "../../../../frontend/components/Loader.vue";
import { ICity } from "./domain/City";
import SDCalendarParams from "../../../Availability/submodule/Calendar/view/vue/domain/SDCalendarParams";
import ModalSubmitButton from "./ModalSubmitButton.vue";
import ButtonTabs from "../../../../frontend/components/ButtonTabs.vue";
import DateRangePlaceholder from "../../../../frontend/components/DateRangePlaceholder.vue";

export default Vue.extend( {
  name       : 'HotelsModal',
  props      : {
    defaultSearchBar : {
      type     : Object as PropType<HotelsSearchbar>,
      required : true
    },
    defaultLabels    : {
      type     : Object,
      required : true
    },
    active           : {
      type    : Boolean,
      default : false
    },
    gpsLabel         : {
      type     : String,
      required : true
    }
  },
  data       : function () {
    return {
      labels          : this.defaultLabels,
      calendarLoading : false,
      searchBar       : this.defaultSearchBar,
      calendar        : null as any
    }
  },
  components : {
    ButtonTabs,
    SDCalendar,
    AmountSelect,
    CitySelect,
    Loader,
    DateRangePlaceholder,
    ModalSubmitButton
  },
  async mounted(): Promise<any> {
    await this.updateCalendar( {
      selection_from : this.searchBar.fromDate,
      selection_till : this.searchBar.tillDate,
      date_shortcut  : this.searchBar.dateShortcut,
    } );
  },
  methods  : {
    handleShortcutClick( params: any ) {
      this.updateCalendar( params )
    },
    async updateCalendarAndSubmit( params: SDCalendarParams ): Promise<any> {
      await this.updateCalendar( params );
      this.submit();
    },
    async updateCalendar( params: any ): Promise<any> {
      this.calendarLoading = true;
      const calendar = await CalendarService.getCalendarByFlow(
          CalendarFlows.hotel_search_bar_date_range_calendar,
          this.searchBar.numRooms,
          this.searchBar.cityUnique,
          params,
      );
      if ( calendar ) {
        const dateRangeCalendar = SDCalendarInstance.fromApi( calendar );
        this.searchBar = HotelsSearchbar.fromCalendar( this.searchBar, dateRangeCalendar );
        this.calendar = dateRangeCalendar;
      }
      this.calendarLoading = false;
    },
    handleNoPreferenceClick() {
      this.updateCalendar( this.calendar.unselect.params )
    },
    setCity( city: ICity ) {
      city.unique = city.unique ?? null;
      city.location = city.location ?? city.locationLabel;

      this.labels.title = city.location ?? this.gpsLabel;
      this.searchBar.setLocation( city );
    },
    setAmount( amountOptionKey: number ): void {
      this.searchBar.setAmount( amountOptionKey );
      this.updateCalendar( {
        selection_from : this.searchBar.fromDate,
        selection_till : this.searchBar.tillDate
      } );
    },
    closeSelf(): void {
      this.$emit( 'close' );
    },
    submit(): void {
      this.$emit( 'submit', this.searchBar );
    }
  },
  computed : {
    placeholder(): any {
      return this.$store.state.placeholder;
    }
  },
} );
