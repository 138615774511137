
































import BuyNowButton from "./BuyNowButton";

export default BuyNowButton.extend(
    {
      name : 'MultiFlexBuyNowButton',
      computed : {
        getButtonText(): string {
          if ( !this.priceString ) {
            return ( this as any ).$t( '28272.App_HotelDealAvailabilityBuyNowCartButtonTitle' );
          }

          return `${ (this as any ).$t( '12833.App_hotelDetailsBookButtonTitle' ) } ${ this.priceString}`;
        }
      }
    } );
