









import Vue, {PropType} from "vue";
import {SearchHistoryItem} from "../../domain/SearchHistoryItem";

export default Vue.extend({
  name: "SearchHistoryItem",
  props: {
    search: {
      type: Object as PropType<SearchHistoryItem>
    }
  },
  methods: {
    handleClick(): void {
      this.$emit('click', this.search );
    }
  },
  computed: {
    keyword(): string {
      return this.search.query ?? this.search.match;
    }
  }
})
