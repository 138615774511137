



























import Vue, { PropType } from "vue";

export interface ITag {
  identifier: string,
  view: string,
  label: string,
  colors: {
    active: {
      icon: IColor,
      text: IColor,
    },
    default: {
      icon: IColor,
      text: IColor,
    }
  },
  icon: {
    image: {
      active: string,
      default: string,
    } | null,
    key: string | null,
    unicode: string | null
  },
  state: 'active' | 'default'
}

export interface IColor {
  alpha: number,
  red: number,
  green: number,
  blue: number,
  hex: string,
}

export default Vue.extend( {
  name    : 'TagSelect',
  props   : {
    value : {
      type     : String as PropType<string>,
      required : true
    },
    tags  : {
      type     : Array as PropType<ITag[]>,
      required : true
    }
  },
  methods : {
    selectTag( tag: any ) {
      this.$emit( 'select', tag );
    }
  }
} );
