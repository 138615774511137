var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "tag-grid" },
      _vm._l(_vm.tags, function (tagCloudEntity, tagCloudEntityKey) {
        return _c(
          "div",
          {
            key: tagCloudEntityKey,
            staticClass: "tag-mobile",
            class: [
              tagCloudEntity.state === "active" ? "is-active" : "",
              tagCloudEntity.view === "inspiration" ? "is-inspiration" : "",
            ],
            style: {
              borderColor: tagCloudEntity.colors[tagCloudEntity.state].text.hex,
              color: tagCloudEntity.colors[tagCloudEntity.state].text.hex,
            },
            on: {
              click: function ($event) {
                return _vm.selectTag(tagCloudEntity)
              },
            },
          },
          [
            _c("div", {
              staticClass: "icon",
              style:
                "color: " +
                tagCloudEntity.colors[tagCloudEntity.state].icon.hex,
              domProps: { innerHTML: _vm._s(tagCloudEntity.icon().html) },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "tag-label",
                style:
                  "color: " +
                  tagCloudEntity.colors[tagCloudEntity.state].text.hex,
              },
              [_vm._v("\n        " + _vm._s(tagCloudEntity.label) + "\n      ")]
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }