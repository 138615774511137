







import Vue, { PropType } from "vue";
import { SearchPredictionSuggestion } from "../../../../../../frontend/services/SearchService";

export default Vue.extend( {
  name     : 'AutocompleteSuggestionItem',
  props    : {
    suggestion  : {
      type     : Object as PropType<SearchPredictionSuggestion>,
      required : true
    },
    searchQuery : {
      type     : String,
      required : true
    }
  },
  computed : {
    icon(): string {
      const icons : Record<string, string> = {
        'search': 'search',
        'location_on': 'pointer',
        'store': 'store'
      }

      return icons[ this.suggestion.icon ];
    },
    shouldHighlight(): boolean {
      const searchQuery = this.searchQuery.toLowerCase();
      const suggestionMatch = this.suggestion.match.toLowerCase();

      return suggestionMatch.startsWith( searchQuery );
    },
    searchLabel(): string {
      if ( this.shouldHighlight ) {
        return this.suggestion.match.substr( 0, this.searchQuery.length );
      }

      return this.suggestion.match;
    },
    highlightLabel(): string {
      if ( this.shouldHighlight ) {
        return this.suggestion.match.substr( this.searchQuery.length, this.suggestion.match.length );
      }

      return ``;
    }
  },
  methods  : {
    handleClick(): void {
      this.$emit( 'click', this.suggestion );
    }
  }
} )
