



































































import Vue, { PropType } from "vue";
import { scrollIntoView } from "seamless-scroll-polyfill";
import { groupBy, values } from "@s-libs/micro-dash";

export default Vue.extend( {
  name  : 'ItemCollapseView',
  props : {
    items : {
      type     : Array,
      required : true
    }
  },
  data() {
    return {
      activeItem : null
    }
  },
  methods : {
    collapse( key: any ) {
      this.activeItem = key;
      let itemElement = (this.$refs.tabs as HTMLElement[])[ key ];
      setTimeout( () => {
        scrollIntoView(itemElement, { behavior: "smooth", block: "start" });
      }, 50 );
    },
    collapseLast() {
      this.activeItem = 'last';
      setTimeout( () => {
        scrollIntoView((this.$refs.all as HTMLElement), { behavior: "smooth", block: "start" });
      }, 50 );
    },
    select( item: any ) {
      this.$emit( 'select', item );
    }
  },
  computed : {
    itemsAsTabs() : any {
      return values(groupBy( this.items, ( x: any ) => x.title ))
    }
  }
} )
