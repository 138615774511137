import ValueObject from "../../../../../frontend/ValueObject";

export interface ISearchBar {
    title: string,
    description: string,
    location: string,
    latitude: string,
    longitude: string,
    city_unique: string
}

export abstract class SearchBar<T extends ISearchBar> extends ValueObject<T> {
    public get title() {
        return this._props.title;
    }

    public get description() {
        return this._props.description;
    }

    public get cityUnique() {
        return this._props.city_unique;
    }

    public get location() {
        return this._props.location;
    }

    public get labels() {
        return {
            title       : this._props.title,
            description : this._props.description,
        }
    }

    public get latitude() {
        return this._props.latitude;
    }

    public get longitude() {
        return this._props.longitude;
    }

    public get latLng() {
        return {
            lat : this.latitude,
            lng : this.longitude
        }
    }

    public setLocation( cityDTO: any ) {
        Object.assign( this._props, cityDTO )
    }

    public abstract toSearchBarDTO(): T;
}
