





























import BuyNowButton from "./BuyNowButton";

export default BuyNowButton.extend( {
  name     : 'RestaurantsBuyNowButton',
  computed : {
    buttonText() {
      if ( !this.isForSale ) {
        return ( this as any
        ).$t( '18963.App_restaurantDetailsBookButtonSoldOut' )
      }
      return this.text;
    }
  }
} )
