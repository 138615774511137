























import Vue from "vue";

export default Vue.extend( {
  props    : {
    box : {
      type     : Object,
      required : true
    }
  },
  computed : {
    checkInValueOrPlaceholder(): any {
      return this.box.check_in.value ?? this.box.check_in.placeholder;
    },
    checkOutValueOrPlaceholder(): any {
      return this.box.check_out.value ?? this.box.check_out.placeholder;
    }
  }
} );
