import { render, staticRenderFns } from "./DateRangePlaceholder.vue?vue&type=template&id=0f9f06ce&scoped=true&"
import script from "./DateRangePlaceholder.vue?vue&type=script&lang=ts&"
export * from "./DateRangePlaceholder.vue?vue&type=script&lang=ts&"
import style0 from "./DateRangePlaceholder.vue?vue&type=style&index=0&id=0f9f06ce&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f9f06ce",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/code/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0f9f06ce')) {
      api.createRecord('0f9f06ce', component.options)
    } else {
      api.reload('0f9f06ce', component.options)
    }
    module.hot.accept("./DateRangePlaceholder.vue?vue&type=template&id=0f9f06ce&scoped=true&", function () {
      api.rerender('0f9f06ce', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "frontend/components/DateRangePlaceholder.vue"
export default component.exports