var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-view" },
    _vm._l(_vm.items, function (item) {
      return _c(
        "div",
        {
          staticClass: "list-view-item",
          class: { "sold-out": !item.isPurchasable },
          on: {
            click: function ($event) {
              return _vm.select(item)
            },
          },
        },
        [
          _c("p", [_vm._v(_vm._s(item.description))]),
          _vm._v(" "),
          _c("div", { staticClass: "list-item" }, [
            _c("div", { staticClass: "stats" }, [
              _c("div", { staticClass: "sold" }, [
                _c("div", { staticClass: "sold--amount" }, [
                  _c("span", [_vm._v(_vm._s(item.stats.discount))]),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(item.stats.sales))]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "price-wrap" }, [
                _c("div", {
                  staticClass: "price-old",
                  domProps: { innerHTML: _vm._s(item.fromPrice.toHtml(false)) },
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "price-new",
                  domProps: { innerHTML: _vm._s(item.price.toHtml(true)) },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("i", {
              staticClass: "material-icons item-arrow",
              attrs: { "data-ligature": "keyboard_arrow_right" },
            }),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }