var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(_vm.dayType, {
        tag: "component",
        attrs: { "day-key": _vm.dayKey, day: _vm.day },
        on: {
          click: function ($event) {
            return _vm.$emit("click")
          },
          mouseover: function ($event) {
            return _vm.$emit("mouseover")
          },
          mouseleave: function ($event) {
            return _vm.$emit("mouseleave")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }