import '../sass/component.scss';

import './window';
import '../../../../frontend/vue'
import HotelsAvailabilityWrapper from "../../../Availability/view/vue/components/HotelsAvailabilityWrapper.vue";
import SearchBarCalendar from "../../../SearchBar/view/vue/SearchBarCalendar.vue";
import Inbox from "../../../Account/view/vue/inbox.vue";
import InboxBadge from "../../../Account/view/vue/components/InboxBadge.vue";

/**
 *  Declare global vue components to use after AJAX calls.
 */
(window as any).AvailabilityComponent = HotelsAvailabilityWrapper;
(window as any).SearchBarCalendarComponent = SearchBarCalendar;
(window as any).InboxComponent = Inbox;
(window as any).InboxBadge = InboxBadge;
