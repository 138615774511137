import Identifier from './../frontend/Identifier';

export default abstract class Entity<T> {
    public readonly id: any;
    protected readonly _props: T;

    protected constructor( props: T, id: any ) {
        this._props = props;
        this.id = new Identifier( id );
    }
}
