import {PricesDTO} from "../../../module/Deals/view/vue/domain/dto/PricesDTO";
import {AvailabilityPrices} from "../../../module/Deals/view/vue/domain/AvailabilityPrices";

export type SearchProductAttribute = {
    name: string;
    values: Array<any> | null;
}

export class SearchProduct {

    constructor(
        private readonly props: any
    ) { }

    public get attributes(): Array<SearchProductAttribute> {
        return this.props?.attributes ?? [];
    }

    public getAttribute( attributeName: string ): Array<any> | null {
        const attribute = this.attributes.find( a => a.name === attributeName );
        if( attribute ) {
            return attribute.values;
        }

        return null;
    }

    public get brand(): string {
        return this.props.brand;
    }

    public get image(): string {
        return this.props.image;
    }

    public get itemno(): string {
        return this.props.itemno;
    }

    public get price(): number {
        return this.props.price;
    }

    public get prices(): PricesDTO {
        return this.props.prices;
    }

    public get title(): string {
        return this.props.title;
    }

    public get url(): string {
        return this.props.url;
    }
}
