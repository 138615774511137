var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "city-select",
      class: { open: _vm.isOpen, "filter-modal": _vm.isInFilter },
    },
    [
      _c(
        "div",
        {
          staticClass: "bar",
          class: { focussed: _vm.isFocussed || _vm.query.length > 0 },
        },
        [
          !_vm.isInFilter
            ? _c("div", { on: { click: _vm.openCitySelect } }, [
                _c("div", { staticClass: "cl-label" }, [
                  _vm._v(_vm._s(_vm.$t("12012.App_LocationInputTitle"))),
                ]),
                _vm._v(" "),
                !_vm.isOpen
                  ? _c("div", { staticClass: "text" }, [
                      _vm._v(
                        "\n        " + _vm._s(_vm.labels.title) + "\n      "
                      ),
                    ])
                  : _vm._e(),
              ])
            : _c("label", { attrs: { for: "cityInput" } }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("37423.App_FilterNearbyLocationTitle")) +
                    "\n    "
                ),
              ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isOpen,
                expression: "isOpen",
              },
            ],
            ref: "cityInput",
            staticClass: "cl-input",
            attrs: {
              id: "cityInput",
              placeholder: !_vm.isInFilter
                ? _vm.$t("12007.App_SearchDataPlaceholderLMD")
                : "",
            },
            domProps: { value: !_vm.isOpen ? _vm.value : _vm.query },
            on: {
              input: _vm.preQuery,
              focus: function ($event) {
                _vm.isFocussed = true
              },
              blur: function ($event) {
                _vm.isFocussed = false
              },
            },
          }),
        ]
      ),
      _vm._v(" "),
      _vm.emptyStateActive || (_vm.query.length > 2 && _vm.predictions)
        ? _c(
            "div",
            { staticClass: "list" },
            [
              _vm.emptyStateActive
                ? _c("div", { staticClass: "empty-state" }, [
                    _c("img", {
                      staticClass: "empty-state-image",
                      attrs: {
                        width: "520",
                        height: "313",
                        src: "https://images.socialdeal.nl/img/no-location-found.png",
                        alt: _vm
                          .$t("3600.App_searchNoCityFound")
                          .replace("<br/>", " "),
                      },
                    }),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "empty-state-title",
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("3600.App_searchNoCityFound")),
                      },
                    }),
                  ])
                : _vm._l(_vm.predictions, function (prediction, index) {
                    return _c("div", { key: index, staticClass: "list-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "list-item",
                          on: {
                            click: function ($event) {
                              return _vm.selectPrediction(prediction)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(prediction.description) +
                              "\n        "
                          ),
                          _c("i", {
                            staticClass: "material-icons",
                            attrs: { "data-ligature": "keyboard_arrow_right" },
                          }),
                        ]
                      ),
                    ])
                  }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isOpen
        ? _c(
            "div",
            { staticClass: "groups" },
            [
              _c("div", { staticClass: "list" }, [
                _c(
                  "div",
                  { staticClass: "list-row", on: { click: _vm.doGps } },
                  [
                    _c("div", { staticClass: "list-nearby" }, [
                      _vm._v(_vm._s(_vm.$t("12134.App_ButtonInMyArea"))),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "material-icons list-nearby-icon" },
                      [_vm._v("my_location")]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm.isLoading
                ? _c("Loader", { attrs: { type: "fixed" } })
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.cityGroups, function (group, groupKey) {
                return group.cities.length > 0
                  ? _c(
                      "div",
                      { key: groupKey, staticClass: "list" },
                      [
                        _c("div", { staticClass: "list-title" }, [
                          _vm._v(_vm._s(group.label)),
                        ]),
                        _vm._v(" "),
                        _vm._l(group.cities, function (city, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "list-row" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "list-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectCity($event, city)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(
                                        _vm.decodeEntities(city.location)
                                      ) +
                                      "\n          "
                                  ),
                                  _c("i", {
                                    staticClass: "material-icons",
                                    attrs: {
                                      "data-ligature": "keyboard_arrow_right",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    )
                  : _vm._e()
              }),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }